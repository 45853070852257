/* eslint-disable */
import WidgetFactory from 'Component/WidgetFactory';
import domToReact from 'html-react-parser/lib/dom-to-react';

const addChildrenToWidget = (args, callback, instance) => {
    const [ { attribs, children } ] = args;

    return <WidgetFactory { ...instance.attributesToProps(attribs) }>{ domToReact(children, instance.parserOptions) }</WidgetFactory>
}

export default {
    'Component/Html/Component': {
        'member-function': {
            'replaceWidget': addChildrenToWidget
        }
    }
}