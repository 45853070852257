/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
/* eslint-disable */
// Leaflet icons not working with webpack
// Solution from https://github.com/Leaflet/Leaflet/issues/4968
import L from 'leaflet';

// import icon from 'leaflet/dist/images/marker-icon.png';
// import iconShadow from 'leaflet/dist/images/marker-shadow.png';
// import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png';

import icon from '../../../public/map-marker.png';
import iconShadow from '../../../public/map-marker.png';
import iconRetinaUrl from '../../../public/map-marker.png';

const iconMarker = new L.Icon({
    iconUrl: icon,
    iconRetinaUrl: iconRetinaUrl,
    shadowUrl: iconShadow,
    iconSize: new L.Point(18, 28),
    className: 'leaflet-div-icon'
});

// let DefaultIcon = L.icon({
//     iconUrl: icon,
//     shadowUrl: iconShadow,
//     iconRetinaUrl: iconRetinaUrl
// })

// L.Marker.prototype.options.icon = DefaultIcon;

delete L.Icon.Default.prototype._getIconUrl;

// L.Icon.Default.mergeOptions(DefaultIcon);

/* eslint-disable global-require, import/first */
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});
// [END] fix

import React from 'react';
import PropTypes from 'prop-types';
import {
    Map,
    TileLayer,
    Marker,
    Popup
} from 'react-leaflet';
import ContentWrapper from 'Component/ContentWrapper';
import Meta from 'Component/Meta';
import FieldStoreFinderContainer from '../../component/FieldStoreFinder/FieldStoreFinder.container';
// import Field from 'Component/Field';
import Html from 'Component/Html';
import 'leaflet/dist/leaflet.css';
import './StoreFinder.style';

/** @namespace Scandiweb/StoreFinderGraphQl/Route/StoreFinder/Component */
export class StoreFinder extends PureComponent {
    static propTypes = {
        location: PropTypes.shape().isRequired,
        enableBreadcrumbs: PropTypes.func.isRequired,
        requestStores: PropTypes.func.isRequired,
        storeListCities: PropTypes.arrayOf(PropTypes.string).isRequired,
        storeListMapped: PropTypes.objectOf(
            PropTypes.arrayOf(
                PropTypes.shape({
                    latitude: PropTypes.string,
                    longitude: PropTypes.string,
                    city: PropTypes.string,
                    address: PropTypes.string
                })
            )
        ).isRequired,
        storeByName: PropTypes.objectOf(
            PropTypes.shape({
                latitude: PropTypes.string,
                longitude: PropTypes.string,
                city: PropTypes.string,
                address: PropTypes.string
            })
        ).isRequired
    };

    __construct(props) {
        super.__construct(props);

        this.state = {
            selectedCity: 'All countries',
            selectedStore: {}
        };

        this.changeCity = this.changeCity.bind(this);
        this.changeStore = this.changeStore.bind(this);
        this.handleStoreChange = this.handleStoreChange.bind(this);
        this.prepareStorePosition = this.prepareStorePosition.bind(this);
    }

    componentWillMount() {
        const { requestStores } = this.props;

        requestStores();
    }

    componentDidMount() {
        const { enableBreadcrumbs, updateMeta } = this.props;

        enableBreadcrumbs();
        updateMeta({
            title: __('Dealers'),
            description: __('Dealers description'),
            keywords: __('dealers'),
            canonical_url: window.location.href
        });
    }

    componentDidUpdate(prevProps) {
        const { requestStores, location } = this.props;

        if (location.pathname !== prevProps.location.pathname) {
            requestStores();
        }
    }

    changeCity(selectedCity) {
        // console.log(selectedCity);
        this.setState({ selectedCity, selectedStore: {} });
    }

    changeStore(selectedStore) {
        // console.log(selectedStore);
        this.setState({ selectedStore });
    }

    handleStoreChange(store_name) {
        const { storeByName } = this.props;

        this.changeStore(storeByName[store_name] || {});
    }

    prepareMapBounds(allStores) {
        const latLongArr = allStores.map(this.prepareStorePosition);

        return L.latLngBounds(latLongArr);
    }

    prepareStorePosition({ latitude, longitude }) {
        if (!latitude || !longitude) {
            return null;
        }

        return [latitude, longitude];
    }

    prepareStoreData() {
        const { storeListMapped } = this.props;
        const { selectedCity } = this.state;

        const cityStores = storeListMapped[selectedCity]
            || Object.values(storeListMapped).reduce((stores, cityStores) => {
                // eslint-disable-next-line no-param-reassign
                stores = [...stores, ...cityStores];
                return stores;
            }, []);

        return cityStores.reduce((validStores, allStore) => {
            const {
                latitude,
                longitude,
                city,
                store_name
            } = allStore;

            if (latitude !== 0 && longitude !== 0 && city) {
                const sortInsertIndex = validStores.findIndex(({
                    city: validCity, store_name: validStoreName
                }) => {
                    if (city === validCity) {
                        return validStoreName > store_name;
                    }

                    return validCity > city;
                });

                const insertPosition = sortInsertIndex >= 0 ? sortInsertIndex : validStores.length;

                validStores.splice(insertPosition, 0, {
                    ...allStore,
                    active: false
                });
            }

            return validStores;
        }, []);
    }

    prepareCityOptions() {
        const { storeListCities: cities } = this.props;

        const validCityOptions = cities.reduce((options, city) => {
            if (city) {
                const sortInsertIndex = options.findIndex(({ label }) => label > city);
                const insertPossition = sortInsertIndex >= 0 ? sortInsertIndex : options.length;

                options.splice(insertPossition, 0, {
                    id: city.replace(/\s/g, ''), value: city, label: city
                });
            }

            return options;
        }, []);

        validCityOptions.unshift({ id: 'All countries', value: 'All countries', label: __('All countries') });

        // console.log(validCityOptions);

        return validCityOptions;
    }

    prepareStoreOptions() {
        const allStore = this.prepareStoreData() || [];

        // const returnStores = [{ store_name: 'All stores', value: 'All stores', label: __('All stores') }, ...allStore.map(({ store_name }) => ({ 
        //     id: store_name, value: store_name, label: store_name
        //  }))] || [];

        // console.log(returnStores);

        return [{ store_name: 'All stores', value: 'All stores', label: __('All stores') }, ...allStore.map(({ store_name }) => ({ 
            id: store_name, value: store_name, label: store_name
         }))] || [];

        // return [{ store_name: __('All stores') }, ...allStore].map(({ store_name }) => (
        //     { id: store_name, value: store_name, label: store_name }
        // )) || [];
    }

    prepareMapData() {
        const { selectedStore, selectedStore: { store_name } } = this.state;
        const allStores = this.prepareStoreData();
        const hasMultipleStores = allStores.length > 1;

        return {
            allStores,
            centerPosition: this.prepareStorePosition(hasMultipleStores ? selectedStore : allStores[0]),
            bounds: hasMultipleStores && !store_name ? this.prepareMapBounds(allStores) : null
        };
    }

    prepareWorkingDays(store) {
        const { working_days } = store;

        if (!working_days) {
            return __('No info about working days');
        }

        const workingDaysMap = working_days.split(',').map(day => day === '1');

        return (
            workingDaysMap.map((isActive, index) => (
                <span
                  block="StoreFinder"
                  elem="Circles"
                  key={ index }
                  mods={ { isActive } }
                />
            ))
        );
    }

    renderHeading() {
        return (
            <div block="StoreFinder" elem="Heading">
                <h1 block="StoreFinder" elem="Heading" mods={ { Title: true } }>
                    { __('Shop search') }
                </h1>
            </div>
        );
    }

    renderSelectList() {
        const { selectedCity, selectedStore: { store_name } } = this.state;

        return (
            <div block="StoreFinder" elem="SelectList">
                { this.renderHeading() }
                <div block="StoreFinder" elem="Select">
                    <FieldStoreFinderContainer
                      id="city-select"
                      name="city-select"
                      type="select"
                      validation={[]}
                      mix={ { block: 'StoreLocation', elem: 'Search' } }
                      mods={ { select: true } }
                      selectOptions={ this.prepareCityOptions() }
                      value={ selectedCity }
                      onChange={ value => this.changeCity(value) }
                      handleChange={() => {}}
                    />
                </div>
                <div block="StoreFinder" elem="Select">
                    <FieldStoreFinderContainer
                      id="store-select"
                      name="store-select"
                      type="select"
                      validation={[]}
                      mix={ { block: 'StoreLocation', elem: 'Search' } }
                      mods={ { select: true } }
                      selectOptions={ this.prepareStoreOptions() }
                      formRef={ () => null }
                      value={ store_name }
                      onChange={ this.handleStoreChange }
                    />
                </div>
            </div>
        );
    }

    renderStoreCardContent(store) {
        const {
            store_name, address, phone_number, store_hours, custom_directions_url
        } = store;

        return (
            <>
                <h3 block="StoreFinder" elem="StoreInfo" mods={ { type: 'name' } }>
                    { store_name || __('No store name') }
                </h3>
                <span block="StoreFinder" elem="StoreInfo" mods={ { type: 'address' } }>
                    { address || __('No address') }
                </span>
                <span block="StoreFinder" elem="StoreInfo" mods={ { type: 'number' } }>
                    { phone_number || __('No phone number') }
                </span>
                <div block="StoreFinder" elem="StoreInfo" mods={ { type: 'workingdays' } }>
                    { this.prepareWorkingDays(store) }
                </div>
                <span block="StoreFinder" elem="StoreInfo" mods={ { type: 'hours' } }>
                    { store_hours ? <Html content={ store_hours } /> : __('No store hours') }
                </span>
                <span block="StoreFinder" elem="StoreInfo" mods={ { type: 'url' } }>
                    { custom_directions_url ? <a href={ custom_directions_url } target="_blank" rel="noopener noreferrer" >Directions</a>  : __('No directions') }
                </span>
            </>
        );
    }

    renderMarker(store, icon) {
        const { latitude, longitude, store_name } = store;

        return (
            <Marker
              position={ [latitude, longitude] }
              icon={ iconMarker }
              key={ store_name.replace(/\s/g, '') || 'All Stores' }
              riseOnHover
              onClick={ () => this.changeStore(store) }
            >
                <Popup closeButton={ false }>
                    <div block="StoreFinder" elem="Store">
                        { this.renderStoreCardContent(store) }
                    </div>
                </Popup>
            </Marker>
        );
    }

    renderAllMarkers() {
        const { allStores } = this.prepareMapData();

        const icon = new L.Icon({
            iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
            iconUrl: require('leaflet/dist/images/marker-icon.png'),
            className: 'leaflet-div-icon',
            iconSize: new L.Point(25, 41)
        });

        return allStores.map(store => this.renderMarker(store, icon));
    }

    renderMap() {
        const { centerPosition, bounds } = this.prepareMapData();

        return (
            <div block="StoreFinder" elem="Map">
                <Map
                  center={ centerPosition }
                  zoom={ 13 }
                  bounds={ bounds }
                  boundsOptions={ { padding: [20, 20] } }
                  duration={ 0.8 }
                  animate
                  useFlyTo
                  tap={ false }
                >
                    <TileLayer
                    //   url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    //   url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
                      url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
                      updateWhenIdle={ false }
                      reuseTiles
                    />
                    { this.renderAllMarkers() }
                </Map>
            </div>
        );
    }

    renderStoreCard(store) {
        const { selectedStore: { store_name: selectedStoreName } } = this.state;
        const { store_name } = store;

        return (
            <div
              block="StoreFinder"
              elem="Store"
              key={ store_name.replace(/\s/g, '') }
              mods={ { isActive: store_name === selectedStoreName } }
            >
                { this.renderStoreCardContent(store) }
                <button
                  block="Button"
                  mods={ { likeLink: true } }
                  onClick={ () => this.changeStore(store) }
                >
                    { __('Show on the map') }
                </button>
            </div>
        );
    }

    renderStoreCards() {
        const allStores = this.prepareStoreData();

        return (
            <div block="StoreFinder" elem="StoreCards">
                { allStores.map(store => this.renderStoreCard(store)) }
            </div>
        );
    }

    renderMainContent() {
        const { storeListCities } = this.props;

        if (!storeListCities.length) {
            return null;
        }

        return (
            <div block="StoreFinder" elem="MainContent">
                { this.renderSelectList() }
                { this.renderMap() }
                { this.renderStoreCards() }
            </div>
        );
    }

    render() {
        const metaObject = {
            name: __('Our Shops'),
            title: __('Our Shops'),
            meta_title: __('Our Shops'),
            meta_description: __('Our Shops - Find the closest store'),
            meta_keyword: 'stores'
        };

        return (
            <main block="StoreFinder">
                <ContentWrapper
                  wrapperMix={ { block: 'StoreFinder', elem: 'Wrapper' } }
                  label={ __('Our Shops') }
                >
                    <Meta metaObject={ metaObject } />
                    { this.renderMainContent() }
                </ContentWrapper>
            </main>
        );
    }
}

export default StoreFinder;
