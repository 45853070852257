import { PureComponent } from 'react';
import Field from 'Component/Field';
import Form from 'Component/Form';
import FormPortal from 'Component/FormPortal';
import { TEXT_TYPE, CHECKBOX_TYPE, SELECT_TYPE } from 'Component/Field/Field.config';
import './CheckFrameNumberForm.style';

export class CheckFrameNumberFormComponent extends PureComponent {
    renderHoneyPotTrap() {
        return (
            <>
                <label className='ohnohoney'>Email</label>
                <Field className='ohnohoney' autoComplete="off" type="text" id="email" name="email" placeholder='your email here' />
            </>
        )
    }

    renderFields() {
        return (
            <div block="CheckFrameNumberForm" elem="Fields">
                <label block="Field" elem="Label" for="steering_arm_changedwarding">
                    {__('Have you received service on your Bullitt')} <strong>{__('steering arm')}</strong> {__('or had it replaced between August 1st, 2021 and March 2022?')}
                </label>
                <Field
                    id="steering_arm_changedwarding"
                    name="steering_arm_changedwarding"
                    type={SELECT_TYPE}
                    selectOptions={[
                        {id: 1, label: __('Please Select an option'), value: ""},
                        {id: 2, label: __('NO, I have NOT had a repair or replacement steering arm in this time'), value: 'no'},
                        {id: 3, label: __('YES, I had my steering arm replaced/repaired in this time'), value: 'yes'}
                    ]}
                    fromBikeForm={true}
                    validation={['notEmpty']}
                />
                <Field
                    block="CheckFrameNumberForm"
                    elem="FrameField"
                    id="framewarding"
                    name="framewarding"
                    type={TEXT_TYPE}
                    label={__('frame number')}
                    validation={['notEmpty']}
                    placeholder={__("e.g 'WLH1234S'")}
                />
                <p>{__('Please enter your full frame number (must start with WLH, e.g. "WLH####N").')}</p>
                {this.renderHoneyPotTrap()}
            </div>
        );
    }

    renderActions() {
        const { onFormSucess, onFormError } = this.props;
        return (
            <Form
                id={'RECALL_CHECK'}
                onSubmitSuccess={(fields) => onFormSucess(fields)}
                onSubmitError={(fields) => onFormError(fields)}
            >
                <button
                    type="submit"
                    block="Button"
                    mix={{ block: "CheckFrameNumberForm", elem: "Button" }}
                >
                    {__('Check Status Now')}
                </button>
            </Form>
        );
    }

    render() {
        const { formSuccess } = this.props;
        return (
            <div block="CheckFrameNumberForm">
                <FormPortal
                    id={'RECALL_CHECK'}
                    name="recall_check"
                >
                    {this.renderFields()}
                </FormPortal>
                {this.renderActions()}
            </div>
        );
    }
}

export default CheckFrameNumberFormComponent;