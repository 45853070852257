import { StoreFinderReducer } from '../store/StoreFinder';

const aroundGetReducer = (args, callback, instance) => ({
    ...callback.apply(instance, args),
    StoreFinderReducer
});

export default {
    'Store/Index/getReducers': {
        function: aroundGetReducer
    }
};
