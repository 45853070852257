/* eslint-disable */

// imports the original component from vendor folder
import { NotificationList as SourceNotificationList } from 'SourceComponent/NotificationList/NotificationList.component';

// import new extended styles file
import './NotificationList.override.style.scss';

export class NotificationList extends SourceNotificationList {
    
}

export default NotificationList;
