/* eslint-disable */
import { formatPrice } from 'Util/Price';

const renderPriceLine = (args, callback, instance) => {
    const [price] = args;
    const {
        totals: { quote_currency_code },
        defaultCurrency
    } = instance.props;

    const currency = quote_currency_code || defaultCurrency;
    return formatPrice(price, currency);
}

const mapStateToProps = (args, callback, instance) => {
    const [state] = args;

    return {
        ...callback.apply(instance, args),
        defaultCurrency: state.ConfigReducer.default_display_currency_code
    }
}

export default {
    'Route/CartPage/Component': {
        'member-function': {
            renderPriceLine
        }
    },
    'Route/CartPage/Container/mapStateToProps': {
        'function': mapStateToProps
    }
}