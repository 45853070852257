// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Link from 'Component/Link';
import './BikeFormSuccess.style';

/** @namespace Hoeks/UpcomingBike-Form/Component/BikeFormSuccess/Component/BikeFormSuccessComponent */
export class BikeFormSuccessComponent extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    renderDescription() {
        const {bikeName = "Missing Name"} = this.props;
        return (
            <div block="BikeFormSuccess" elem="Description">
                <p block="BikeFormSuccess-Description" elem="Top">
                    {__('We will keep you updated with everything %s as soon as we can say more', bikeName)}
                </p>
                
                <p block="BikeFormSuccess-Description" elem="Bottom">
                    {__('In the meantime, please visit your local dealers to place an order with them, or if you need to know more click the link below.')}
                </p>
            </div>
        )
    }

    renderActions() {
        const { learnMoreLink = "/" } = this.props;
        console.log(this.props);
        return (
            <div block="BikeFormSuccess" elem="Actions">
                <h3 block="BikeFormSuccess" elem="Actions-Title">{__('Need to know more?')}</h3>
                <Link block="BikeFormSuccess" elem="Button" to="/stores">{__('Ask Your Local Dealer')}</Link>
                <Link block="BikeFormSuccess" elem="Button" to={learnMoreLink}>{__('Learn More')}</Link>
            </div>
        )
    }

    render() {
        return (
            <div block="BikeFormSuccess">
                { /* TODO: Implement render method */ }
                <h2 block="BikeFormSuccess" elem="Title">{__('Success!')}</h2>
                {this.renderDescription()}
                {this.renderActions()}
            </div>
        );
    }
}

export default BikeFormSuccessComponent;
