/**
 * Hoeks.dk
 */
 import Event, {
    EVENT_GTM_IMPRESSIONS_SEARCH,
    EVENT_GTM_IMPRESSIONS_HOME,
    EVENT_GTM_IMPRESSIONS_CROSS_SELL,
    EVENT_GTM_IMPRESSIONS_PLP,
    EVENT_GTM_IMPRESSIONS_WISHLIST,
    EVENT_GTM_IMPRESSIONS_LINKED,
    EVENT_GTM_GENERAL_INIT
} from '../../../util/Event';

import BaseEvent from './BaseEvent.event';
import ProductHelper from '../utils/Product4';



export const EVENT_HANDLE_DELAY = 700;

/**
 * Website places, from where was received event data
 *
 * @type {string}
 */
export const LIST_ID_CATEGORY = 'category';
export const LIST_ID_SEARCH = 'search';

export const LIST_NAME_MAP = {
    [LIST_ID_CATEGORY]: 'Category Page',
    [LIST_ID_SEARCH]: 'Search Results',
}

class ViewItemList4Event extends BaseEvent {
    /**
     * Set base event call delay
     *
     * @type {number}
     */
    eventHandleDelay = EVENT_HANDLE_DELAY;

    bindEvent() {
        // PLP
        Event.observer(EVENT_GTM_IMPRESSIONS_PLP, ({ items, filters, category }) => {
            this.handle(LIST_ID_CATEGORY, items, filters, category);
        });

        // Search
        Event.observer(EVENT_GTM_IMPRESSIONS_SEARCH, ({ items }) => {
            this.handle(LIST_ID_SEARCH, items);
        });
    }

    handler(productCollectionType = PLP_IMPRESSIONS, products = [], filters = {}, category = {}) {
        const items = this.getItemsData(productCollectionType, products, category);

        const value = ProductHelper.getItemsValue(items);

        this.clearEcommerce();
        this.pushEventData({
            ecommerce: {
                currenct: this.getCurrencyCode(),
                value,
                items,
                content_ids: ProductHelper.getItemSkus(items),
            }
        });
    }

    getItemsData(type, products, category){
        return products.map((product, index) => {
            return {
                ...ProductHelper.getProductData(product, category),
                index,
                item_list_id: type,
                item_list_name: LIST_NAME_MAP[type]
            }
        });
    }
}

export default ViewItemList4Event;
