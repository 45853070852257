/* eslint-disable */
import BundleSectionsReducer from '../store/BundleSections/BundleSections.reducer';
import BuilderImagesReducer from '../store/BuilderImages/BuilderImages.reducer';
import HeaderReducer from '../store/Header/Header.reducer';

const aroundGetReducer = (args, callback) => {
    // console.log('Around get reducer')
    return {
        ...callback(args),
        BundleSectionsReducer,
        BuilderImagesReducer,
        HeaderReducer
    }
};

export default {
    'Store/Index/getReducers': {
        function: aroundGetReducer
    }
};
