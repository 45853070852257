/* eslint-disable */

import { PureComponent } from 'react';
import PopupWidget from './PopupWidget.component';
import { showPopup } from 'Store/Popup/Popup.action';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/** @namespace Component/PopupWidget/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
});

/** @namespace Component/PopupWidget/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showPopup: (payload, id) => dispatch(showPopup(id, payload))
});

export class PopupWidgetContainer extends PureComponent {
    componentDidMount() {
        const {
            location: {
                search = ''
            } = {},
            cmsBlockId
        } = this.props;

        const queryParams = new URLSearchParams(search);

        if (queryParams.get('activePopup') === cmsBlockId) {
            this.triggerPopup();
        }
    }

    state = {
        randomId: Math.random()
    }

    getPopupId() {
        const { cmsBlockId } = this.props;
        const { randomId } = this.state;

        return `${cmsBlockId}${randomId}`;
    }

    triggerPopup() {
        const { showPopup, title } = this.props;

        showPopup({ title }, this.getPopupId());
    }

    containerFunctions() {
        return {
            triggerPopup: this.triggerPopup.bind(this),
            getPopupId: this.getPopupId.bind(this) 
        }
    }

    render() {
        return (
            <PopupWidget { ...this.props } { ...this.state } { ...this.containerFunctions() } />
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PopupWidgetContainer));