/* eslint-disable */
import { UPDATE_RECENTLY_VIEWED_PRODUCTS } from 'Store/RecentlyViewedProducts/RecentlyViewedProducts.action';

const updateCartTotals = (args, callback, instance) => {
    const [action] = args;
    const { cartData: cartTotals } = action;

    const items = cartTotals?.items ?? [];

    // const reducedCartTotals = {
    //     ...cartTotals,
    //     items: items.map(item => ({
    //         ...item,
    //         product: {
    //             ...item?.product,
    //             items: []
    //         }
    //     }))
    // }

    const reducedCartTotals = {
        ...cartTotals,
        items: items.map(item => ({
            ...item,
            product: {
                ...item?.product,
                items: (item?.product?.items ?? []).map(innerItem => ({
                    hoeks_bundle_option_necessary: innerItem?.hoeks_bundle_option_necessary,
                    option_id: innerItem?.option_id,
                    options: innerItem?.options.map(option => ({
                        id: option?.id,
                        product: {
                            sku: option?.product?.sku
                        }
                    })),
                    required: innerItem?.required
                }))
            }
        }))
    }

    const updatedAction = {
        ...action,
        cartData: reducedCartTotals
    }

    return callback.apply(instance, [updatedAction]);
}

const updatePrefix = (prefix) => {
    window.hoeksConfigPrefix = prefix;
}

const getPrefix = (pathname) => {
    const prefix = pathname.split('/').filter(e => e !== "").shift();
    const isValidPrefix = window.storeList.reduce((acc, val) => acc || val === prefix, false);
    if (isValidPrefix) {
        updatePrefix(prefix);
    }
    const [firstStore] = window.storeList;
    const finalPrefix = isValidPrefix ? prefix : (window.hoeksConfigPrefix || firstStore);
    // console.log(finalPrefix);
    return finalPrefix;
}

const getItem = (args, callback, instance) => {
    const {
        location: {
            pathname
        }
    } = window;

    const [location] = args;
    var locationPrefix = null;
    if (typeof location === 'object') {
        locationPrefix = location.prefix;
    }

    const prefix = locationPrefix ? locationPrefix : getPrefix(pathname);

    return typeof location === 'object' ? callback.apply(instance, [`${prefix}-${location.path}`]) : callback.apply(instance, [`${prefix}-${location}`]);
}

const setItem = (args, callback, instance) => {
    const {
        location: {
            pathname
        }
    } = window;

    const [data, location, expiration] = args;

    var locationPrefix = null;
    if (typeof location === 'object') {
        locationPrefix = location.prefix;
    }

    const prefix = locationPrefix ? locationPrefix : getPrefix(pathname);

    return typeof location === 'object' ? callback.apply(instance, [data, `${prefix}-${location.path}`, expiration]) : callback.apply(instance, [data, `${prefix}-${location}`, expiration]);
}

const deleteItem = (args, callback, instance) => {
    const {
        location: {
            pathname
        }
    } = window;

    const [location] = args;

    var locationPrefix = null;
    if (typeof location === 'object') {
        locationPrefix = location.prefix;
    }

    const prefix = locationPrefix ? locationPrefix : getPrefix(pathname);

    return typeof location === 'object' ? callback.apply(instance, [`${prefix}-${location.path}`]) : callback.apply(instance, [`${prefix}-${location}`]);
}

const recentlyViewedProductsReducer = (args, callback, instance) => {
    const [state, action] = args;

    if (action.type === UPDATE_RECENTLY_VIEWED_PRODUCTS) {
        const { product } = action;
        const items = product?.items ?? [];
        const reducedProduct = {
            ...product,
            items: items.map(item => ({
                ...item,
                options: (item?.options ?? []).map(option => ({
                    ...option,
                    product: {}
                }))
            }))
        };

        const updatedAction = {
            ...action,
            product: reducedProduct
        }

        return callback.apply(instance, [state, updatedAction]);
    }

    return callback.apply(instance, args);
}

const componentDidMount = (args, callback, instance) => {
    localStorage.removeItem('recentlyViewedProducts');
    localStorage.removeItem('cart_totals');
    localStorage.removeItem('config');
    localStorage.removeItem('wishlist_products');
    localStorage.removeItem('guest_quote_id');
    localStorage.removeItem('LINKED_PRODUCTS');
    return callback.apply(instance, args);
}

const MyAccountReducer = (args, callback, instance) => {
    const [_, action] = args;
    if (action.type.startsWith('@@redux/REPLACE')) {
        return callback.apply(instance, [undefined, action]);
    }
    return callback.apply(instance, args);
}

export default {
    'Store/Cart/Reducer/updateCartTotals': {
        'function': updateCartTotals
    },
    'Util/BrowserDatabase': {
        'member-function': {
            getItem,
            setItem,
            deleteItem
        }
    },
    'Store/RecentlyViewedProducts/Reducer/recentlyViewedProductsReducer': {
        'function': recentlyViewedProductsReducer
    },
    'Component/Router/Container': {
        'member-function': {
            componentDidMount
        }
    },
    'Store/MyAccount/Reducer': {
        'function': MyAccountReducer
    }
}