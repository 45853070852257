/* eslint-disable */
import { SET_CURRENT_SECTION } from "./BundleSections.action";

/** @namespace Store/BundleSections/Reducer/getInitialState */
export const getInitialState = () => ({
    section: ""
});

/** @namespace Store/BundleSections/Reducer */
export const BundleSectionsReducer = (state = getInitialState(), action) => {
    switch (action.type) {
        case SET_CURRENT_SECTION:
            const { section } = action;
            return {
                ...state,
                section
            }
        default:
            return state;
    }
};

export default BundleSectionsReducer;