import { Field } from 'Util/Query';

/** @namespace Hoeks/product-recall/Query/ProductRecall/Query/ProductRecallQuery */
export class ProductRecallQuery {
    addEmailToList(email, frameNumber, fromContactAffected = 0) {
        return new Field('addEmailToList')
        .addArgument('email', 'String!', email)
        .addArgument('frameNumber', 'String!', frameNumber)
        .addArgument('fromContactAffected', 'Int!', fromContactAffected)
        .addField('status')
        .addField('message')
    }

    checkFrameNumber(frameNumber, steering_arm_changedwarding) {
        return new Field('checkFrameNumber')
        .addArgument('frameNumber', 'String!', frameNumber)
        .addArgument('steeringArmChanged', 'String!', steering_arm_changedwarding)
        .addField('red')
        .addField('yellow')
        .addField('green')
    }
}

export default new ProductRecallQuery();
