/* eslint-disable */
import { PureComponent } from 'react';
import Popup from 'Component/Popup';
import CmsBlock from 'Component/CmsBlock';

import './PopupWidget.style';

/** @namespace Component/PopupWidget/Component */
export class PopupWidget extends PureComponent {
    renderPopup() {
        const { cmsBlockId, getPopupId, isLarge } = this.props;

        return (
            <Popup
                id={ getPopupId() }
                clickOutside={ true }
                mix={ { block: 'CmsPopup', mods: { isLarge: isLarge === 1 } } }
            >
                { cmsBlockId ? <CmsBlock identifier={ cmsBlockId } /> : '' }
            </Popup>
        )
    }

    renderText() {
        const { text, triggerPopup } = this.props;

        return (
            <button
                className="cms-button popup-trigger"
                onClick={ triggerPopup }
            >
                { text ? text : '' }
            </button>
        )
    }

    render() {
        return (
            <>
                { this.renderText() }
                { this.renderPopup() }
            </>
        )
    }
}

export default PopupWidget;