/**
 * Hoeks.dk
 */
import Event, {
    EVENT_GTM_PRODUCT_DETAIL
} from '../../../util/Event';

import BaseEvent from './BaseEvent.event';
import ProductHelper from '../utils/Product4';

export const EVENT_HANDLE_DELAY = 700;

/**
 * Website places, from where was received event data
 *
 * @type {string}
 */
export const LIST_ID_PRODUCT = 'product';

export const LIST_NAME_MAP = {
    [LIST_ID_PRODUCT]: 'Product Page'
}

class ViewItem4Event extends BaseEvent {
    /**
     * Set base event call delay
     *
     * @type {number}
     */
    eventHandleDelay = EVENT_HANDLE_DELAY;

    bindEvent() {
        // Product
        Event.observer(EVENT_GTM_PRODUCT_DETAIL, ({ product, pathname, list }) => {
            this.handle(LIST_ID_PRODUCT, product, pathname, list);
        });
    }

    handler(productCollectionType = PLP_IMPRESSIONS, product = {}, pathname = '', list = '') {
        const items = [
            {
                ...ProductHelper.getProductData(product),
                index: 0,
                item_list_id: productCollectionType,
                item_list_name: LIST_NAME_MAP[productCollectionType],
            }
        ];

        const value = ProductHelper.getItemsValue(items);

        this.clearEcommerce();
        this.pushEventData({
            ecommerce: {
                currency: this.getCurrencyCode(),
                value,
                items,
                content_ids: ProductHelper.getItemSkus(items),
            }
        });
    }
}

export default ViewItem4Event;
