/* eslint-disable */
// import NewsletterForm from "../component/NewsletterForm";
import BikeForm from "../component/BikeForm";
// import NewsletterFormPlacholder from "../component/NewsletterFormPlacholder";
import BikeFormPlaceholder from "../component/BikeFormPlaceholder";
export const BikeForm_TYPE = 'HoeksUpcomingBikeForm'
const addUpcomingBikeFormWidget = (member, instance) => {
    return {
        ...member,
        [BikeForm_TYPE]: {
            component: BikeForm,
            fallback: () => <BikeFormPlaceholder />
        }
    }
}

export default {
    'Component/WidgetFactory/Component': {
        'member-property': {
            'renderMap': {
                implementation: addUpcomingBikeFormWidget,
                position: 0
            }
        }
    },
}