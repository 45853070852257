/* eslint-disable */
import TextPlaceholder from 'Component/TextPlaceholder'

/** @namespace Component/EstimatedDateReusable/Component */
export class EstimatedDateReusable extends PureComponent {
    render() {
        const { dateInStock, isLoading, isSimple, isShown } = this.props;

        if (!isSimple && isLoading) {
            return <TextPlaceholder />
        }

        if (isSimple && !isShown) {
            return null;
        }

        return (
            <div block="EstimatedDate" mods={ { isSimple } }>
                { dateInStock }
            </div>
        )
    }
}

export default EstimatedDateReusable;