/* eslint-disable import/no-cycle */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import General from './events/General.event';
import Scripts from './Scripts';
import AddToCart4Event from './events/AddToCart4.event';
import Purchase4Event from './events/Purchase4.event';
import ViewItem4Event from './events/ViewItem4.event';
import ViewItemList4Event from './events/ViewItemList4.event';
import ViewCart4Event from './events/ViewCart4.event';
import BeginCheckout4Event from './events/BeginCheckout4.event';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';
import { CUSTOMER } from 'Store/MyAccount/MyAccount.dispatcher';
import BrowserDatabase from 'Util/BrowserDatabase';

/**
 * Event list
 */
export const EVENT_GENERAL = 'page_view';

/**
 * GA4 Events
 */
export const EVENT_ADD_TO_CART = 'add_to_cart';
export const EVENT_BEGIN_CHECKOUT = 'begin_checkout';
export const EVENT_PURCHASE = 'purchase';
export const EVENT_VIEW_CART = 'view_cart';
export const EVENT_VIEW_ITEM = 'view_item';
export const EVENT_VIEW_ITEM_LIST = 'view_item_list';

/**
 * Const
 */
export const DATA_LAYER_NAME = 'dataLayer';
export const INITIAL_DATA_LAYER_NAME = 'initialDataLayer';
export const GROUPED_PRODUCTS_PREFIX = 'GROUPED_PRODUCTS_';
export const GROUPED_PRODUCTS_GUEST = `${ GROUPED_PRODUCTS_PREFIX }GUEST`;
export const GTM_INJECTION_TIMEOUT = 5000;

/**
 * Google tag manager wrapper
 * This should have 1 instance to avoid multiple initializations
 */
class GoogleTagManager extends PureComponent {
    static propTypes = {
        gtm: PropTypes.shape(),
        // eslint-disable-next-line react/no-unused-prop-types
        state: PropTypes.shape(),
        // eslint-disable-next-line react/no-unused-prop-types
        dispatch: PropTypes.func
    };

    static defaultProps = {
        gtm: {
            enabled: false,
            gtm_id: ''
        },
        state: {},
        dispatch: () => {}
    };

    /**
     * Event list used in GTM
     * All used events should be registered in this data mapping
     * TODO: 404 page, categoryFilter, additional events
     *
     * @type {{[p: string]: General|AddToCart4Event|BeginCheckout4Event|Purchase4Event|ViewCart4Event|ViewItem4Event|ViewItemList4Event}}
     */
    static eventList = {
        [EVENT_GENERAL]: General,
        [EVENT_ADD_TO_CART]: AddToCart4Event,
        [EVENT_BEGIN_CHECKOUT]: BeginCheckout4Event,
        [EVENT_PURCHASE]: Purchase4Event,
        [EVENT_VIEW_CART]: ViewCart4Event,
        [EVENT_VIEW_ITEM]: ViewItem4Event,
        [EVENT_VIEW_ITEM_LIST]: ViewItemList4Event,
    };

    /**
     * GoogleTagManager instance
     *
     * @type {GoogleTagManager}
     */
    static instance = null;

    /**
     * Push data to GTM
     *
     * @param event
     * @param data
     */
    static pushData(event, data) {
        if (this.getInstance()) {
            this.getInstance().processDataPush(event, data);
        }
    }

    /**
     * Append Data Layer with new data
     *
     * @param data
     */
    static appendData(data) {
        if (this.getInstance()) {
            this.getInstance().addDataLayer(data);
        }
    }

    /**
     * Get event by name
     *
     * @param name
     * @return {null|BaseEvent}
     */
    static getEvent(name) {
        if (this.getInstance()) {
            return this.getInstance().getEvent(name);
        }

        return null;
    }

    /**
     * Get GoogleTagManager Instance
     *
     * @return {GoogleTagManager}
     */
    static getInstance() {
        return this.instance;
    }

    /**
     * Is GoogleTagManager enabled
     *
     * @type {boolean}
     */
    enabled = false;

    /**
     * Prepared Data Layer
     *
     * @type {{}}
     */
    currentDataLayer = {};

    /**
     * Data layer name
     *
     * @type {string}
     */
    currentDataLayerName = INITIAL_DATA_LAYER_NAME;

    /**
     * Initial data layer name
     * 
     * @type {string}
     */
    initialDataLayerName = INITIAL_DATA_LAYER_NAME;

    /**
     * groupedProducts
     */
    groupedProductsStorageName = GROUPED_PRODUCTS_GUEST;

    /**
     * Event data object
     *
     * @type {{}}
     */
    events = {};

    /**
     * Data storage for event data
     *
     * @type {{}}
     */
    eventDataStorage = {};

    /**
     * Grouped product storage
     */
    groupedProducts = {};

    /**
     * Did mount
     */
    componentDidMount() {
        this.initialize();
    }

    /**
     * If props is updated
     */
    componentDidUpdate() {
        this.initialize();
    }

    /**
     * Unregister component
     */
    componentWillUnmount() {
        this.destruct();
    }

    /**
     * Get event by name
     *
     * @param name
     * @return {null|*}
     */
    getEvent(name) {
        if (Object.keys(this.events).indexOf(name) >= 0) {
            return this.events[name];
        }

        return null;
    }

    /**
     * Set event storage
     *
     * @param event
     * @param data
     */
    setEventStorage(event, data) {
        this.eventDataStorage[event] = data;
    }

    /**
     * Set grouped products to storage
     *
     * @param groupedProducts
     */
    setGroupedProducts(groupedProducts) {
        BrowserDatabase.setItem(groupedProducts, this.groupedProductsStorageName, ONE_MONTH_IN_SECONDS);
        this.groupedProducts = groupedProducts;
    }

    /**
     * Get reference to grouped products
     */
    getGroupedProducts() {
        return this.groupedProducts;
    }

    /**
     * Get reference to the storage
     *
     * @param event
     * @return {*}
     */
    getEventDataStorage(event) {
        if (typeof this.eventDataStorage[event] === 'undefined') {
            this.resetEventDataStorage(event);
        }

        return this.eventDataStorage[event];
    }

    /**
     * Reset storage data
     *
     * @param event
     */
    resetEventDataStorage(event) {
        this.eventDataStorage[event] = {};
    }

    updateGroupedProducts() {
        this.groupedProducts = BrowserDatabase.getItem(this.groupedProductsStorageName) || {};
    }

    updateGroupedProductsStorageName(name) {
        this.groupedProductsStorageName = name
            ? `${ GROUPED_PRODUCTS_PREFIX }${ name }`
            : GROUPED_PRODUCTS_GUEST;

        this.updateGroupedProducts();
    }

    /**
     * Register GTM event handlers
     */
    registerEvents() {
        this.events = Object.entries(GoogleTagManager.eventList).reduce((acc, [name, Event]) => {
            acc[name] = new Event(name, this);
            acc[name].bindEvent();

            return acc;
        }, {});
    }

    /**
     * Send event and data to the GoogleTagManager
     *
     * @param event
     * @param data
     */
    processDataPush(event, data) {
        if (this.enabled) {
            this.addDataLayer(data);

            if (this.debug) {
                // eslint-disable-next-line no-console
                console.log(event, data);
            }
            if (event){
                window[this.currentDataLayerName].push({
                    event,
                    ...this.currentDataLayer
                });
            } 
            else {
                window[this.currentDataLayerName].push({
                   ...this.currentDataLayer
                });
            }

            this.currentDataLayer = {};
        }
    }

    /**
     * Unregister/ destruct all parts related to the gtm
     */
    destruct() {
        Object.values(this.events).forEach((event, name) => {
            event.destruct();
            // eslint-disable-next-line fp/no-delete
            delete this.events[name];
        });

        this.events = {};
    }

    /**
     * Append current DataLayer with new nata
     *
     * @param data
     */
    addDataLayer(data) {
        if (this.enabled) {
            this.currentDataLayer = Object.assign({}, this.currentDataLayer, data);
        }
    }

    /**
     * Initialize GTM
     */
    initialize() {
        const { gtm: { enabled } } = this.props;

        if (this.enabled || !enabled || GoogleTagManager.getInstance()) {
            return;
        }

        this.enabled = true;
        GoogleTagManager.instance = this;

        this.initGroupedProducts();
        this.injectGTMScripts();
        this.registerEvents();
    }

    /**
     * Insert GTM scripts to the document
     */
    injectGTMScripts() {
        const { gtm: { gtm_id: id } } = this.props;

        const script = document.createElement('script');
        script.innerHTML = Scripts.getScript(
            { id, dataLayerName: this.currentDataLayerName }
        );

        const {
            location: {
                pathname = ''
            } = {}
        } = window;

        const fastPaths = ['/quickpaygateway/payment', '/checkout'];

        const gtmInjectionTimeout = fastPaths.some(path => pathname.includes(path)) ? 5 : GTM_INJECTION_TIMEOUT;

        setTimeout(() => {
            document.head.insertBefore(script, document.head.childNodes[0]);
            // console.log('GTM inserted.');
            this.currentDataLayerName = DATA_LAYER_NAME;
            window[this.currentDataLayerName] = window[this.currentDataLayerName] || [];

            setTimeout(() => {
                window[this.initialDataLayerName] = window[this.initialDataLayerName].reduce((acc, data) => {
                    const {
                        event,
                        ...eventData
                    } = data;

                    if (event === 'gtm.js') {
                        // console.log('Pushing gtm.js event.');
                        this.processDataPush(event, eventData);
                    } else {
                        return [ ...acc, data ];
                    }
                    return acc;
                }, []);
            }, gtmInjectionTimeout / 5);

            setTimeout(() => {
                window[this.initialDataLayerName] = window[this.initialDataLayerName].reduce((acc, data) => {
                    const {
                        event,
                        ...eventData
                    } = data;

                    if (event && event.includes('cookie_consent')) {
                        // console.log('Pushing cookie_consent event.');
                        this.processDataPush(event, eventData);
                    } else {
                        return [ ...acc, data ];
                    }
                    return acc;
                }, []);

                window[this.initialDataLayerName] = window[this.initialDataLayerName].reduce((acc, data) => {
                    const {
                        event,
                        ...eventData
                    } = data;

                    // console.log('Pushing event.');
                    this.processDataPush(event, eventData);

                    return acc;
                }, [])
            }, gtmInjectionTimeout);
        }, gtmInjectionTimeout);
        window[this.currentDataLayerName] = window[this.currentDataLayerName] || [];
    }

    /**
     * Initialize grouped products
     */
    initGroupedProducts() {
        const customer = BrowserDatabase.getItem(CUSTOMER);

        this.updateGroupedProductsStorageName(customer && customer.id);

        this.groupedProducts = BrowserDatabase.getItem(this.groupedProductsStorageName) || {};
    }

    /**
     * Skip render
     *
     * @return {null}
     */
    render() {
        return null;
    }
}

export default GoogleTagManager;
