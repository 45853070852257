/* eslint-disable */
import Html from 'Component/Html';
import Link from 'Component/Link';
import {BlogPostCardWidget as SourceBlogPostCardWidget} from "@hoeks/blog-extension/src/component/BlogPostCardWidget/BlogPostCardWidget.component"

import "./BlogPostCardWidget.override.style"
export class BlogPostCardWidget extends SourceBlogPostCardWidget {
    renderTitle() {
        const {
            postname
        } = this.props;

        return (
            <h4
                block="BlogPostCardWidget"
                elem="Title use-red-text"
            >{ postname }</h4>
        )
    }
    renderShortContent() {
        const {
            shortcontent = ''
        } = this.props;

        if (!shortcontent) {
            return null;
        }
        return (
            <p
                block="BlogPostCardWidget"
                elem="ShortContent"
            >
                <Html content={ shortcontent } />
            </p>
        )
    }
    renderContainer() {
        return (
            <div
                block="BlogPostCardWidget"
                elem="Container BlogPostCard"
            >
                { this.renderFeaturedImage() }
                <div block="BlogPostCardWidget" elem="Details">
                { this.renderTitle() }
                { this.renderShortContent() }
                </div>
                {/* { this.renderPublishDate() } */}
                {/* { this.renderCategories() } */}
                <div class="plus-button"></div>
                <div class="plus-sign">+</div>
            </div>
        )
    }
}

export default BlogPostCardWidget;