/* eslint-disable */
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import BikeBuilderFooter from './BikeBuilderFooter.component';
import {
    BUNDLE
} from 'Util/Product';
import history from 'Util/History';

export const BundleSectionsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../../store/BundleSections/BundleSections.dispatcher'
);

/** @namespace Component/BikeBuilderFooter/mapStateToProps */
export const mapStateToProps = (state) => ({
    currentSection: state.BundleSectionsReducer.section,
});

/** @namespace Component/BikeBuilderFooter/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    setCurrentSection: (section) => BundleSectionsDispatcher.then(({ default: dispatcher }) => dispatcher.setCurrentSection(section, dispatch))
});

/** @namespace Component/BikeBuilderFooter/Container */
export class BikeBuilderFooterContainer extends PureComponent {
    state = {
        selectedOptions: [],
        showSummary: false,
        estimatedDateOptional: { status: 0 },
        estimatedDateRequired: { status: 0 }
    }

    containerProps() {
        return {
            isFirstSection: this.isFirstSection(),
            isLastSection: this.isLastSection(),
            productPrice: this.getProductPrice()
        };
    };

    containerFunctions() {
        return {
            onBackButtonClick: this.onBackButtonClick,
            onNextButtonClick: this.onNextButtonClick,
            onSummaryClick: this.onSummaryClick,
            onSummaryCloseClick: this.onSummaryCloseClick,
            goBack: this.goBack,
            setEstimatedDeliveryDate: this.setEstimatedDeliveryDate,
            onConfigurationUrlClick: this.onConfigurationUrlClick.bind(this)
        }
    }

    onConfigurationUrlClick(event) {
        const { showNotification } = this.props;
        event.target.setSelectionRange(0, event.target.value.length);
        navigator.clipboard.writeText(event.target.value);
        showNotification('success', __('Copied configuration link to clipboard.'));
    }

    getSections() {
        const {
            product: {
                sections: sectionsString
            } = {}
        } = this.props;

        return sectionsString.split(',').map(section => section.trim());
    }

    isFirstSection = () => {
        const {
            currentSection,
        } = this.props;

        const sections = this.getSections();
        const sectionIndex = sections.indexOf(currentSection);

        if (sectionIndex === 0) return true;
        return false;
    }

    isLastSection = () => {
        const {
            currentSection,
        } = this.props;

        const sections = this.getSections();
        const sectionIndex = sections.indexOf(currentSection);

        if (sectionIndex === sections.length - 1) return true;
        return false;
    }

    goBack = () => {
        history.goBack();
    }

    onBackButtonClick = () => {
        if (this.isFirstSection()) return;

        const {
            currentSection,
            setCurrentSection
        } = this.props;

        const sections = this.getSections();
        const backSectionIndex = sections.indexOf(currentSection) - 1;

        setCurrentSection(sections[backSectionIndex]);
    };

    onNextButtonClick = () => {
        if (this.isLastSection()) return;

        const {
            currentSection,
            setCurrentSection
        } = this.props;

        const sections = this.getSections();
        const nextSectionIndex = sections.indexOf(currentSection) + 1;

        setCurrentSection(sections[nextSectionIndex]);
    };

    onSummaryClick = () => {
        const {
            productOptionsData: {
                productOptions
            } = {},
            product: {
                items,
                sections
            } = {}
        } = this.props;

        this.setState(state => ({ showSummary: !state.showSummary }));

        const shouldContinue = productOptions.every(option => items.some(item => item.option_id === option.id));

        if (!shouldContinue) return [];

        const sectionsArray = sections.split(',').map(e => e.trim());

        const selectedOptions = productOptions.map(option => {
            // console.log(option);
            const item = items.find(item => item.option_id === option.id);
            return {
                section: item.hoeks_bundle_section,
                position: item.position,
                quantity: option.quantity,
                product: item.options.find(itemOption => itemOption.id === parseInt(option.value[0])).product,
                optionTitle: item.title
            }
        }).sort((a, b) => a.position - b.position).reduce((acc, val) => {
            // console.log(val);
            acc[val.section] = [...(acc[val.section] || []), val];
            return acc;
        }, {});

        const finalSelectedOptions = sectionsArray.map(e => ({ title: e, options: selectedOptions[e] }));
        // console.log(finalSelectedOptions);

        // const test = selectedOptions.reduce((acc, val) => {
        //     acc[val.section] = [...(acc[val.section] || []), val].sort((a, b) => a.position - b.position);
        //     return acc;
        // }, {});
        // console.log(selectedOptions);

        this.setState({ selectedOptions: finalSelectedOptions });
        // console.log(selectedOptions);
    };

    onSummaryCloseClick = () => {
        this.setState({ showSummary: false });
    };

    setEstimatedDeliveryDate = (required, optional) => {
        this.setState({ estimatedDateRequired: required, estimatedDateOptional: optional });
    }

    getProductPrice() {
        const {
            product,
            product: { variants = [], type_id },
            configurableVariantIndex,
            selectedBundlePrice,
            selectedBundlePriceExclTax
        } = this.props;

        const {
            price_range
        } = variants[configurableVariantIndex] || product;

        if (type_id === BUNDLE) {
            const {
                price_range: {
                    minimum_price: {
                        regular_price: { currency },
                        discount: { percent_off }
                    }
                }
            } = product;

            // eslint-disable-next-line no-magic-numbers
            const discount = (1 - percent_off / 100);

            const finalBundlePrice = selectedBundlePrice * discount;
            const finalBundlePriceExclTax = selectedBundlePriceExclTax * discount;

            const priceValue = { value: finalBundlePrice, currency };
            const priceValueExclTax = { value: finalBundlePriceExclTax, currency };

            // BEGIN new calc
            const {
                product: {
                    items = []
                },
                productOptionsData: {
                    productOptions = []
                }
            } = this.props;

            let price = price_range;

            // console.log(price);

            const updatePriceRange = (priceRange, val) => {
                return {
                    ...priceRange,
                    minimum_price: {
                        final_price: {
                            ...priceRange.minimum_price.final_price,
                            value: priceRange.minimum_price.final_price.value + val
                        },
                        final_price_excl_tax: {
                            ...priceRange.minimum_price.final_price_excl_tax,
                            value: priceRange.minimum_price.final_price_excl_tax.value + val
                        },
                        regular_price: {
                            ...priceRange.minimum_price.regular_price,
                            value: priceRange.minimum_price.regular_price.value + val
                        },
                        regular_price_excl_tax: {
                            ...priceRange.minimum_price.regular_price_excl_tax,
                            value: priceRange.minimum_price.regular_price_excl_tax.value + val
                        }
                    }
                }
            }

            productOptions.forEach(selectedOption => {
                const item = items.find(item => item.option_id === selectedOption.id);
                if (item === undefined) return;
                const option = item.options.find(option => option.id === parseInt(selectedOption.value[0]));
                if (option === undefined) return;
                if (option.price_type !== 'FIXED') return;
                price = updatePriceRange(price, option.price);
            });

            price.minimum_price.final_price.value = price?.minimum_price?.final_price?.value > 0 ? price?.minimum_price?.final_price?.value : finalBundlePrice;
            price.minimum_price.final_price_excl_tax.value = price?.minimum_price?.final_price_excl_tax?.value > 0 ? price?.minimum_price?.final_price_excl_tax?.value : finalBundlePriceExclTax;
            price.minimum_price.regular_price.value = price?.minimum_price?.regular_price?.value > 0 ? price?.minimum_price?.regular_price?.value : selectedBundlePrice;
            price.minimum_price.regular_price_excl_tax.value = price?.minimum_price?.regular_price_excl_tax?.value > 0 ? price?.minimum_price?.regular_price_excl_tax?.value : selectedBundlePriceExclTax;

            return price;
            // END new calc

            // return {
            //     minimum_price: {
            //         final_price: priceValue,
            //         regular_price: priceValue,
            //         final_price_excl_tax: priceValueExclTax,
            //         regular_price_excl_tax: priceValueExclTax
            //     }
            // };
        }

        return price_range;
    }

    render() {
        return (
            <BikeBuilderFooter
                { ...this.props }
                { ...this.containerProps() }
                { ...this.containerFunctions() }
                { ...this.state }
            />
        );
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BikeBuilderFooterContainer);