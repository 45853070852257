/* eslint-disable */
import { PureComponent } from 'react';
import EstimatedDate from '../EstimatedDate';
import AddToCart from 'Component/AddToCart';
import ProductPrice from 'Component/ProductPrice';
import ReactToPrint from 'react-to-print';
import {
    STATUS_LOADING,
    STATUS_UNKNOWN,
    STATUS_IN_STOCK,
    STATUS_DATE
} from '../EstimatedDate/EstimatedDate.config'

import './BikeBuilderFooter.style';

/** @namespace Component/BikeBuilderFooter/Component */
export class BikeBuilderFooter extends PureComponent {
    static defaultProps = {
        productPrice: {}
    };

    __construct(props) {
        super.__construct(props);
        this.renderSummarySection = this.renderSummarySection.bind(this);
        this.renderSummaryOption = this.renderSummaryOption.bind(this);
    }

    renderBackButton() {
        const { onBackButtonClick, isFirstSection, goBack } = this.props;

        if (isFirstSection) {
            return (
                <button
                    block="Button BikeBuilderFooter"
                    elem="BackButton"
                    aria-label="Back"
                    onClick={ goBack }
                >
                    { __('Back to Overview') }
                </button>
            )
        }
        return (
            <button
                onClick={ onBackButtonClick }
                block="Button BikeBuilderFooter"
                elem="BackButton"
                disabled={ isFirstSection }
            >
                <span>{__('Back')}</span>
            </button>
        );
    };

    renderNextButton() {
        const { onNextButtonClick, isLastSection } = this.props;

        if (isLastSection) {
            return this.renderAddToCart();
        } else {
            return (
                <button
                    onClick={ onNextButtonClick }
                    block="Button BikeBuilderFooter"
                    elem="NextButton"
                >
                    <span>{__('Next')}</span>
                </button>
            );
        }
    };

    renderAddToCart() {
        const {
            configurableVariantIndex,
            product,
            quantity,
            groupedProductQuantity,
            onProductValidationError,
            productOptionsData,
            estimatedDateRequired: {
                status: requiredStatus,
                text: requiredText = ''
            },
            estimatedDateOptional: {
                status: optionalStatus,
                text: optionalText = ''
            }
        } = this.props;

        let estimatedDate = '';
        let isSet = false;
        const requiredStatusText = requiredStatus === STATUS_IN_STOCK || requiredStatus === STATUS_UNKNOWN || requiredStatus === STATUS_LOADING ? __('Bike stock status: ') : __('Bike expected back in stock: ')

        const optionalStatusText = optionalStatus === STATUS_IN_STOCK || optionalStatus === STATUS_UNKNOWN || optionalStatus === STATUS_LOADING ? __('Accessories stock status: ') : __('Accessories expected back in stock: ')

        if (requiredStatus === STATUS_LOADING) {
            estimatedDate = 'LOADING';
            isSet = true;
        }

        if ((optionalStatus === requiredStatus && optionalStatus !== STATUS_DATE) && !isSet) {
            estimatedDate = requiredStatusText + requiredText;
            isSet = true;
        }

        if ((optionalStatus === STATUS_DATE && requiredStatus === STATUS_DATE && optionalText === requiredText) && !isSet) {
            estimatedDate = requiredStatusText + requiredText;
            isSet = true;
        }

        if (!isSet) {
            estimatedDate = requiredStatusText + requiredText + '\n' + optionalStatusText + optionalText;
        }

        return (
            <AddToCart
              product={ product }
              configurableVariantIndex={ configurableVariantIndex }
              mix={ { block: 'ProductActions', elem: 'AddToCart' } }
              quantity={ quantity }
              groupedProductQuantity={ groupedProductQuantity ?? {} }
              onProductValidationError={ onProductValidationError }
              productOptionsData={ productOptionsData }
              estimatedDate={ estimatedDate }
            />
        );
    }

    renderSummaryOption(option) {
        const {
            optionTitle,
            product: {
                name
            } = {},
        } = option;

        return (
            <span
                block="BikeBuilderFooter"
                elem="SummaryItem"
                key={`${optionTitle}${name}`}
            >
                <span
                    block="BikeBuilderFooter"
                    elem="SummaryItemLabel"
                >
                    { `${optionTitle}: ` }
                </span>
                <span
                    block="BikeBuilderFooter"
                    elem="SummaryItemValue"
                >
                    { `${name}` }
                </span>
            </span>
        )
    }

    renderSummarySection(section) {
        const { title, options } = section;
        return (
            <div
                block="BikeBuilderFooter"
                elem="SummarySection"
            >
                { options && <span block="BikeBuilderFooter" elem="SummarySectionTitle">{ title }</span> }
                { (options || []).map(this.renderSummaryOption) }
            </div>
        )
    }

    renderEstimatedDate() {
        return (
            <div
                block="EstimatedDate"
                elem="DateContainer"
            >
                <EstimatedDate { ...this.props } />
            </div>
        );
    };

    renderConfigurationUrl() {
        const { preSelectUrl, onConfigurationUrlClick } = this.props;

        if (!preSelectUrl) {
            return null;
        }

        return (
            <div
                block="BikeBuilderFooter"
                elem="ConfigurationUrl"
            >
                <div
                    block="BikeBuilderFooter"
                    elem="SummarySection"
                >
                    <span
                        block="BikeBuilderFooter"
                        elem="SummarySectionTitle"
                    >
                        { __('Configuration Link') }
                    </span>
                    <input
                        block="BikeBuilderFooter"
                        elem="SummaryItem"
                        value={ preSelectUrl }
                        onClick={ onConfigurationUrlClick }
                    />
                </div>
            </div>
        );
    }

    renderSummary() {
        const { 
            selectedOptions, 
            showSummary, 
            onSummaryCloseClick,
            product: { name },
            productPrice,
            saveCurrentConfiguration
        } = this.props;

        return (
            <div
                block="BikeBuilderFooter"
                elem="Summary"
                mods={ { isHidden: !showSummary } }
            >
                <div
                    block="BikeBuilderFooter"
                    elem="SummaryContent"
                    id="SummaryPrintContent"
                    ref={ el => this.summaryRef = el }
                >
                    <h4
                        block="BikeBuilderFooter"
                        elem="SummaryTitle"
                    >
                        {__('Build Overview')}
                    </h4>
                    <h5
                        block="BikeBuilderFooter"
                        elem="SummaryProductTitle"
                    >
                        { name }
                    </h5>
                    { this.renderConfigurationUrl() }
                    <div
                        block="BikeBuilderFooter"
                        elem="SummaryItems"
                    >
                        { selectedOptions.map(this.renderSummarySection) }
                    </div>
                    <div
                        block="BikeBuilderFooter"
                        elem="SummaryPrice"
                    >
                        <span
                            block="BikeBuilderFooter"
                            elem="SummaryPriceLabel"
                        >
                            {__('Total Price: ')}
                        </span>
                        <ProductPrice
                            price={ productPrice }
                        />
                    </div>
                    <div
                        block="BikeBuilderFooter"
                        elem="SummaryPrintNoticeContainer"
                    >
                        <span
                            block="BikeBuilderFooter"
                            elem="SummaryPrintNotice"
                        >
                            {__('NB. Shipping and assembly not included')}
                        </span>
                        <span
                            block="BikeBuilderFooter"
                            elem="SummaryPrintNotice"
                        >
                            {__('Prices may vary in store - Check with your local dealer')}
                        </span>
                    </div>
                    <div
                        block="BikeBuilderFooter"
                        elem="SummaryDateContainer"
                    >
                        <EstimatedDate { ...this.props } />
                    </div>
                    <img
                        src="https://www.larryvsharry.com/pub/media/hoeks-pwa/lvsh-badge.svg"
                        block="BikeBuilderFooter"
                        elem="SummaryPrintBadge"
                    />
                    <img
                        src="https://www.larryvsharry.com/pub/media/hoeks-pwa/lvsh-badge-grey.svg"
                        block="BikeBuilderFooter"
                        elem="SummaryPrintBadgeUnderlay"
                    />
                    <img
                        src="https://www.larryvsharry.com/pub/media/hoeks-pwa/bullitt.svg"
                        block="BikeBuilderFooter"
                        elem="SummaryPrintBullittLogo"
                    />
                </div>
                <button
                    block="Button BikeBuilderFooter"
                    elem="CloseSummaryButton"
                    onClick={ onSummaryCloseClick }
                >
                    <span>{ __('Close') }</span>
                </button>
                <ReactToPrint
                    trigger={() => {
                        return (
                            <button
                                block="Button BikeBuilderFooter"
                                elem="PrintSummaryButton"
                            >
                                    <span>{ __('Print') }</span>
                            </button>
                        )
                    }}
                    documentTitle='Custom Bike'
                    content={() => this.summaryRef}
                    pageStyle={() => {
                        const pageStyle = `
                            @page {
                                size: auto; 
                                margin: 0mm;
                            }
                        `;

                        return pageStyle
                    }}
                />
                <button
                    block="Button BikeBuilderFooter"
                    elem="SaveConfigButton"
                    onClick={ saveCurrentConfiguration }
                >
                    <span>{ __('Save') }</span>
                </button>
            </div>
        )
    };

    renderSummaryButton() {
        const {
            onSummaryClick
        } = this.props;

        return (
            <button
                onClick={ onSummaryClick }
                block="Button BikeBuilderFooter"
                elem="SummaryButton"
            >
                <span>{__('Summary')}</span>
            </button>
        )
    };

    renderProductName() {
        const {
            product: { name }
        } = this.props;

        return (
            <div
                block="BikeBuilderFooter"
                elem="ProductName"
            >
                <span>{ name }</span>
            </div>
        )
    };

    renderPrice() {
        const { productPrice } = this.props;

        return (
            <ProductPrice
                price={ productPrice }
            />
        )
    }

    render() {
        return (
            <section
                block="BikeBuilderFooter"
            >
                { this.renderBackButton() }
                { this.renderEstimatedDate() }
                { this.renderProductName() }
                { this.renderPrice() }
                { this.renderSummary() }
                { this.renderSummaryButton() }
                { this.renderNextButton() }
            </section>
        );
    };
};

export default BikeBuilderFooter;