/* eslint-disable */

// imports the original component from vendor folder
import HoeksBlogPostCard from 'scandipwa-blog/src/component/BlogPostCard/BlogPostCard.component';

//import dependencies
import Link from 'Component/Link';
import { getPostUrl } from 'scandipwa-blog/src/util/Posts';
import Fade from 'react-reveal/Fade';

// import new extended styles file
import './BlogPostCard.override.style.scss';

export class BlogPostCard extends HoeksBlogPostCard { 
    renderTitle() {
        const { post: { title }, post, path } = this.props;
        if (!title) {
            return null;
        }

        return (
        <Link to={ getPostUrl(post, path) }>
                <h3 block="BlogPostCard" elem="TextTitle">
                    { title }
                </h3>
        </Link>
        );
    }

    renderContents() {
        const { isPlaceholder } = this.props;

        if (isPlaceholder) {
            return null;
        }

        return (
                <div block="BlogPostCard" elem="ContentWrapper" mods={ { expand: true } }>
                    <div block="BlogPostCard" elem="InnerWrapper" >
                        { this.renderImage() }
                        <div block="BlogPostCard" elem="Details">
                            { this.renderTitle() }
                            <div block="BlogPostCard" elem="DescriptionWrapper">
                                { this.renderDescription() }
                            </div>
                        </div>
                    </div>
                </div>
        );
    }


    render() {
        const { isPlaceholder, isCompactOnMobile } = this.props;
        const {
            post: {
                meta_keywords
            },post
        } = this.props;

        var keywords = '';
        var isFeatured = false;
        if (meta_keywords) {
            var keywords = meta_keywords ? meta_keywords.split(',') : null;
            var isFeatured = keywords.includes('featured');
        };

        return (
            <Fade bottom small duration={500}>
                <div block="BlogPostCard" mods={ { isLoading: isPlaceholder, isCompactOnMobile, isFeatured } }>
                    <Link to={ getPostUrl(post) } block="BlogPostCard" elem="MainLink">
                        { this.renderContents() }
                    </Link>
                    <div block="plus" elem="button">
                        </div>
                    <div block="plus" elem="sign">+</div>
                </div>
            </Fade>
        );
    }
}

export default BlogPostCard;
