/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { connect } from 'react-redux';
import { updateMeta } from 'Store/Meta/Meta.action';
// import { BreadcrumbsDispatcher } from 'Store/Breadcrumbs/Breadcrumbs.dispatcher';
export const BreadcrumbsDispatcher = import(
    /* webpackMode: "lazy", "webpackChunkName": "dispatchers" */
    'Store/Breadcrumbs/Breadcrumbs.dispatcher'
);
import { StoreFinderDispatcher } from '../../store/StoreFinder';
import StoreFinder from './StoreFinder.component';

/** @namespace Scandiweb/StoreFinderGraphQl/Route/StoreFinder/Container/mapStateToProps */
export const mapStateToProps = state => ({
    storeListCities: state.StoreFinderReducer.storeListCities,
    storeListMapped: state.StoreFinderReducer.storeListMapped,
    storeByName: state.StoreFinderReducer.storeByName
});

/** @namespace Scandiweb/StoreFinderGraphQl/Route/StoreFinder/Container/mapDispatchToProps */
export const mapDispatchToProps = dispatch => {
    return {
        requestStores: () => {
            StoreFinderDispatcher.requestStoreData(dispatch);
        },

        enableBreadcrumbs: () => {
            BreadcrumbsDispatcher.then(
                ({ default: dispatcher }) => dispatcher.update([
                    {
                        url: '',
                        name: __('Dealers')
                    },
                    {
                        url: '/',
                        name: __('Home')
                    }
                ], dispatch)
            )
        },

        updateMeta: (meta) => dispatch(updateMeta(meta))

        // enableBreadcrumbs: () => {
        //     BreadcrumbsDispatcher.update([
        //         {
        //             url: '',
        //             name: __('Shops')
        //         },
        //         {
        //             url: '/',
        //             name: __('Home')
        //         }
        //     ], dispatch);
        // }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StoreFinder);
