/* eslint-disable */
import { MyAccountAddressTable as SourceMyAccountAddressTable } from 'SourceComponent/MyAccountAddressTable/MyAccountAddressTable.component';
export * from 'SourceComponent/MyAccountAddressTable/MyAccountAddressTable.component';

/** @namespace Component/MyAccountAddressTable/Component */
export class MyAccountAddressTable extends SourceMyAccountAddressTable {
    get dataPairArray() {
        const { address } = this.props;

        return [
            ...super.dataPairArray,
            {
                key: 'company',
                label: __('Company'),
                source: address
            }
        ]
    }
}

export default MyAccountAddressTable;