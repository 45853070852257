/* eslint-disable */

// imports the original component from vendor folder
import { Footer as SourceFooter } from 'SourceComponent/Footer/Footer.component';
import CmsBlock from 'Component/CmsBlock';

// import new extended styles file
import './Footer.override.style.scss';

export class Footer extends SourceFooter {

    renderCmsBlockWrapper() {
        const { footer_content: { footer_cms } = {} } = window.contentConfiguration;

        // Remove native newsletter to replace with mailchimp embed in CMS block
        return (
            <div
              block="Footer"
              elem="CmsBlockWrapper"
              mix={ { block: 'Footer', elem: 'Content' } }
            >
                <div
                  block="Footer"
                  elem="Columns"
                  mix={ { block: 'ContentWrapper' } }
                >
                    <CmsBlock identifier={ footer_cms } />
                </div>
            </div>
        );
    }

    render() {
        const { isVisibleOnMobile, device } = this.props;

        if (!isVisibleOnMobile && device.isMobile) {
            return null;
        }

        if (isVisibleOnMobile && !device.isMobile) {
            return null;
        }

        return (
            <footer block="Footer" aria-label="Footer">
                { this.renderContent() }
            </footer>
        );
    }
}

export default Footer;
