/* eslint-disable */
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Fade from 'react-reveal/Fade';
import {BlogPostListWidget as SourceBlogPostListWidget} from "@hoeks/blog-extension/src/component/BlogPostListWidget/BlogPostListWidget.component"

export class BlogPostListWidget extends SourceBlogPostListWidget {
    getSliderSettings() {
        return {
            dots: false,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: true,
            autoplay: false,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 3,
                  }
                },{
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
           
              ]
        }
    }

   

   
    render() {
        const {
            isslider,title
        } = this.props;

        if (isslider) {
            
            return(<Fade bottom small duration={500}>
                <>
                <div className="cms-section cms-slider blog-slider">
                    <h2 className="use-red-text">{title}</h2>
                {this.renderSlider()}
                </div>
                </>
                </Fade>)
            
        }
        
        return <Fade bottom small duration={500}>{this.renderList()}</Fade>
    }
}

export default BlogPostListWidget;