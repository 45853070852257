/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
/* eslint-disable */

import { showNotification } from 'Store/Notification/Notification.action';
import { QueryDispatcher } from 'Util/Request';

import BlogCategories from '../../query/BlogCategories.query';
import { updateCategories } from './Categories.action';

/** @namespace ScandiPWA/Blog/Store/Categories/Dispatcher */
export class CategoriesDispatcher extends QueryDispatcher {
  __construct() {
    super.__construct('BlogCategories');
  }

  onSuccess({ categories }, dispatch) {
    dispatch(updateCategories(categories));
  }

  onError({ message }, dispatch) {
    dispatch(showNotification('error', 'Error fetching Categories!', message));
  }

  prepareRequest(options) {
    return BlogCategories.getQuery(options);
  }
}

export default new CategoriesDispatcher();
