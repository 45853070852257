import BikeFormReducer from "../store/BikeForm/BikeForm.reducer";

const addReducer = (args, callback, instance) => {
    return {
        ...callback(...args),
        BikeFormReducer
    }
}

export default {
    'Store/Index/getReducers': {
        'function':  addReducer
    }
}