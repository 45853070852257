/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 import Event, {
    EVENT_GTM_VIEW_CART
} from '../util/Event';

const componentDidMount = (args, callback, instance) => {
    const {
        totals
    } = instance.props;

    Event.dispatch(EVENT_GTM_VIEW_CART, totals);

    return callback.apply(instance, args);
}

export default {
    'Route/CartPage/Component': {
        'member-function': {
            'componentDidMount': componentDidMount
        }
    }
};
