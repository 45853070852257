/* eslint-disable */
import { lazy } from 'react';
import { Route } from 'react-router-dom';
import {
    SWITCH_ITEMS_TYPE
} from 'Component/Router/Router.config';
import { withStoreRegex } from 'Component/Router/Router.component';

export const CreateAccountPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ '../route/CreateAccount'));
export const LoginAccountPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ '../route/LoginAccount'));
export const ForgotPasswordPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ '../route/ForgotPassword'));

const addLoginAccount = (member, context) => {
    return [
        ...member,
        {
            component: <Route path={ withStoreRegex('/:account*/create/') } render={ (props) => <CreateAccountPage { ...props } /> } />,
            position: 61
        },
        {
            component: <Route path={ withStoreRegex('/:account*/login/') } render={ (props) => <LoginAccountPage { ...props } /> } />,
            position: 62
        },
        {
            component: <Route path={ withStoreRegex('/:account*/forgotpassword/') } render={ (props) => <ForgotPasswordPage { ...props } /> } />,
            position: 63
        }
    ]
}

export default {
    'Component/Router/Component': {
        'member-property': {
            [SWITCH_ITEMS_TYPE]: addLoginAccount
        }
    }
};