/* eslint-disable */
import ProductRecallEmailForm from "../component/ProductRecallEmailForm";
import CheckFrameNumberForm from "../component/CheckFrameNumberForm";
import WriteFrameNumber from "../component/WriteFrameNumber";
import ProductRecallEmailFormPlaceholder from "../component/ProductRecallEmailFormPlaceholder";
export const ProductRecallEmailForm_TYPE = 'HoeksProductRecallEmailForm';
export const CheckFrameNumberForm_TYPE = 'HoeksProductRecallCheckFrameForm';
export const WriteFrameNumber_TYPE = 'HoeksProductRecallWriteFrameNumber';
const addProductRecallEmailFormWidget = (member, instance) => {
    // console.log('Debug Hello');
    return {
        ...member,
        [ProductRecallEmailForm_TYPE]: {
            component: ProductRecallEmailForm,
            fallback: () => <ProductRecallEmailFormPlaceholder />
        },
        [CheckFrameNumberForm_TYPE]:{
            component: CheckFrameNumberForm,
            fallback: () => <ProductRecallEmailFormPlaceholder />
        },
        [WriteFrameNumber_TYPE]:{
            component: WriteFrameNumber,
            fallback: () => <ProductRecallEmailFormPlaceholder />
        }
    }
}

export default {
    'Component/WidgetFactory/Component': {
        'member-property': {
            'renderMap': {
                implementation: addProductRecallEmailFormWidget,
                position: 0
            }
        }
    },
}