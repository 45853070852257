// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { showNotification } from 'Store/Notification/Notification.action';
import { fetchQuery } from 'Util/Request';
import DealerDocumentationQuery from '../../query/DealerDocumentation.query';

import DealerDocumentationComponent from './DealerDocumentation.component';

/** @namespace Hoeks/DealerDocumentation/Component/DealerDocumentation/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({
    // wishlistItems: state.WishlistReducer.productsInWishlist
});

/** @namespace Hoeks/DealerDocumentation/Component/DealerDocumentation/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    // addProduct: options => CartDispatcher.addProductToCart(dispatch, options)
    showErrorNotification: (message) => dispatch(showNotification('error', message)),
    showSuccessNotification: (message) => dispatch(showNotification('success', message)),
});

/** @namespace Hoeks/DealerDocumentation/Component/DealerDocumentation/Container/DealerDocumentationContainer */
export class DealerDocumentationContainer extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    state = {
        qty_repaired: 0,
        formSuccess: false
    }

    containerFunctions = {
        // getData: this.getData.bind(this)
        handleQtyRepairedChange: this.handleQtyRepairedChange.bind(this),
        onFormSuccess: this.onFormSuccess.bind(this),
        onFormError: this.onFormError.bind(this)
    };

    containerProps() {
        // isDisabled: this._getIsDisabled()
    }

    handleQtyRepairedChange(value) {
        this.setState({qty_repaired: value})
    }

    onFormSuccess(fields) {
        const {
            showSuccessNotification,
            showErrorNotification
        } = this.props;

        const {
            qty_repaired
        } = this.state;

        const {
            dealer_namewarding,
            contact_personwarding,
            confirm_destructionwarding,
            email = null
        } = fields;

        if (email) {
            return;
        }

        if (confirm_destructionwarding != 'yes') {
            showErrorNotification("Cant't send recall documentation, before you have confirmed that all affected parts have been destroyed and can't be used again.");
            return;
        }

        const today = new Date();
        var frameNumbers = '';
        Object.keys(fields).forEach(key => {
            if (key.includes('framewarding')) {
                const frame = fields[key].replace(/\s/g, '').toUpperCase();
                if (frameNumbers == '') {
                    frameNumbers = frame;
                } else {
                    frameNumbers += `, ${frame}`;
                }
            }
        });

        const data = {
            dealer_name: dealer_namewarding,
            contact_person: contact_personwarding,
            amount_repaired_or_replaced: qty_repaired,
            frame_numbers: frameNumbers,
            submittal_date: `${today.getFullYear()}-${today.getMonth()+1}-${today.getDay()} ${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
        };
        
        try {
            fetchQuery(DealerDocumentationQuery.addDealerDocumentation(data)).then(({addToDealerDocumentation: {status = 'error', message = 'something went wrong'}}) => {
                if (status == "success") {
                    showSuccessNotification(message);
                    this.setState({formSuccess: true});
                } else {
                    showErrorNotification(message)
                }
            })
        } catch (error) {
            showErrorNotification('something went wrong');
            console.error(error);
        }
    }

    onFormError(error) {
        const {
            showErrorNotification
        } = this.props;
        console.log('debug', error)
        showErrorNotification('Please ensure that you have filled out the form correctly and are not missing any fields.');
    }

    clearInput() {
        this.setState({qty_repaired: 0});
        const textInputs = document.querySelectorAll('.Input');
        textInputs.forEach(input => {
            console.log('debug',input);
            input.value = '';
        });
        document.getElementById('confirm_destructionwarding').value = 'no';
    }

    render() {
        return (
            <DealerDocumentationComponent
                {...this.state}
                {...this.props}
                {...this.containerFunctions}
                {...this.containerProps()}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DealerDocumentationContainer);
