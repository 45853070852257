import { PureComponent } from 'react';
import { connect } from 'react-redux';
import CheckFrameNumberFormComponent from './CheckFrameNumberForm.component'
import ProductRecallQuery from '@hoeks/product-recall/src/query/ProductRecall.query';
import { fetchQuery } from 'Util/Request';
import history from 'Util/History';
import { appendWithStoreCode, setQueryParams } from 'Util/Url';
import { showNotification } from 'Store/Notification/Notification.action';

export const mapStateToProps = (state) => ({

});

export const mapDispatchToProps = (dispatch) => ({
    showErrorNotification: (message) => dispatch(showNotification('error', message)),
    showSuccessNotification: (message) => dispatch(showNotification('success', message)),
})

export class CheckFrameNumberFormContainer extends PureComponent {
    state = {

    }

    containerFunctions = {
        onFormSucess: this.onFormSucess.bind(this),
        onFormError: this.onFormError.bind(this)
    }

    onFormSucess(fields) {
        const {showErrorNotification} = this.props;
        const { framewarding = "", steering_arm_changedwarding = "no", email = null } = fields;

        if (email) {
            return;
        }

        const frame  = framewarding.replace(/\s/g, '').toUpperCase();

        if (frame.substring(0, 3) != 'WLH') {
            showErrorNotification('Frame number must start with "WLH". (e.g. "WLH####N")');
            return;
        }

        const isLastCharLetter = (/[a-zA-Z]/).test(frame.substring(frame.length - 1));
        // console.log('debug', frame.substring(frame.length - 1));
        if (!isLastCharLetter) {
            showErrorNotification('Frame number must end on a letter');
            return;
        }

        const digits = frame.substring(3, frame.length - 1);
        if (isNaN(digits) || digits.length < 4 || digits.length > 5) {
            showErrorNotification('Frame number must have 4 to 5 digit number. (e.g. "WLH####N")');
            return;
        }

        const isAlphanumeric = (/^[a-z0-9]+$/i).test(frame);
        if (!isAlphanumeric) {
            showErrorNotification('Only numbers (0-9) and letters (A-Z) are allowed.');
            return;
        }

        try {
            fetchQuery(ProductRecallQuery.checkFrameNumber(frame, steering_arm_changedwarding)).then(({checkFrameNumber: {red = false, yellow = false, green = false}}) => {
                this.setCookie("framenumber", frame, 3);
                if (red && steering_arm_changedwarding == "no") {
                    history.push(appendWithStoreCode('/product-recall/affected'));
                } else if (yellow) {
                    history.push(appendWithStoreCode('/product-recall/contact/affected'));
                } else if (green && steering_arm_changedwarding == "no") {
                    history.push(appendWithStoreCode('/product-recall/not-affected'));
                } else if (steering_arm_changedwarding == "yes") {
                    history.push(appendWithStoreCode('/product-recall/affected'));
                }
            })
        } catch (error) {
            console.log('debug', error);
        }
    }

    onFormError(fields) {
        const { showErrorNotification } = this.props;
        showErrorNotification('Please fill out the form before continuing');
        console.log('form error');

    }

    setCookie(name, value, hours) {
        var expires = '';
        if (hours) {
            const date = new Date();
            date.setTime(date.getTime() + (hours * 60 * 60 * 1000));
            expires = '; expires=' + date.toUTCString();
        }

        document.cookie = name + '=' + (value || '') + expires + '; path=/';
    }

    getCookie(name) {
        const nameEQ = name + '=';
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    eraseCookie(name) {
        document.cookie = name + '=; Max-Age=-99999999;';
    }

    render() {
        return (
            <CheckFrameNumberFormComponent
                {...this.props}
                {...this.state}
                {...this.containerFunctions}
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckFrameNumberFormContainer);