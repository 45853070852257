/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
/* eslint-disable */

import LinkedProductsDispatcher from 'Store/LinkedProducts/LinkedProducts.dispatcher';
import { updateNoMatch } from 'Store/NoMatch/NoMatch.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { QueryDispatcher } from 'Util/Request';

import BlogPostQuery from '../../query/BlogPost.query';
import { updateSinglePost } from './PostsDetails.action';

/** @namespace ScandiPWA/Blog/Store/PostsDetails/Dispatcher */
export class PostsDetailsDispatcher extends QueryDispatcher {
  __construct() {
    super.__construct('BlogPostsDetails');
  }

  onSuccess(data, dispatch) {
    const { post } = data;
    if (Object.keys(post).length !== 0) {
      const { related_products } = post;
      const post_links = [];

      if (related_products.length > 0) {
        related_products.forEach((productSku, index) => {
          post_links.push(this.buildProductLink(productSku, index));
        });
      }

      if (post_links.length !== 0) {
        LinkedProductsDispatcher.handleData(dispatch, post_links);
      }
    }

    dispatch(updateSinglePost(post));
  }

  onError({ message }, dispatch) {
    dispatch(
      showNotification('error', 'Error fetching post details!', message)
    );
    dispatch(updateNoMatch(true));
  }

  prepareRequest(options) {
    return BlogPostQuery.getQuery(options);
  }

  buildProductLink(productSKU, index) {
    return {
      link_type: 'related',
      linked_product_sku: productSKU,
      position: index,
    };
  }
}

export default new PostsDetailsDispatcher();
