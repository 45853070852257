/* eslint-disable */
import CheckoutAddressTable from 'Component/CheckoutAddressTable';
import MyAccountAddressTable from 'Component/MyAccountAddressTable';

const checkoutAddressBookRenderAddress = (args, callback, instance) => {
    const [address] = args;
    const { onAddressSelect, selectedAddressId } = instance.props;
    const {
        city,
        street: [
            address1
        ],
        id
    } = address;

    return (
        <CheckoutAddressTable
            onClick={ onAddressSelect }
            isSelected={ selectedAddressId === id }
            title={ __('%s, %s', city, address1) }
            address={ address }
            key={ id }
        />
    );
}

const myAccountAddressBookRenderAddress = (args, callback, instance) => {
    const [address, index] = args;
    const { getDefaultPostfix } = instance.props;
    const addressNumber = index + 1;
    const postfix = getDefaultPostfix(address);

    const {
        city,
        street: [
            address1
        ]
    } = address;

    return (
        <MyAccountAddressTable
            title={ __('%s, %s%s', city, address1, postfix) }
            showActions
            address={ address }
            key={ addressNumber }
        />
    );
}

export default {
    'Component/CheckoutAddressBook/Component': {
        'member-function': {
            renderAddress: checkoutAddressBookRenderAddress
        }
    },
    'Component/MyAccountAddressBook/Component': {
        'member-function': {
            renderAddress: myAccountAddressBookRenderAddress
        }
    }
}