/* eslint-disable */

const _getAddressFields = (args, callback, instance) => ([
    ...callback.apply(instance, args),
    'company'
])

export default {
    'Query/MyAccount': {
        'member-function': {
            _getAddressFields
        }
    }
}