// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Field from 'Component/Field';
import Form from 'Component/Form';
import FormPortal from 'Component/FormPortal';
import { TEXT_TYPE, CHECKBOX_TYPE, SELECT_TYPE, NUMBER_TYPE } from 'Component/Field/Field.config';
import CmsBlock from 'Component/CmsBlock';

import './DealerDocumentation.style';

/** @namespace Hoeks/DealerDocumentation/Component/DealerDocumentation/Component/DealerDocumentationComponent */
export class DealerDocumentationComponent extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    renderHoneyPotTrap() {
        return (
            <>
                <label className='ohnohoney'>Email</label>
                <Field className='ohnohoney' autoComplete="off" type="text" id="email" name="email" placeholder='your email here' />
            </>
        )
    }

    renderFrameFields(amount) {
        const inputs = [];

        for (let index = 0; index < amount; index++) {
            inputs.push(
                <Field
                    block="DealerDocumentation"
                    elem="FrameField"
                    id={`framewarding${index}`}
                    name={`framewarding${index}`}
                    type={TEXT_TYPE}
                    label={__('Frame number of the bike')}
                    validation={['notEmpty', 'frameNumberWLH', 'frameNumberDigits', 'frameNumberEndLetter', 'frameNumberSpecialChar']}
                    placeholder={__("e.g 'WLH1234S'")}
                />
            )
        }

        return (
            <>
                {inputs.length != 0 ? <><br /><label block="DealerDocumentation" elem="label Field-Label">{__(`If you don't have all the frame numbers wirte "MISSING" in the fields below.`)}</label></> : <></>}
                {inputs}
            </>
        )
    }

    renderFields() {
        const { qty_repaired, handleQtyRepairedChange } = this.props;
        return (
            <div block="DealerDocumentation" elem="Fields">
                <Field
                    block="DealerDocumentation"
                    elem="Field Input"
                    id="dealer_namewarding"
                    name="dealer_namewarding"
                    type={TEXT_TYPE}
                    label={__('Dealer Name')}
                    validation={['notEmpty']}
                    placeholder={__("e.g Larry vs Harry Copenhagen")}
                />
                <Field
                    block="DealerDocumentation"
                    elem="Field Input"
                    id="contact_personwarding"
                    name="contact_personwarding"
                    type={TEXT_TYPE}
                    label={__('Contact Person')}
                    validation={['notEmpty', 'email']}
                    placeholder={__("e.g joeblogs@larryvsharry.com")}
                />
                <Field
                    block="DealerDocumentation"
                    elem="Field Input"
                    id="qty_replacedwarding"
                    name="qty_replacedwarding"
                    type={TEXT_TYPE}
                    label={__('How many steering arms did you replace that were affected by this recall?')}
                    validation={['notEmpty', 'frameNumberQtyNumber']}
                    value={qty_repaired}
                    onChange={(event) => handleQtyRepairedChange(event)}
                    placeholder={__('e.g. 6')}
                    min={0}
                    max={100}
                />
                {this.renderFrameFields(qty_repaired)}
                <Field
                    block="DealerDocumentation"
                    elem="Field Select-Input"
                    id="confirm_destructionwarding"
                    name="confirm_destructionwarding"
                    type={SELECT_TYPE}
                    selectOptions={[
                        { id: 1, label: __('No I have not yet destroyed the replaced parts (this is required’)'), value: 'no' },
                        { id: 2, label: __('Yes I can confirm all of the parts replaced have now been destroyed'), value: 'yes' }
                    ]}
                    label={__('Requirement: I can confirm all affected parts have been destroyed and cannot be used again')}
                    validation={['notEmpty']}
                />
                {this.renderHoneyPotTrap()}
            </div>
        );
    }

    renderActions() {
        const { onFormSuccess, onFormError } = this.props;
        return (
            <Form
                id={'DEALER_DOCUMENTATION'}
                onSubmitSuccess={(fields) => onFormSuccess(fields)}
                onSubmitError={(error) => onFormError(error)}
            >
                <button
                    type="submit"
                    block="Button"
                    mix={{ block: "DealerDocumentation", elem: "Button" }}
                >
                    {__('Send Recall Documentation')}
                </button>
            </Form>
        );
    }

    render() {
        const { formSuccess } = this.props;
        return (
            <div block="DealerDocumentation">
                {
                    formSuccess ?
                        <CmsBlock identifier="product-recall-success" />
                    :
                        <>
                            <FormPortal
                                id={'DEALER_DOCUMENTATION'}
                                name="dealer_documentation"
                            >
                                {this.renderFields()}
                            </FormPortal>
                            {this.renderActions()}
                        </>
                }
            </div>
        );
    }
}

export default DealerDocumentationComponent;
