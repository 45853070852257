/* eslint-disable */
import { PureComponent } from 'react';
import EstimatedDate from './EstimatedDate.component';
import { makeCancelable } from 'Util/Promise';
import { connect } from 'react-redux';
// import { fetchQuery } from 'Util/Request';

import DatesInStockQuery from '../../query/DatesInStock.query';

import {
    STATUS_LOADING,
    STATUS_UNKNOWN,
    STATUS_IN_STOCK,
    STATUS_DATE
} from './EstimatedDate.config';

/** @namespace Component/EstimatedDate/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    showWithin: state.ConfigReducer.stock_warehouses_frontend_show_eta_within,
    showWithinText: state.ConfigReducer.stock_warehouses_frontend_show_eta_within_text
})

/** @namespace Component/EstimatedDate/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({});

/** @namespace Component/EstimatedDate/Container */
export class EstimatedDateContainer extends PureComponent {
    state = {
        datesInStock: [],
        isLoading: false,
        // cancel: () => {}
    };

    containerProps() {
        return {
            requiredDate: this.calculateRequiredDate(),
            optionalDate: this.calculateOptionalDate()
        }
    };

    componentDidMount() {
        // console.log('Estimated date mount');
        // console.log(this.props);

        const { datesInStock, isLoading } = this.state;

        // console.log(this.props);
        const options = this.getRequiredAndOptionalOptions();
        // console.log(options);

        // const { datesInStock: prevDatesInStock } = prevState;

        // if (JSON.stringify(datesInStock.sort()) !== JSON.stringify(prevDatesInStock.sort())) {
        //     const { setEstimatedDeliveryDate = () => {} } = this.props;
        //     setEstimatedDeliveryDate(this.calculateRequiredDate(), this.calculateOptionalDate());
        // }

        if (options.some(option => option.product === undefined || option.product === null)) {
            // console.log('RETURNING!');
            this.setState({ isLoading: false });
            return;
        }
        // console.log('So far so good');
        // console.log('NOT RETURNING')
        const optionsSkus = options.map(option => option.product.sku).sort();
        const datesInStockSkus = datesInStock.map(dateInStock => dateInStock.sku).sort();
        // console.log(optionsSkus);
        // console.log(datesInStockSkus);
        if (!isLoading && (optionsSkus.length !== datesInStockSkus.length || !optionsSkus.every((sku, idx) => sku === datesInStockSkus[idx]))) {
            // console.log('Fetching dates');
            this.setState(state => ({
                ...state,
                isLoading: true
            }));
            // cancel();
            this.fetchDatesInStock(optionsSkus);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log('Estimated date update');
        // console.log(this.props);
        const { datesInStock, isLoading } = this.state;

        const options = this.getRequiredAndOptionalOptions();

        const { datesInStock: prevDatesInStock } = prevState;

        if (JSON.stringify(datesInStock.sort()) !== JSON.stringify(prevDatesInStock.sort())) {
            const { setEstimatedDeliveryDate = () => {} } = this.props;
            setEstimatedDeliveryDate(this.calculateRequiredDate(), this.calculateOptionalDate());
        }

        if (options.some(option => option.product === undefined || option.product === null)) {
            // console.log('RETURNING!');
            this.setState({ isLoading: false });
            return;
        }
        // console.log('NOT RETURNING')
        const optionsSkus = options.map(option => option.product.sku).sort();
        const filteredOptionSkus = optionsSkus.filter((sku, pos) => {
            return optionsSkus.indexOf(sku) == pos;
        });
        const datesInStockSkus = datesInStock.map(dateInStock => dateInStock.sku).sort();
        // console.log('debug optionsSkus',optionsSkus);
        // console.log('debug datesInStockSkus',datesInStockSkus);
        // console.log("debug", filteredOptionSkus.length !== datesInStockSkus.length, !filteredOptionSkus.every((sku, idx) => sku === datesInStockSkus[idx]))
        if (!isLoading && (filteredOptionSkus.length !== datesInStockSkus.length || !filteredOptionSkus.every((sku, idx) => sku === datesInStockSkus[idx]))) {
            this.setState(state => ({
                ...state,
                isLoading: true
            }));
            // cancel();
            this.fetchDatesInStock(filteredOptionSkus);
        }
    };

    // Required is now really necessary, but I am too lazy to rename all of it, so just imagine it's now called necessary. Thank you.
    calculateRequiredDate() {
        const requiredOptions = this.getRequiredAndOptionalOptions().filter(option => option.required);
        // console.log(requiredOptions);

        return this.calculateDateInStock(requiredOptions);
    };

    calculateOptionalDate() {
        const optionalOptions = this.getRequiredAndOptionalOptions().filter(option => !option.required);
        // console.log(optionalOptions);

        return this.calculateDateInStock(optionalOptions);
    };

    calculateDateInStock(options) {
        const { datesInStock, isLoading } = this.state;
        if (isLoading) return { status: STATUS_LOADING, text: __('Loading') };

        const filteredDatesInStock = datesInStock.filter(dateInStock => options.some(option => option?.product?.sku === dateInStock?.sku)).map(dateInStock => dateInStock.date);

        if (filteredDatesInStock.some(dateInStock => isNaN(dateInStock))) return { status: STATUS_UNKNOWN, text: __('Currently out of stock') };

        const largestDate = filteredDatesInStock.reduce((largestSoFar, dateInStock) => dateInStock > largestSoFar ? dateInStock : largestSoFar, 0);

        const dateInStock = new Date(largestDate);
        const now = new Date();
        if (dateInStock - (1000 * 60 * 2) < now) return { status: STATUS_IN_STOCK, text: __('In stock') }; 

        return { status: STATUS_DATE, text: dateInStock.toLocaleDateString('da-DK') };
    };

    getRequiredAndOptionalOptions() {
        const { 
            productOptionsData: {
                productOptions,
                requiredOptions
            } = {},
            product: {
                items
            } = {}
        } = this.props;

        if (productOptions === undefined) {
            return [];
        }

        const shouldContinue = productOptions.every(option => items.some(item => item.option_id === option.id));

        if (!shouldContinue) return [];

        // console.log(items);
        // console.log(productOptions);

        return productOptions.map(option => {
            // console.log(option)
            const product = items.find(item => item.option_id === option.id).options.find(itemOption => itemOption.id === parseInt(option.value[0]));

            // console.log(option);
            // console.log(product);
            // console.log(option);

            return {
                // required: requiredOptions.find(req => req === option.id) !== undefined,
                required: items.find(item => item.option_id === option.id).hoeks_bundle_option_necessary ?? false,
                product: product ? product.product : null
            };
        });

    };

    fetchDatesInStock(skus) {
        // console.log('fetchDatesInStock called!')
        // console.log(this.state);
        import('Util/Request').then(({ fetchQuery }) => {
            const query = [DatesInStockQuery.getQuery({ skus: skus.map(sku => ({ sku, qty: 1 })) })];
            // const {
            //     cancel: prevCancel
            // } = this.state;

            // prevCancel();

            const {
                promise,
                // cancel
            } = makeCancelable(fetchQuery(query));

            // this.setState(state => ({
            //     ...state,
            //     cancel
            // }))

            promise.then(
                ({ datesInStock }) => {

                    this.setState({
                        isLoading: false,
                        datesInStock: datesInStock.map(dateInStock => ({
                            ...dateInStock,
                            date: parseInt(dateInStock.date)
                        }))
                    })
                },
                (err) => console.log(err)
            );
        });
    };

    render() {
        return (
            <EstimatedDate
                { ...this.props }
                { ...this.containerProps() }
            />
        )
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EstimatedDateContainer);