/* eslint-disable */

// imports the original component from vendor folder
import { Menu as SourceMenu } from 'SourceComponent/Menu/Menu.component';

// import new extended styles file
import './Menu.override.style.scss';

export class Menu extends SourceMenu {
    renderAdditionalInformation(checkMobile = false) {
        const { device } = this.props;
        if (checkMobile && !device.isMobile) {
            return null;
        }

        return (
            <div 
                block="Menu"
                elem="SelectFields"
            >
                { this.renderStoreSwitcher() }
                { this.renderCurrencySwitcher() }
            </div>
        );
    }
}

export default Menu;
