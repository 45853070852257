import { PureComponent } from 'react';
import { connect } from 'react-redux';
import ProductRecallEmailFormComponent from './ProductRecallEmailForm.component';
import { showNotification } from 'Store/Notification/Notification.action';
import ProductRecallQuery from '@hoeks/product-recall/src/query/ProductRecall.query';
import { fetchQuery } from 'Util/Request';

export const mapStateToProps = (state) => ({
    breadcrumbs: state.BreadcrumbsReducer.breadcrumbs
});

export const mapDispatchToProps = (dispatch) => ({
    showErrorNotification: (message) => dispatch(showNotification('error', message)),
    showSuccessNotification: (message) => dispatch(showNotification('success', message)),
})

export class ProductRecallEmailFormContainer extends PureComponent {
    state = {
        formSuccess: false
    }
    
    containerFunctions = {
        onFormSucess: this.onFormSucess.bind(this),
        onFormError: this.onFormError.bind(this)
    }

    getCookie(name) {
        const nameEQ = name + '=';
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    onFormSucess(fields) {
        const { showErrorNotification, showSuccessNotification, breadcrumbs = [{url: '', name: ''}] } = this.props;
        const { emailwarding = "", email = null } = fields;

        if (email) {
            // console.log("A bot tried to use the form");
            return;
        }

        const frameNumber = this.getCookie('framenumber') ? this.getCookie('framenumber') : null;

        const fromContactAffected = breadcrumbs[0].name.includes('Status Unknown') ? 1 : 0
        console.log('debug');
        try {
            fetchQuery(ProductRecallQuery.addEmailToList(emailwarding, frameNumber, fromContactAffected)).then(({addEmailToList: response}) => {
                const {status = "error", message = "response is empty"} = response;
                if (status == 'success') {
                    showSuccessNotification(message);
                    this.setState({formSuccess: true});
                } else {
                    showErrorNotification(message);
                }
            });
        } catch (error) {
            console.log('debug',error);
        }
    }

    onFormError(fields) {
        console.error('debug', fields)
    }

    render() {
        return (
            <ProductRecallEmailFormComponent
                {...this.containerFunctions}
                {...this.props}
                {...this.state}
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductRecallEmailFormContainer);