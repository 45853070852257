/* eslint-disable */
import DataContainer from 'Util/Request/DataContainer';
import DatesInStockQuery from '../../query/DatesInStock.query';
import { makeCancelable } from 'Util/Promise';
import { prepareQuery } from 'Util/Query';
import { executePost } from 'Util/Request';
import { connect } from 'react-redux';
import EstimatedDateReusable from './EstimatedDateReusable.component';

/** @namespace Component/EstimatedDateReusable/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    showWithin: state.ConfigReducer.stock_warehouses_frontend_show_eta_within,
    showWithinText: state.ConfigReducer.stock_warehouses_frontend_show_eta_within_text
})

/** @namespace Component/EstimatedDateReusable/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({});

/** @namespace Component/EstimatedDateReusable/Container */
export class EstimatedDateReusableContainer extends DataContainer {
    state = {
        dateInStock: 'Loading',
        status: false,
        isLoading: true,
        isShown: true
    }

    componentDidMount() {
        const {
            sku,
            qty,
            isSimple
        } = this.props;

        this.getDatesInStock(sku, qty);

        if (isSimple) {
            this.setState(state => ({...state, isShown: false}));
        }
    }

    componentDidUpdate(prevProps) {
        const {
            sku,
            qty
        } = this.props;

        const {
            sku: prevSku,
            qty: prevQty
        } = prevProps;

        if (sku !== prevSku || qty !== prevQty) {
            this.getDatesInStock(sku, qty);
        }
    }

    componentWillUnmount() {
        if (this.promise) {
            this.promise.cancel();
        }
    }

    getDatesInStock(sku, qty = 1) {
        this.setState({
            dateInStock: 'Loading',
            isLoading: true
        })
        this.fetchData(DatesInStockQuery.getQuery({ skus: [{ sku, qty }] }), this.onSuccess, this.onError);
    }

    onSuccess = (response) => {
        const { datesInStock } = response;
        const etaData = datesInStock.shift();

        const {
            date: dateInStock,
            in_stock: inStock
        } = etaData;

        const { isSimple } = this.props;

        if (!dateInStock) {
            this.setState({
                dateInStock: 'Not in stock',
                status: true,
                isLoading: false,
                isShown: true
            })
            return;
        }

        const dateInt = parseInt(dateInStock);

        if (isNaN(dateInt)) {
            this.setState({
                dateInStock: 'Unknown',
                status: false,
                isLoading: false,
                isShown: false
            })
            return;
        }

        const date = new Date(dateInt);
        const now = new Date();

        if (date - (1000 * 60 * 2) < now) {
            if (inStock) {
                this.setState({
                    dateInStock: __('In stock'),
                    status: true,
                    isLoading: false,
                    isShown: false
                })
            } else {
                this.setState({
                    dateInStock: __('Scheduled for arrival this week'),
                    status: true,
                    isLoading: false,
                    isShown: false
                })
            }
            return;
        }

        const { showWithin, showWithinText } = this.props;
        if (+showWithin) {
            const showWithinDate = new Date();
            showWithinDate.setDate(now.getDate() + (+showWithin));
            if (date > showWithinDate) {
                this.setState({
                    dateInStock: showWithinText ?? __('Awaiting ETA'),
                    status: true,
                    isLoading: false,
                    isShown: true,
                });
                return;
            }
        }

        this.setState({
            dateInStock: isSimple ? __('Pre-order now, ETA %s', date.toLocaleDateString()) : `Expected in stock on ${date.toLocaleDateString()}`,
            status: true,
            isLoading: false,
            isShown: true
        })
    }

    onError = (error) => {
        this.setState({
            dateInStock: 'Failed to load stock',
            status: false,
            isLoading: false,
            isShown: false
        })
        console.log(error);
    }

    fetchData(rawQueries, onSuccess = () => {}, onError = () => {}) {
        const preparedQuery = prepareQuery(rawQueries);

        this.promise = makeCancelable(
            executePost(preparedQuery)
        );

        this.promise.promise.then(
            (response) => onSuccess(response),
            (err) => onError(err)
        );
    }

    render() {
        return <EstimatedDateReusable { ...this.state } { ...this.props } />
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EstimatedDateReusableContainer);