/* eslint-disable */
import ExpandableContentShowMore from 'Component/ExpandableContentShowMore';

const renderDropdown = (args, callback, instance) => {
    const [option] = args;
    const {
        attribute_code,
        attribute_values
    } = option;

    if (attribute_code === 'category_id') {
        return (
            <div
            block="ProductConfigurableAttributes"
            elem="DropDownList"
          >
              <ExpandableContentShowMore showElemCount={ 9999 }>
                  { attribute_values.map((attribute_value) => (
                      instance.renderConfigurableAttributeValue({ ...option, attribute_value })
                  )) }
              </ExpandableContentShowMore>
          </div>
        )
    }

    return callback.apply(instance, args);
}


export default {
    'Component/CategoryConfigurableAttributes/Component': {
        'member-function': {
            renderDropdown
        }
    }
}