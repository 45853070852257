/* eslint-disable */

// imports the original component from vendor folder
import { NewsletterSubscription as SourceNewsletterSubscription } from 'SourceComponent/NewsletterSubscription/NewsletterSubscription.component';

// import new extended styles file
import './NewsletterSubscription.override.style.scss';

export class NewsletterSubscription extends SourceNewsletterSubscription {
}

export default NewsletterSubscription;
