// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import TextPlaceholder from 'Component/TextPlaceholder';
import './BikeFormPlaceholder.style';

/** @namespace Hoeks/UpcomingBike-Form/Component/BikeFormPlaceholder/Component/BikeFormPlaceholderComponent */
export class BikeFormPlaceholderComponent extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    render() {
        return (
            <div block="BikeFormPlaceholder">
                <TextPlaceholder></TextPlaceholder>
            </div>
        );
    }
}

export default BikeFormPlaceholderComponent;
