// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import FieldForm from 'Component/FieldForm';
import Field from 'Component/Field';
import Form from 'Component/Form';
import FormPortal from 'Component/FormPortal';
import { TEXT_TYPE, CHECKBOX_TYPE, SELECT_TYPE } from 'Component/Field/Field.config';
import CheckoutTermsAndConditionsPopup from 'Component/CheckoutTermsAndConditionsPopup';
import BikeFormSuccess from '../BikeFormSuccess';
import './BikeForm.style';

/** @namespace Hoeks/UpcomingBike-Form/Component/BikeForm/Component/BikeFormComponent */
export class BikeFormComponent extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    renderNameAndDescription() {
        const { bikename, bikedescription } = this.props;
        return (
            <div>
                <h2
                    block="BikeForm"
                    elem="Title"
                >{bikename}</h2>
                <p dangerouslySetInnerHTML={{ __html: bikedescription}}></p>
            </div>
        )
    }

    renderHoneyPotTrap() {
        return (
            <>
                <label className='ohnohoney'>Name</label>
                <Field className='ohnohoney' autoComplete="off" type="text" id="name" name="name" placeholder='your name here'/>
                <label className='ohnohoney'>Company Name</label>
                <Field className='ohnohoney' autoComplete="off" type="text" id="company_name" name="company_name" placeholder='your company name here'/>
                <label className='ohnohoney'>Email</label>
                <Field className='ohnohoney' autoComplete="off" type="text" id="email" name="email" placeholder='your email here'/>
                <label className='ohnohoney'>Country</label>
                <Field className='ohnohoney' autoComplete="off" type="text" id="country" name="country" placeholder='your country here'/>
            </>
        )
    }

    renderFields() {
        return (
            <div block="BikeForm" elem="Fields">
                <Field
                    id="company_namewarding"
                    name="company_namewarding"
                    type={TEXT_TYPE}
                    label={__('Company Name')}
                />
                <Field
                    id="namewarding"
                    name="namewarding"
                    type={TEXT_TYPE}
                    label={__('Name')}
                    validation={['notEmpty']}
                />
                <Field
                    id="emailwarding"
                    name="emailwarding"
                    type={TEXT_TYPE}
                    label={__('Email')}
                    validation={['notEmpty', 'email']}
                />
                <Field
                    id="countrywarding"
                    name="countrywarding"
                    type={TEXT_TYPE}
                    label={__('Country')}
                    validation={['notEmpty']}
                />
                <Field
                    id="use_it_forwarding"
                    name="use_it_forwarding"
                    type={SELECT_TYPE}
                    selectOptions={[
                        { id: 1, label: __('Please Select'), value: "none selected" }, 
                        { id: 2, label: __('Food and drink Delivery'), value: "Food and drink Delivery" }, 
                        { id: 3, label: __('Craftsman'), value: "Craftsman" },
                        { id: 4, label: __('Mobile work shops'), value: "Mobile work shops" },
                        { id: 5, label: __('Government and Council'), value: "Government and Council" },
                        { id: 6, label: __('Other'), value: "Other" },
                    ]}
                    value="none selected"
                    label={__('We are planing to use it for:')}
                    validation={['notEmpty']}
                    fromBikeForm={true}
                />
                <Field
                    id="interested_inwarding"
                    name="interested_inwarding"
                    type={SELECT_TYPE}
                    selectOptions={[
                        { id: 1, label: __('Please Select'), value: "none selected" }, 
                        { id: 2, label: "1", value: "1" }, 
                        { id: 3, label: "2-5", value: "2-5" },
                        { id: 4, label: "6-10", value: "6-10" },
                        { id: 5, label: "11-100", value: "11-100" },
                        { id: 6, label: "101+", value: "101+" },
                    ]}
                    value="none selected"
                    label={__('I am interested in:')}
                    validation={['notEmpty']}
                    fromBikeForm={true}
                />
                {this.renderHoneyPotTrap()}
            </div>
        )
    }

    

    renderNewsletterSubscription() {
        const { isNewsletterAccepted, setNewsletterAccepted } = this.props
        return (
            <div block="BikeForm" elem="Newsletter">
                <Field
                    id="newsletter"
                    name="newsletter"
                    type="checkbox"
                    checked={isNewsletterAccepted}
                    onChange={setNewsletterAccepted}
                />
                <label>
                    {__('Sign up for furture Newsletters')}
                </label>
            </div>
        )
    }

    renderTermsAndConditions() {
        const {
            termsAreEnabled,
            termsAndConditions,
            handleShowPopup,
            isTACAccepted,
            setTACAccepted
        } = this.props;

        const {
            checkbox_text = __('I agree to terms and conditions')
        } = termsAndConditions[0] || {};

        if (!termsAreEnabled) {
            return null;
        }

        return (
            <div block="BikeForm" elem="TermsAndConditions">
                <Field
                    id="termsAndConditions"
                    name="termsAndConditions"
                    type="checkbox"
                    value="termsAndConditions"
                    mix={{ block: 'BikeForm', elem: 'TermsAndConditions-Checkbox' }}
                    checked={isTACAccepted}
                    onChange={setTACAccepted}
                />
                <label block="BikeForm" elem="TACLabel">
                    {checkbox_text}
                    <button
                        block="BikeForm"
                        elem="TACLink"
                        onClick={(e) => handleShowPopup(e)}
                    >
                        {__('read more')}
                    </button>
                </label>
            </div>
        )
    }

    renderPopup() {
        return <CheckoutTermsAndConditionsPopup />;
    }

    renderIANR() {
        const {
            isIANRAccepted,
            setIANRAccepted
        } = this.props;
        return (
            <div block="BikeForm" elem="IANR">
                <Field
                    id="IANR"
                    name="IANR"
                    value="IANR"
                    type={CHECKBOX_TYPE}
                    checked={isIANRAccepted}
                    onChange={setIANRAccepted}
                />
                <label>
                    {__('I am not a robot')}
                </label>
            </div>
        )
    }

    renderActions() {
        const { onFormSucess, onFormError, isTACAccepted, isIANRAccepted } = this.props;
        const isBtnDisabled = !isTACAccepted || !isIANRAccepted ? true : false;
        return (
            <>
                <Form
                    id={'NOTIFY'}
                    onSubmitSuccess={(fields) => onFormSucess(fields)}
                    onSubmitError={(fields) => onFormError(fields)}>
                    <button
                        type="submit"
                        block="Button"
                        mix={{ block: "BikeForm", elem: "Button" }}
                        disabled={isBtnDisabled}
                    >
                        {__('Notify Me')}
                    </button>
                </Form>
                {this.renderNewsletterSubscription()}
                {this.renderTermsAndConditions()}
                {this.renderIANR()}
            </>
        )
    }

    render() {
        // console.log('test')
        const { onFormSuccess, bikeFormStatus, bikename, learnmorelink } = this.props;
        return (
            <div block="BikeForm">
                {/* <BikeFormSuccess bikeName={bikename} /> */}
                {bikeFormStatus != "success" ?
                    <>
                        <FormPortal
                            id={'NOTIFY'}
                            name="bike_form"
                        // onSubmitSuccess={onFormSuccess}
                        // mix={{ block: 'FieldForm' }}
                        >
                            {this.renderNameAndDescription()}
                            {this.renderFields()}
                        </FormPortal>
                        {this.renderActions()}
                        {this.renderPopup()}
                    </>
                    :
                    <BikeFormSuccess bikeName={bikename} learnMoreLink={learnmorelink} />
                }
            </div>
        );
    }
}

export default BikeFormComponent;
