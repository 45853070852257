/* eslint-disable */
import { connect } from 'react-redux';
import { UrlRewritesContainer as SourceUrlRewritesContainer, mapStateToProps, mapDispatchToProps } from 'SourceRoute/UrlRewrites/UrlRewrites.container';
import { TYPE_BUILDER_PRODUCT, TYPE_BUILDER_CATEGORY } from './UrlRewrites.config';
export * from 'SourceRoute/UrlRewrites/UrlRewrites.container';

/** @namespace Route/UrlRewrites/Container */
export class UrlRewritesContainer extends SourceUrlRewritesContainer {
    static stateMapping = {
        ...SourceUrlRewritesContainer.stateMapping,
        builderProduct: TYPE_BUILDER_PRODUCT,
        builderCategory: TYPE_BUILDER_CATEGORY
    }

    getTypeSpecificProps() {
        const {
            urlRewrite: {
                id,
                sku
            }
        } = this.props;

        const isLoading = this.getIsLoading();

        switch (this.getType()) {
            case TYPE_BUILDER_PRODUCT:
                /**
                 * In case we are not yet sure what product ID it is:
                 * - check if there is a hint in browser history
                 * - fallback to none
                 */
                if (isLoading) {
                    const product = history?.state?.state?.product;

                    if (product) {
                        const { sku: historySKU } = product;
                        return { productSKU: historySKU };
                    }

                    return {};
                }

                return { productSKU: sku };
            case TYPE_BUILDER_CATEGORY:
                /**
                 * In case we are not yet sure what category ID it is:
                 * - check if there is a hint in browser history
                 * - fallback to none
                 */
                if (isLoading) {
                    const category = history?.state?.state?.category;

                    if (category && category !== true) {
                        return { categoryIds: category };
                    }

                    return {};
                }

                return { categoryIds: id };
            default:
                return super.getTypeSpecificProps();
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UrlRewritesContainer);