/* eslint-disable */
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import BundleSections from './BundleSections.component';

const HOEKS_BUILDER_IMAGE = 'hoeks_builder_image';

export const BundleSectionsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../../store/BundleSections/BundleSections.dispatcher'
);

export const BuilderImagesDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../../store/BuilderImages/BuilderImages.dispatcher'
);

/** @namespace Component/BundleSections/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    currentSection: state.BundleSectionsReducer.section,
    state,
    cartItems: state.CartReducer?.cartTotals?.items
});

/** @namespace Component/BundleSections/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    setCurrentSection: (section) => BundleSectionsDispatcher.then(({ default: dispatcher }) => dispatcher.setCurrentSection(section, dispatch)),
    updateBuilderGallery: (gallery) => BuilderImagesDispatcher.then(({ default: dispatcher }) => dispatcher.updateGallery(gallery, dispatch))
});

/** @namespace Component/BundleSections/Container */
export class BundleSectionsContainer extends PureComponent {
    componentDidMount() {
        this.updateCurrentSection = this.updateCurrentSection.bind(this);
        this.getSections = this.getSections.bind(this);

        const sections = this.getSections();

        if (sections.length > 0) {
            this.updateCurrentSection(sections.shift().name)
        }
    }

    componentDidUpdate(prevProps) {
        const {
            currentSection: prevCurrentSection,
            product: {
                sections: prevSections
            } = {}
        } = prevProps;

        const { 
            currentSection,
            updateBuilderGallery,
            product: {
                sections,
                items
            } = {},
            productOptionsData: {
                productOptions
            } = {},
            areDetailsLoaded
        } = this.props;

        if (prevSections !== sections && this.getSections().length >= 0) {
            this.updateCurrentSection(this.getSections().shift().name)
        }

        // console.log(`prevCurrentSection: ${prevCurrentSection}`);
        // console.log(`currentSection: ${currentSection}`);

        if (prevCurrentSection !== currentSection && productOptions) {
            const currentSectionItems = items.filter(item => item.hoeks_bundle_section === currentSection).filter(item => productOptions.some(option => option.id === item.option_id));

            if (currentSectionItems.length) {
                const selectedOption = currentSectionItems[0].options.find(option => option.id === parseInt(productOptions.find(productOption => productOption.id === currentSectionItems[0].option_id).value[0]));
                // console.log(selectedOption);

                const { 
                    product: {
                        media_gallery_entries: mediaGallery = []
                    } = {}
                } = selectedOption;

                const filteredGallery = mediaGallery.filter(galleryItem => galleryItem.types.some(type => type === HOEKS_BUILDER_IMAGE));
                // console.log(filteredGallery)

                if (areDetailsLoaded) {
                    updateBuilderGallery(filteredGallery);
                }
            } else {
                updateBuilderGallery([]);
            }
        }
    }

    containerProps() {
        return {
            sections: this.getSections(),
        };
    }

    getSections() {
        const { 
            product: {
                sections
            } = {}
        } = this.props;

        return sections.split(',').map(section => ({
            name: section.trim(),
            updateCurrentSection: this.updateCurrentSection
        }));
    }

    updateCurrentSection(section) {
        const { setCurrentSection } = this.props;
        setCurrentSection(section);
    };

    render() {
        return (
            <BundleSections
                { ...this.props }
                { ...this.containerProps() }
            />
        )
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BundleSectionsContainer);