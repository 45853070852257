/* eslint-disable */
import { UPDATE_GALLERY } from "./BuilderImages.action";

/** @namespace Store/BuilderImages/Reducer/getInitialState */
export const getInitialState = () => ({
    gallery: []
});

/** @namespace Store/BuilderImages/Reducer */
export const BuilderImagesReducer = (state = getInitialState(), action) => {
    switch (action.type) {
        case UPDATE_GALLERY:
            const { gallery } = action;
            return {
                ...state,
                gallery
            }
        default:
            return state;
    }
};

export default BuilderImagesReducer;