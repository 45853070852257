/* eslint-disable */
import { StoreSwitcherContainer as SourceStoreSwitcherContainer, mapStateToProps as sourceMapStateToProps, mapDispatchToProps } from 'SourceComponent/StoreSwitcher/StoreSwitcher.container';
import { connect } from 'react-redux';
export * from 'SourceComponent/StoreSwitcher/StoreSwitcher.container';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';
import { STORE_CONFIG_KEY } from 'Component/StoreSwitcher/StoreSwitcher.config';


/** @namespace Component/StoreSwitcher/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
});

/** @namespace Component/StoreSwitcher/Container */
export class StoreSwitcherContainer extends SourceStoreSwitcherContainer {
    _formatStoreList(storeList) {
        const { currentStoreCode } = this.props;
        const originalStoreList = super._formatStoreList(storeList);
        const { pathname = ""} = window.location;
        const updatedStoreList = originalStoreList.filter(({ label = '' }) => !label.includes('B2B'));

        // updatedStoreList.forEach(store => {
        //     const { value, storeLinkUrl } = store;
        //     const path = currentStoreCode != "default" ? this.getRequestedPath(currentStoreCode, pathname) : this.getDefaultRequestedPath(updatedStoreList, pathname);
        //     store.storeLinkUrl = storeLinkUrl + path.substring(1);
        // });

        return updatedStoreList;
    }

    getDefaultRequestedPath(storeList, pathname) {
        var path = pathname;
        storeList.forEach(store => {
            const { value } = store;
            path = path.replace('/' + value, '');
        });

        return path;
    }

    getRequestedPath(storeCode, pathname) {
        if (storeCode == "default") return pathname;
        return pathname.replace('/' + storeCode, '')
    }

    _handleStoreSelect(storeCode) {
        const { showErrorNotification, currentStoreCode } = this.props;
        const { storeList } = this.state;

        const store = storeList.find(
            ({ value }) => value === storeCode
        );

        if (!store) {
            showErrorNotification(__('This store can not be opened!'));
            return;
        }
        this.setCookie("chagedStoreView", true, 1);
        BrowserDatabase.deleteItem(STORE_CONFIG_KEY);
        const { pathname = ""} = window.location;
        const path = currentStoreCode != "default" ? this.getRequestedPath(currentStoreCode, pathname) : this.getDefaultRequestedPath(storeList, pathname);
        window.location = store.storeLinkUrl + path.substring(1);;
    }

    setCookie(name, value, hours) {
        var expires = '';
        if (hours) {
            const date = new Date();
            date.setTime(date.getTime() + (hours * 60 * 60 * 1000));
            expires = '; expires=' + date.toUTCString();
        }

        document.cookie = name + '=' + (value || '') + expires + '; path=/';
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StoreSwitcherContainer);