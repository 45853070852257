/* eslint-disable */

// imports the original component from vendor folder
import SourcePostsListing from 'scandipwa-blog/src/route/PostsListing/PostsListing.component';

// import dependencies
import ContentWrapper from 'Component/ContentWrapper';
import BlogCategories from 'scandipwa-blog/src/component/BlogCategories';
import Fade from 'react-reveal/Fade';
import CmsBlock from 'Component/CmsBlock';

// import new extended styles file
import './PostsListing.override.style.scss';

export class PostsListing extends SourcePostsListing {
    render() {
        const { nameTranslated, path, categoriesUsed } = this.props;
        console.log("BlogListing props:", this.props)

        if (path === 'career') {
            return (
                <main block="PostsListing">
                    <CmsBlock identifier="careers-full-block" />
                    <ContentWrapper label="PostsListing">
                    <Fade bottom small duration={500}>
                        <div block="PostsListing" elem="Sidebar">
                            {/* <BlogSearchBar mix={ { block: 'PostsListing', elem: 'Search' } } /> */}
                            {/* <BlogRecentPosts /> */}
                            <BlogCategories path={ path } categoriesUsed={ categoriesUsed } />
                        </div>
                    </Fade>
                    <div block="PostsListing" elem="ColumnWrapper">
                        <div block="PostsListing" elem="Grid">
                            {this.renderGrid()}
                            {this.renderLoadMore()}
                        </div>
                    </div>
                </ContentWrapper>
                </main>
            );
        }

        return (
            <main block="PostsListing">
                <div class="PostsListing-Header Blog-Video cms-module-hero cms-section use-light-text vertical-center cms-vertical-section-padding cms-double-vertical-section-padding grey-background"><video class="cms-module-hero-videoe-background" autoplay="autoplay" loop="loop" muted width="300" height="150" >
                    <source src="https://larryvsharry.com/media/wysiwyg/cms_pages/video/lvsh-video-new.mp4" type="video/mp4" />
                </video>
                    <div class="cms-section-container">
                        <div class="cms-row has-vertical-col-spacing double-col-spacing equal-height-cols center-cols">
                            <div class="cms-col cms-col-4 cms-col-6-bd cms-col-12-sm">
                                <div class="cms-col-inner cms-col-inner-no-padding">
                                    <h1 class="hero">{ nameTranslated }</h1>
                                </div>
                            </div>
                            <div class="cms-col cms-col-8 cms-col-6-bd hidden-on-sm">
                                <div class="cms-col-inner cms-col-inner-no-padding">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ContentWrapper label="PostsListing">
                    <Fade bottom small duration={500}>
                        <div block="PostsListing" elem="Sidebar">
                            {/* <BlogSearchBar mix={ { block: 'PostsListing', elem: 'Search' } } /> */}
                            {/* <BlogRecentPosts /> */}
                            <BlogCategories path={ path } categoriesUsed={ categoriesUsed } />
                        </div>
                    </Fade>
                    <div block="PostsListing" elem="ColumnWrapper">
                        <div block="PostsListing" elem="Grid">
                            {this.renderGrid()}
                            {this.renderLoadMore()}
                        </div>
                    </div>
                </ContentWrapper>
            </main>
        );
    }

}

export default PostsListing;
