/* eslint-disable */
export * from 'SourceUtil/Url/Url';

import { getStore } from 'Store';

/**
 * Append store code to URL
 * @param {String} pathname the URL to append store code to
 * @namespace Util/Url/appendWithStoreCode
 */
 export const appendWithStoreCode = (pathname) => {
    const { ConfigReducer: { base_link_url = window.location.origin } = {} } = getStore().getState() || {};
    const { pathname: storePrefix } = new URL(base_link_url);

    // ignore empty URLs
    if (!pathname) {
        return pathname;
    }

    // match URLs which have the store code in pathname
    if (pathname.match(`^/(${window.storeList.join('|')})(/|$)`)) {
        return pathname;
    }

    if (!pathname.startsWith('/')) {
        // eslint-disable-next-line no-param-reassign
        pathname = `/${ pathname }`;
    }

    // trim the last slash from URL, and append it to pathname
    return storePrefix.slice(0, -1).concat(pathname);
};