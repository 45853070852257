/* eslint-disable */
// Plugin to add classes to the body of the page based on which route is mounted.

const IS_HOME_PAGE = 'isHomePage';
const IS_CMS_PAGE = 'isCmsPage';
const IS_PRODUCT_PAGE = 'isProductPage';
const IS_CATEGORY_PAGE = 'isCategoryPage';
const IS_SEARCH_PAGE = 'isSearchPage';
const IS_BUILDER_PAGE = 'isBuilderPage';
const IS_ACCOUNT_PAGE = 'isAccountPage';
const IS_CHECKOUT_PAGE = 'isCheckoutPage';
const IS_MENU_PAGE = 'isMenuPage';
const IS_CART_PAGE = 'isCartPage';

const addClass = (className) => (args, callback, instance) => {
    document.body.classList.add(className);
    return callback.apply(instance, args);
}

const removeClass = (className) => (args, callback, instance) => {
    document.body.classList.remove(className);
    return callback.apply(instance, args);
}

export default {
    'Route/HomePage/Container': {
        'member-function': {
            'componentDidMount': addClass(IS_HOME_PAGE),
            'componentWillUnmount': removeClass(IS_HOME_PAGE)
        }
    },
    'Route/CmsPage/Container': {
        'member-function': {
            'componentDidMount': addClass(IS_CMS_PAGE),
            'componentWillUnmount': removeClass(IS_CMS_PAGE)
        }
    },
    'Route/ProductPage/Container': {
        'member-function': {
            'componentDidMount': addClass(IS_PRODUCT_PAGE),
            'componentWillUnmount': removeClass(IS_PRODUCT_PAGE)
        }
    },
    'Route/CategoryPage/Container': {
        'member-function': {
            'componentDidMount': addClass(IS_CATEGORY_PAGE),
            'componentWillUnmount': removeClass(IS_CATEGORY_PAGE)
        }
    },
    'Route/SearchPage/Container': {
        'member-function': {
            'componentDidMount': addClass(IS_SEARCH_PAGE),
            'componentWillUnmount': removeClass(IS_SEARCH_PAGE)
        }
    },
    'Route/BuilderProductPage/Container': {
        'member-function': {
            'componentDidMount': addClass(IS_BUILDER_PAGE),
            'componentWillUnmount': removeClass(IS_BUILDER_PAGE)
        }
    },
    'Route/MyAccount/Container': {
        'member-function': {
            'componentDidMount': addClass(IS_ACCOUNT_PAGE),
            'componentWillUnmount': removeClass(IS_ACCOUNT_PAGE)
        }
    },
    'Route/Checkout/Container': {
        'member-function': {
            'componentDidMount': addClass(IS_CHECKOUT_PAGE),
            'componentWillUnmount': removeClass(IS_CHECKOUT_PAGE)
        }
    },
    'Route/MenuPage/Container': {
        'member-function': {
            'componentDidMount': addClass(IS_MENU_PAGE),
            'componentWillUnmount': removeClass(IS_MENU_PAGE)
        }
    },
    'Route/CartPage/Container': {
        'member-function': {
            'componentDidMount': addClass(IS_CART_PAGE),
            'componentWillUnmount': removeClass(IS_CART_PAGE)
        }
    }
}