/* eslint-disable */
import WidgetFactory from 'SourceComponent/WidgetFactory';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export class BlogPostListWidget extends PureComponent {
    getSliderSettings() {
        return {
            dots: true,
            infinite: true,
            arrows: true
        }
    }

    renderTitle() {
        const {
            title
        } = this.props;

        return (
            <div
                block="BlogPostListWidget"
                elem="Title"
            >{ title }</div>
        )
    }

    renderSlider() {
        const {
            children
        } = this.props;

        return (
            <Slider
                { ...this.getSliderSettings() }
            >
                { children.filter(child => child.type === WidgetFactory) }
            </Slider>
        )
    }

    renderList() {
        const {
            children
        } = this.props;

        return (
            <div
                block="BlogPostListWidget"
                elem="Container"
            >
                { children }
            </div>
        )
    }

    render() {
        const {
            isslider
        } = this.props;

        if (isslider) {
            return this.renderSlider();
        }

        return this.renderList();
    }
}

export default BlogPostListWidget;