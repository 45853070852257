/* eslint-disable */
import { formatPrice } from 'Util/Price';

const renderOptionLabel = (args, callback, instance) => {
    const [priceType, price] = args;

    const { 
        props: {
            price_range: {
                minimum_price: {
                    final_price: {
                        currency
                    } = {}
                } ={}
            } = {}
        } = {}
    } = instance;

    switch (priceType) {
        case 'PERCENT':
            return `${ price }%`;
        default:
            return formatPrice(price, currency);
    }
};

export default {
    'Component/ProductCustomizableOption/Container': {
        'member-function': {
            renderOptionLabel
        }
    }
};