import PopupWidget from '../component/PopupWidget';

const POPUP = 'Popup';
const renderMap = (member, instance) => {

    const val = {
        ...member,
        [POPUP]: {
            component: PopupWidget
        }
    }
    return val;
}

const renderContent = (args, callback, instance) => {
    const { type } = instance.props;

    if (type === POPUP) {
        const {
            component: Widget,
        } = instance.renderMap[type] || {};

        if (Widget !== undefined) {
            return (
                <Widget { ...instance.props } />
            )
        }
    }

    return callback.apply(instance, args);
}

export default {
    'Component/WidgetFactory/Component': {
        'member-property': {
            renderMap
        },
        'member-function': {
            renderContent
        }
    },
}