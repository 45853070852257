import Event, { EVENT_GTM_PURCHASE } from '../util/Event';

const getOrderData = async (args, callback, instance) => {
    const result = await callback(...args);

    const {
        paymentData: {
            order_id,
            totals
        } = {},
        cartTotals: {
            items = []
        } = {}
    } = result;

    Event.dispatch(
        EVENT_GTM_PURCHASE,
        { orderID: order_id, totals: { ...totals, items } }
    );

    return result;
};

export default {
    'Hoeks/QuickPay/Route/QuickPayGateway/Container': {
        'member-function': {
            'getOrderData': getOrderData
        }
    }
};
