
/**
 * Product helper, contain all related to product data prepare methods
 */
import { roundPrice } from 'Util/Price';

export default class Product4 {
    static getCurrency(product) {
        const {
            price_range: {
                minimum_price: {
                    final_price: {
                        currency
                    } = {}
                } = {}
            } = {}
        } = product;

        return currency;
    }

    static getProductData(product) {
        const { categories } = product;
        const variant = this.getProductVariant(product);
        const {
            sku,
            name,
            price_range: {
                minimum_price: {
                    discount: {
                        amount_off: discount
                    },
                    regular_price: {
                        currency,
                        value: price
                    }
                } = {}
            } = {}
        } = variant;

        return {
            name,
            id: sku,
            price: +roundPrice(price),
            discount: +roundPrice(discount),
            currency,
            brand: this.getItemBrand(product),
            ...this.getVariantString(product, variant),
            position: 1,
            category: this.getCategoryData(categories ? product : variant)
        };
    }

    static getItemData(item) {
        if (item && Object.values(item).length) {
            const { product = {}, sku = '' } = item;
            const configurableVariantIndex = this.getSelectedVariantIndex(product, sku);

            return this.getProductData({ ...product, configurableVariantIndex });
        }

        return {};
    }

    static getSelectedVariantIndex(product, sku) {
        const { variants = [] } = product;
        return variants.findIndex(({ sku: variantSku = '' }) => sku === variantSku);
    }

    static getProductVariant(product){
        const { configurableVariantIndex, variants } = product;
        if (configurableVariantIndex >= 0 && configurableVariantIndex < variants.length ){
            return variants[configurableVariantIndex];
        }
        return product;
    }

    static getItemBrand(product) {
        const {
            attributes: {
                brands: {
                    attribute_value = 0,
                    attribute_options = {}
                } = {}
            } = {}
        } = product;
        return attribute_options[attribute_value]?.label || null;
    }

    static getCategoryData(product){
        const { categories = [], prevCategoryId } = product;

        const category = categories.find(({ id }) => id === prevCategoryId) || this.findLongestBreadcrumbs(categories);

        const {
            breadcrumbs = [],
            name
        } = category;

        const breadcrumbNames = (breadcrumbs || []).reduce((acc, { category_name }) => ([ ...acc, category_name ]), []);
        const hierarchies = [ ...breadcrumbNames, name ];
        const categoryData = hierarchies.join('/');

        return categoryData;
    }

    static findLongestBreadcrumbs(categories) {
        const { breadcrumbsCategory = {} } = categories.reduce((acc, category) => {
            const { longestBreadcrumbsLength } = acc;
            const { breadcrumbs } = category;
            const breadcrumbsLength = (breadcrumbs || []).length;
            if (!breadcrumbsLength && longestBreadcrumbsLength !== 0) {
                return acc;
            }

            if (breadcrumbsLength < longestBreadcrumbsLength) {
                return acc;
            }

            return {
                breadcrumbsCategory: category,
                longestBreadcrumbsLength: breadcrumbsLength
            };
        }, {
            breadcrumbsCategory: {},
            longestBreadcrumbsLength: 0
        });
        return breadcrumbsCategory;
    }

    static getVariantString(product, variant){
        const { configurable_options } = product;
        if (configurable_options){
            const labels = Object.keys(configurable_options).map((key) => {
                const {
                    attributes: {
                        [key]: {
                            attribute_value = 0,
                            attribute_options: variantAttributeOptions = {}
                        } = {}
                    } = {}
                } = variant;
                const {
                    [key]: {
                        attribute_options = {}
                    } = {}
                } = configurable_options;
                return variantAttributeOptions[attribute_value]?.label || attribute_options[attribute_value]?.label || null;
            });
            const result = labels.filter((label) => !!label).join(',');
            return result ? { variant: result } : {}; 
        }
        return {};
    }

    static getProductData(product, category) {
        const { categories } = product;
        const variant = this.getProductVariant(product);
        const {
            sku,
            name,
            price_range: {
                minimum_price: {
                    discount: {
                        amount_off: discount
                    },
                    regular_price: {
                        currency,
                        value: price
                    }
                }
            },
        } = variant;

        return {
            item_id: sku,
            item_name: name,
            price: +roundPrice(price),
            currency,
            discount: +roundPrice(discount),
            item_brand: this.getItemBrand(variant),
            ...(category ? this.getCategoryData(category) : this.getProductCategoryData(categories ? product : variant)),
            ...this.getVariantString(product, variant)
        };
    }

    static getProductCategoryData(product){
        const { categories = [], prevCategoryId } = product;

        const category = categories.find(({ id }) => id === prevCategoryId) || this.findLongestBreadcrumbs(categories);

        return this.getCategoryData(category);
    }

    static getCategoryData(category){

        const {
            breadcrumbs = [],
            name
        } = category;

        const breadcrumbNames = (breadcrumbs || []).reduce((acc, { category_name }) => ([ ...acc, category_name ]), []);
        const hierarchies = [ ...breadcrumbNames, name ];
        const categoryData = hierarchies.reduce((acc, val, idx) => ({ ...acc, [`item_category${ idx ? idx + 1 : '' }`]: val }), {});

        return categoryData;
    }

    static getItemSkus(items = []) {
        return items.map(({ item_id = '' }) => item_id);
    }

    static getItemsValue(items = []) {
        return +roundPrice(items.reduce((acc, { price, discount }) => acc + price - discount, 0));
    }
}