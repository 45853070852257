/* eslint-disable */
import { MyAccountQuery as SourceMyAccountQuery } from 'SourceQuery/MyAccount.query';

export class MyAccountQuery extends SourceMyAccountQuery {
    _getCustomerFields() {
        return [
            ...super._getCustomerFields(),
            'store_id',
            this._getAddressesField()
        ];
    }
}

export default new MyAccountQuery();