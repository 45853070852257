/* eslint-disable */
export * from 'SourceComponent/Form/Form.config';

import { formConfig as sourceFormConfig } from 'SourceComponent/Form/Form.config';

export const MAX_ADDRESS_LENGTH = 30;

/** @namespace Component/Form/Config/validateAddressLength */
const validateAddressLength = ({ value }) => value.length <= MAX_ADDRESS_LENGTH;

const validateFrameNumberWLH = ({value}) => {
    const frame = value.replace(/\s/g, '').toUpperCase();
    if (frame == "MISSING") return true;
    
    return frame.substring(0, 3) == 'WLH'
}

const validateFrameNumberDigis = ({value}) => {
    const frame = value.replace(/\s/g, '').toUpperCase();
    if (frame == "MISSING") return true;

    const digits = isNaN(frame[frame.length - 1]) ? frame.substring(3, frame.length - 1) : frame.substring(3);
    if (!isNaN(digits)) {
        // console.log('debug', digits.length)
        if (digits.length == 4 || digits.length == 5) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
    // return !isNaN(digits) && (digits.length >= 4 || digits.length <= 5);
}

const validateFrameNumberEndLetter = ({value}) => {
    const frame = value.replace(/\s/g, '').toUpperCase();
    return (/[a-zA-Z]/).test(frame.substring(frame.length - 1));
}

const validateFrameNumberSpecialChar = ({value}) => {
    const frame = value.replace(/\s/g, '').toUpperCase();
    return (/^[a-z0-9]+$/i).test(frame)
}

const validateFrameNumberQtyNumber = ({value}) => (/[0-9]+$/g).test(value)

/** @namespace Component/Form/Config */
export const formConfig = () => ({
    ...sourceFormConfig(),
    addressLength: {
        validate: validateAddressLength,
        message: __('Address should be a maxmimum of %s characters.', MAX_ADDRESS_LENGTH)
    },
    frameNumberWLH: {
        validate: validateFrameNumberWLH,
        message: __('Frame number must start with "WLH". (e.g. "WLH####N")')
    },
    frameNumberDigits: {
        validate: validateFrameNumberDigis,
        message: __('Frame number must have 4 to 5 digit number. (e.g. "WLH####N")')
    },
    frameNumberEndLetter: {
        validate: validateFrameNumberEndLetter,
        message: __('Frame number must end on a letter. (e.g. "WLH####N")')
    },
    frameNumberSpecialChar: {
        validate: validateFrameNumberSpecialChar,
        message: __('Only numbers (0-9) and letters (A-Z) are allowed.')
    },
    frameNumberQtyNumber: {
        validate: validateFrameNumberQtyNumber,
        message: __('Only numbers (0-9) are allowed')
    }
});

export default formConfig();