/* eslint-disable */
import { PureComponent } from 'react';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

/* Import dependencies */
import Link from 'Component/Link';

import './BundleSections.style';

/** @namespace Component/BundleSections/Component */
export class BundleSections extends PureComponent {
    goBack() {
        history.push(appendWithStoreCode('/bike-builder'));
    }

    renderSection(section) {
        const { currentSection } = this.props;
        const { name, updateCurrentSection } = section;

        return (
            <span 
                block="BundleSections"
                elem="Section"
                mods={ { isActive: currentSection === name } }
                key={name}
                onClick={() => updateCurrentSection(name)
            }>
                { __(name) }
            </span>
        )
    }

    renderSections() {
        const { sections } = this.props;

        return sections.map(section => this.renderSection(section));
    }

    render() {
        const { cartItems = [] } = this.props;

        return (
            <section
                block="BundleSections"
            >
                <button
                    block="BundleSections"
                    elem="BackToShop"
                    aria-label="Back to Overview"
                    onClick={ this.goBack }
                >
                    { __('Back to Overview') }
                </button>
                <div
                    block="BundleSections"
                    elem="SectionItems"
                >
                    { this.renderSections() }
                </div>
                <Link
                    block="BundleSections"
                    elem="ToCheckout"
                    mods={ { isSomethingInCart: cartItems.length > 0 } }
                    aria-label="Go to checkout"
                    to={ '/cart' }
                >
                    { __('Go to Cart') }
                </Link>
            </section>
        )
    }
}

export default BundleSections;