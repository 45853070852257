/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
/* eslint-disable */

import { Route } from 'react-router-dom';
import { withStoreRegex } from 'Component/Router/Router.component';

import PostsDetails from '../route/PostsDetails';
import PostsListing from '../route/PostsListing';

export class RouterPlugin {
  /**
   * Adds blog url handles to the router SWITCH_ITEMS_TYPE list
   * @param originalMember
   * @returns {*[]}
   */
  switchItems = (originalMember) => [
    ...originalMember,
    {
      component: <Route path={ withStoreRegex("/blog/:handle") } component={PostsDetails} />,
      position: 100,
    },
    {
      component: <Route path={ withStoreRegex("/blog") } component={PostsListing} />,
      position: 110,
    },
    {
      component: <Route path={ withStoreRegex("/career/:handle") } component={ (props) => <PostsDetails { ...props }  path={ 'career' } name={ 'Career' } nameTranslated={ __('Career') } /> } />,
      position: 120,
    },
    {
      component: <Route path={ withStoreRegex("/career") } component={ (props) => <PostsListing { ...props }  path={ 'career' } name={ 'Career' } nameTranslated={ __('Career') } /> } />,
      position: 130,
    },
  ];
}

const { switchItems } = new RouterPlugin();

export const config = {
  'Component/Router/Component': {
    'member-property': {
      SWITCH_ITEMS_TYPE: switchItems,
    },
  },
};

export default config;
