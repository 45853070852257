/* eslint-disable */
import { lazy } from 'react';
import { UrlRewrites as SourceUrlRewrites } from 'SourceRoute/UrlRewrites/UrlRewrites.component';
import { TYPE_BUILDER_CATEGORY, TYPE_BUILDER_PRODUCT } from './UrlRewrites.config';
export * from 'SourceRoute/UrlRewrites/UrlRewrites.component';

export const BuilderProductPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "builderProduct" */ 'Route/BuilderProductPage'));
export const BuilderCategoryPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "builderCategory" */ 'Route/BuilderCategoryPage'));

export class UrlRewrites extends SourceUrlRewrites {
    renderContent() {
        const { props, type } = this.props;

        switch (type) {
        case TYPE_BUILDER_PRODUCT:
            return <BuilderProductPage { ...props } />;
        case TYPE_BUILDER_CATEGORY:
            return <BuilderCategoryPage { ...props } />;
        default:
            return super.renderContent()
        }
    }
}

export default UrlRewrites;