import { QUICKPAY_PAYMENT, QUICKPAY_PAYMENT_TOTALS } from '../component/QuickPay/QuickPay.config';
import QuickPayQuery from '../query/QuickPay.query';
import { isSignedIn } from 'Util/Auth';
import { GUEST_QUOTE_ID } from 'Store/Cart/Cart.dispatcher';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';
import BrowserDatabase from 'Util/BrowserDatabase';
import {
    PAYMENT_TOTALS
} from 'Route/Checkout/Checkout.config';

const Auth = import('Util/Auth');
const RequestImport = import('Util/Request');
const CheckoutQueryImport = import(/* webpackMode: "lazy", webpackChunkName: "dispatchers" */ 'Query/Checkout.query');

export class CheckoutContainerPlugin {
    aroundSavePaymentMethodAndPlaceOrder = async (args, callback, instance) => {
    // console.log('CheckoutContainer.plugin.js')
    const [paymentInformation] = args;
        const { paymentMethod: { code, additional_data } } = paymentInformation;

        if (code === QUICKPAY_PAYMENT){
            // const { isSignedIn } = await Auth;
            const guest_cart_id = !isSignedIn() ? instance._getGuestCartId() : '';
            try {

                const { default: CheckoutQuery } = await CheckoutQueryImport;
                const { fetchMutation } = await RequestImport;

                await fetchMutation(CheckoutQuery.getSetPaymentMethodOnCartMutation({
                    guest_cart_id,
                    payment_method: {
                        code,
                        [code]: additional_data
                    }
                }));

                const orderData = await fetchMutation(CheckoutQuery.getPlaceOrderMutation(guest_cart_id));

                const { resetCart, resetGuestCart, totals } = instance.props;

                if (!isSignedIn()) {
                    BrowserDatabase.deleteItem(GUEST_QUOTE_ID);
                }

                BrowserDatabase.deleteItem(PAYMENT_TOTALS);
                BrowserDatabase.setItem(totals, QUICKPAY_PAYMENT_TOTALS, ONE_MONTH_IN_SECONDS)

                if (isSignedIn()) {
                    resetCart();
                } else {
                    resetGuestCart();
                }

                const { quickpayLink: { payment_link } } = await fetchMutation(QuickPayQuery.getCreateQuickPayPaymentLinkMutation());
                window.location.href = payment_link;
            } catch (e) {
                instance._handleError(e);
            }
        }
        else {
            callback.apply(instance, args);
        }
    };
}

const {
    aroundSavePaymentMethodAndPlaceOrder
} = new CheckoutContainerPlugin();


export const config = {
    'Route/Checkout/Container': {
        'member-function': {
            savePaymentMethodAndPlaceOrder: aroundSavePaymentMethodAndPlaceOrder
        }
    }
};

export default config;
