/* eslint-disable */

// imports the original component from vendor folder
import { Header as SourceHeader } from 'SourceComponent/Header/Header.component';

// import dependencies
import OfflineNotice from 'Component/OfflineNotice';
import StoreSwitcher from 'Component/StoreSwitcher';
import CurrencySwitcher from 'Component/CurrencySwitcher';
import Link from 'Component/Link';
import Menu from 'Component/Menu';
import CmsBlock from 'Component/CmsBlock';

import {
    CART_OVERLAY,
    CUSTOMER_WISHLIST
} from './Header.config';

// import new extended style file
import './Header.override.style.scss';

export class Header extends SourceHeader { 

    // removed account title
    renderAccountButton(isVisible) {
        const {
            onMyAccountButtonClick
        } = this.props;

        return (
            <button
              block="Header"
              elem="MyAccountWrapper"
              tabIndex="0"
              onClick={ onMyAccountButtonClick }
              aria-label="Open my account"
              id="myAccount"
            >
                <div
                  block="Header"
                  elem="Button"
                  mods={ { isVisible, type: 'account' } }
                />
            </button>
        );
    }

    // remove minicart title
    renderMinicartButton() {
        const {
            onMinicartButtonClick
        } = this.props;

        return (
            <button
              block="Header"
              elem="MinicartButtonWrapper"
              tabIndex="0"
              onClick={ onMinicartButtonClick }
              aria-label="Open minicart"
            >
                <span
                  aria-label="Minicart"
                  block="Header"
                  elem="MinicartIcon"
                />
                { this.renderMinicartItemsQty() }
            </button>
        );
    }

    // new function to add two new header buttons
    renderAdditionalHeaderButtons() {
        return (
            <>
                <Link
                block="Header"
                elem="ShopLink"
                aria-label="Go to shop"
                to={ 'shop' }
                >
                    { __('Shop') }
                </Link>
                <Link
                block="Header"
                elem="BuilderLink"
                aria-label="Go to bike builder"
                to={ 'bike-builder' }
                mix={ { block: 'Button' } }
                >
                    { __('Bike Builder') }
                </Link>
            </>
        );
    }

    // new function to add storeswitcher to header instead of topmenu
    renderStoreSwitcher() {
        return (
            <>
                <StoreSwitcher/>
            </>
        );
    }

    // new function to add currencyswitcher to header instead of topmenu
    renderCurrencySwitcher() {
        const { device } = this.props;

        if (device.isMobile) {
            return null;
        }

        return (
            <>
                <CurrencySwitcher/>
            </>
        );
    }

    renderStoreSwitcherBanner() {
        const { userCountry, storeCode, isStoreSwitcherClosed, closeStoreSwitcher, handleOnSwitchStoreClick } = this.props;
        // console.log(userCountry);
        // console.log(storeCode);

        if (isStoreSwitcherClosed) {
            return null;
        }
        
        if (!storeCode) {
            return null;
        }
        
        if (userCountry === '') {
            return null;
        }
        
        if (storeCode === 'da') {
            return null;
        }
        
        if (userCountry !== 'DK') {
            return null;
        }
        
        return (
            <div
            block="StoreSwitcherBanner"
            elem="Container"
            mix={ { block: 'Header', elem: 'StoreSwitcherBanner' } }
            >
                <div
                  block="StoreSwitcherBanner"
                  elem="Content"
                  >
                    <div
                      block="StoreSwitcherBanner"
                      elem="Text"
                    >
                        {__('It looks like you are located in')}<strong> {__('Denmark')}</strong>{__(', do you want to switch to the')} <strong>{__('Danish')}</strong> {__('version of the site?')}
                    </div>
                    <div
                      block="StoreSwitcherBanner"
                      elem="Actions"
                    >
                        <a
                          block="StoreSwitcherBanner"
                          elem="SwitcherButton"
                          mix={ { block: 'Button' } }
                          aria-label="Switch Store"
                          onClick={handleOnSwitchStoreClick}
                        >
                            { __('Switch to the Danish Version') }
                        </a>
                    </div>
                    <a
                      block="StoreSwitcherBanner"
                      elem="CloseButton"
                      onClick={closeStoreSwitcher}
                    >
                        { __('Close') }
                    </a>
                </div>
            </div>
        );
    }

    // remove compare button
    // add storeswitcher
    // add buttons
    // reposition logo
    renderMap = {
        logo: this.renderLogo.bind(this),
        cancel: this.renderCancelButton.bind(this),
        back: this.renderBackButton.bind(this),
        close: this.renderCloseButton.bind(this),
        share: this.renderShareWishListButton.bind(this),
        title: this.renderTitle.bind(this),
        storeswitcher: this.renderStoreSwitcher.bind(this),
        currencyswitcher: this.renderCurrencySwitcher.bind(this),
        account: this.renderAccount.bind(this),
        minicart: this.renderMinicart.bind(this),
        search: this.renderSearchField.bind(this),
        clear: this.renderClearButton.bind(this),
        edit: this.renderEditButton.bind(this),
        ok: this.renderOkButton.bind(this),
        buttons: this.renderAdditionalHeaderButtons.bind(this)
    };

    //Edit to keep header on checkout
    renderMenu() {
        const { isCheckout, device } = this.props;

        if (device.isMobile) {
            return null;
        }

        return <Menu />;
    }


    renderTempMessage() {
        return (
            <div
              block="Header"
              elem="TempMessage"
            >
                <CmsBlock identifier={ "top-banner-temp-message" } />
            </div>
        );
    }

    // removed topbar
    // added store swithcer
    // added currency swithcer
    // added custom buttons
    render() {
        const { stateMap } = this;
        const {
            navigationState: { name, isHiddenOnMobile = false },
            isCheckout,
            device
        } = this.props;

        if (!device.isMobile) {
            // hide edit button on desktop
            stateMap[CUSTOMER_WISHLIST].edit = false;
            stateMap[CUSTOMER_WISHLIST].share = false;
            stateMap[CART_OVERLAY].edit = false;
        }

        return (
            <>
                { this.renderStoreSwitcherBanner() }
                <section block="Header" elem="Wrapper">
                    <header
                    block="Header"
                    mods={ { name, isHiddenOnMobile, isCheckout } }
                    mix={ { block: 'FixedElement', elem: 'Top' } }
                    ref={ this.logoRef }
                    >
                        <nav block="Header" elem="Nav">
                            { this.renderNavigationState() }
                        </nav>
                        { this.renderMenu() }
                    </header>
                    <OfflineNotice />
                </section>
            </>
        );
    }
}

export default Header;
