/* eslint-disable */

// imports the original component from vendor folder
import { NavigationTabs as SourceNavigationTabs } from 'SourceComponent/NavigationTabs/NavigationTabs.component';

// import new extended styles file
import './NavigationTabs.override.style.scss';

export class NavigationTabs extends SourceNavigationTabs {

    renderHomeButton(isActive = false) {
        const { onHomeButtonClick } = this.props;

        return (
            <button
              key="home"
              block="NavigationTabs"
              elem="Button"
              aria-label="Home"
              onClick={ onHomeButtonClick }
            >
                <div
                  block="Header"
                  elem="Button"
                  mix={ { block: 'NavigationTabs', elem: 'Icon', mods: { isActive } } }
                  mods={ { type: 'home', isVisible: true } }
                />
                <span
                  block="NavigationTabs"
                  elem="MenuOverlay"
                ></span>
            </button>
        );
    }

    renderMenuButton(isActive = false) {
        const { onMenuButtonClick } = this.props;

        return (
            <button
              key="menu"
              block="NavigationTabs"
              elem="Button"
              aria-label="Go to menu and search"
              onClick={ onMenuButtonClick }
            >
                <div
                  block="Header"
                  elem="Button"
                  mix={ { block: 'NavigationTabs', elem: 'Icon', mods: { isActive } } }
                  mods={ { isVisible: true, type: 'menu' } }
                />
                <span
                  block="NavigationTabs"
                  elem="MenuOverlay"
                ></span>
            </button>
        );
    }

    renderAccountButton(isActive = false) {
        const { onMyAccountButtonClick } = this.props;

        return (
            <button
              key="account"
              block="NavigationTabs"
              elem="Button"
              onClick={ onMyAccountButtonClick }
              aria-label="Open my account"
            >
                <div
                  block="Header"
                  elem="Button"
                  mix={ { block: 'NavigationTabs', elem: 'Icon', mods: { isActive } } }
                  mods={ { isVisible: true, type: 'account' } }
                />
                <span
                  block="NavigationTabs"
                  elem="MenuOverlay"
                ></span>
            </button>
        );
    }

    renderMinicartButton(isActive = false) {
      const { onMinicartButtonClick } = this.props;

      return (
          <button
            key="mincart"
            block="NavigationTabs"
            elem="Button"
            onClick={ onMinicartButtonClick }
            aria-label="Minicart"
          >
              <div
                block="Header"
                elem="Button"
                mix={ { block: 'NavigationTabs', elem: 'Icon', mods: { isActive } } }
                mods={ { isVisible: true, type: 'minicart' } }
              >
                  { this.renderMinicartItemsQty() }
              </div>
              <span
                  block="NavigationTabs"
                  elem="MenuOverlay"
                ></span>
          </button>
      );
  }
    

}

export default NavigationTabs;
