/* eslint-disable */
import { SET_IS_HEADER_SHOWN } from "./Header.action";

/** @namespace Store/Header/Reducer/getInitialState */
export const getInitialState = () => ({
    isHeaderShown: true
});

/** @namespace Store/Header/Reducer */
export const HeaderReducer = (state = getInitialState(), action) => {
    switch (action.type) {
        case SET_IS_HEADER_SHOWN:
            const { isHeaderShown } = action;
            return {
                ...state,
                isHeaderShown
            }
        default:
            return state;
    }
};

export default HeaderReducer;