import React from 'react';
import { Route } from 'react-router-dom';
import StoreFinder from '../route/StoreFinder';
import { withStoreRegex } from 'Component/Router/Router.component';

// export class RouterPlugin {
//     beforeSwitchItemsType = originalMember => [
//         ...originalMember,
//         {
//             component: <Route path="/stores" exact component={ StoreFinder } />,
//             position: 90
//         }
//     ];
// }

const beforeSwitchItemsType = originalMember => {
    // console.log('beforeSwitchItemsType');
    // console.log(`withStoreRegex('/stores'): ${withStoreRegex('/stores')}`);
    return [
        ...originalMember,
        {
            component: <Route path={withStoreRegex('/stores')} exact render={(props) => <StoreFinder {...props} />} />,
            position: 90
        }
    ];
}

// const {
//     beforeSwitchItemsType
// } = new RouterPlugin();

// const renderMainItems = (args, callback, instance) => {
//     console.log(instance);
//     return callback(...args);
// }

export const config = {
    'Component/Router/Component': {
        'member-property': {
            SWITCH_ITEMS_TYPE: [
                {
                    position: 100,
                    implementation: beforeSwitchItemsType
                }
            ]
        }
    }
};

export default config;
