/* eslint-disable */
import { DEFAULT_STATE_NAME } from 'Component/NavigationAbstract/NavigationAbstract.config';

const componentDidMount = (args, callback, instance) => {
    const { changeHeaderState } = instance.props;

    const val = callback.apply(instance, args);

    changeHeaderState({
        name: DEFAULT_STATE_NAME,
        isHiddenOnMobile: true
    });

    return val;
}

export default {
    'Route/HomePage/Container': {
        'member-function': {
            componentDidMount
        }
    }
};