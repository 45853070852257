/* eslint-disable */

// imports the original component from vendor folder
import HoeksBlogCategories from 'scandipwa-blog/src/component/BlogCategories/BlogCategories.component';

// import new extended styles file
import './BlogCategories.override.style.scss';

export class BlogCategories extends HoeksBlogCategories { 
    render() {
        return (
            <div block="BlogCategories" elem="Wrapper">
                { this.renderContent() }
            </div>
        );
    }

}

export default BlogCategories;
