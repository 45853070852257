/* eslint-disable */
import { Field as SourceField }
    from 'SourceComponent/Field/Field.component';
import FieldSelectStoreFinderContainer from '../FieldSelectStoreFinder/FieldSelectStoreFinder.container';

/**
 * Input fields component
 * @class Field
 * @namespace Component/Field/Component
 */
export class FieldStoreFinder extends SourceField {
    renderSelectWithOptions() {
        return (
            <FieldSelectStoreFinderContainer
              { ...this.props }
            />
        );
    }
}

export default FieldStoreFinder;