import { PureComponent } from 'react';
import TextPlaceholder from 'Component/TextPlaceholder';
export class ProductRecallEmailFormPlaceholder extends PureComponent {
    render() {
        return (
            <div block="ProductRecallEmailFormPlaceholder">
                <TextPlaceholder></TextPlaceholder>
            </div>
        );
    }
}

export default ProductRecallEmailFormPlaceholder;