/* eslint-disable */

// imports the original component from vendor folder
import { TierPrices as SourceTierPrices } from 'SourceComponent/TierPrices/TierPrices.component';

// import new extended styles file
import './TierPrices.override.style.scss';

export class TierPrices extends SourceTierPrices {

}

export default TierPrices;
