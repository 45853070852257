/* eslint-disable */
import Field from 'Component/Field/Field.component';
import { Form } from 'Component/Form/Form.component';
import {
    cloneElement,
    createRef
} from 'react';

const componentFieldContainer = {
    'member-function': {
        setValidationMessage: (args, callback, instance) => {
            const [prevProps] = args;
            const { message: prevMessage = {} } = prevProps;
            const { message = {} } = instance.props;
            const { validationMessage = {} } = instance.state;

            if (message !== validationMessage && !prevMessage && message) {
                instance.setState({ validationMessage: message });
            }
        },
        componentDidUpdate: (args, callback, instance) => {
            const [prevProps] = args;
            const result = callback.apply(instance, args);
            instance.setValidationMessage(prevProps);
            return result;
        },
        updatedContainerProps: (args, callback, instance) => {
            const {
                validationMessage
            } = instance.state;

            return {
                ...instance.containerProps(),
                message: validationMessage
            }
        },
        render: (args, callback, instance) => {
            return (
                <Field
                    { ...instance.props }
                    { ...instance.updatedContainerProps() }
                    { ...instance.containerFunctions }
                />
            );
        }
    }
}

const componentFormComponent = {
    'static-member': {
        updateChildrenRefs: (args, callback) => {
            const [props, state = {}] = args;

            const { children: propsChildren } = props;
            const { refMap: refMapState = {} } = state;

            const refMap = {};

            const children = Form.cloneChildren(
                propsChildren,
                (child) => {
                    const { props: { name } } = child;
                    const { message } = Object.keys(refMapState).length
                        ? Form.validateField(child, refMapState)
                        : {};
                    refMap[name] = createRef();

                    const childProps = {
                        formRef: refMap[name],
                        formRefMap: refMap
                    };

                    if (message) {
                        childProps.message = message;
                    }

                    return cloneElement(child, childProps);
                }
            );

            return { children, refMap };
        },
        getDerivedStateFromProps: (args, callback) => {
            const [props, state] = args;
            const { refMap } = state;
            const { children } = props;

            return {
                ...Form.cloneAndValidateChildren(children, refMap),
                ...Form.updateChildrenRefs(props, state)
            };
        }
    }
}

export default {
    'Component/Field/Container': {
        ...componentFieldContainer
    },
    'Component/Form/Component': {
        ...componentFormComponent
    }
}