/* eslint-disable */
import { CheckoutQuery as SourceCheckoutQuery } from 'SourceQuery/Checkout.query';

import { isSignedIn } from 'Util/Auth';
import { Field } from 'Util/Query';

export class CheckoutQuery extends SourceCheckoutQuery {
    getPlaceOrderMutation(guestCartId, comment, deliveryComment) {
        const mutation = super.getPlaceOrderMutation(guestCartId);
        mutation
            .addArgument('orderComment', 'String', comment)
            .addArgument('deliveryComment','String', deliveryComment);
        return mutation;
    }
}

export default new CheckoutQuery();