/* eslint-disable */
import { Field } from 'Util/Query';

const _getBundleItemsFields = (args, callback, instance) => [...callback(...args), 'hoeks_bundle_section', 'hoeks_bundle_option_description', 'hoeks_bundle_option_necessary'];

const _getProductInterfaceFields = (args, callback, instance) => [...callback(...args), 'sections', 'lvsh_info_cms_identifier', 'lvsh_info_cms_identifier_is_large', 'lvsh_is_recommended', _getImageField()];

const _getImageField = () => {
    return new Field('image')
        .addFieldList(_getImageFields());
}

const _getImageFields = () => [
    'path',
    'url'
] 

export default {
    'Query/ProductList': {
        'member-function': {
            _getBundleItemsFields,
            _getProductInterfaceFields
        }
    }
};