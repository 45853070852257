/* eslint-disable */
import ContentWrapper from 'Component/ContentWrapper';

const render = (args, callback, instance) => {
    return (
        <ContentWrapper
          mix={ { block: 'ProductTabs', elem: 'Section' } }
          wrapperMix={ { block: 'ProductTabs', elem: 'Wrapper' } }
          label={ __('Product tabs') }
        >
            { instance.renderTabs() }
        </ContentWrapper>
    );
}

export default {
    'Component/ProductTabs/Component': {
        'member-function': {
            render
        }
    }
}