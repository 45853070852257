/* eslint-disable */
import { SearchField as SourceSearchField } from 'SourceComponent/SearchField/SearchField.component';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';
export * from 'SourceComponent/SearchField/SearchField.component';

// import new extended styles file
import './SearchField.override.style.scss';

/** @namespace Component/SearchField/Component */
export class SearchField extends SourceSearchField {
    onSearchEnterPress = (e) => {
        const { searchCriteria, hideActiveOverlay, onSearchBarChange, code } = this.props;
        const search = searchCriteria.trim().replace(/\s\s+/g, '%20');
        const trimmedSearch = searchCriteria.trim();

        if (e.key === 'Enter' && trimmedSearch !== '') {
            history.push(`/${code}/search/${ search }`);
            hideActiveOverlay();
            // onSearchBarChange({ target: { value: '' } });
            this.searchBarRef.current.blur();
            this.closeSearch();
        }
    };
}

export default SearchField;