/* eslint-disable */
import Html from 'SourceComponent/Html';
import Link from 'SourceComponent/Link';

export class BlogPostCardWidget extends PureComponent {
    renderTitle() {
        const {
            title
        } = this.props;

        return (
            <div
                block="BlogPostCardWidget"
                elem="Title"
            >{ title }</div>
        )
    }

    renderFeaturedImage() {
        const {
            featuredimage,
            featuredimagealt
        } = this.props;

        if (!featuredimage) {
            return null;
        }

        return (
            <div
                block="BlogPostCardWidget"
                elem="FeaturedImageContainer"
            >
                <img
                    src={ featuredimage }
                    alt={ featuredimagealt }
                />
            </div>
        )
    }

    renderPublishDate() {
        const {
            ispublishdateenabled,
            publishdate
        } = this.props;

        if (!ispublishdateenabled) {
            return null;
        }

        return (
            <div
                block="BlogPostCardWidget"
                elem="PuslishDate"
            >
                <span
                    block="BlogPostCardWidget"
                    elem="PuslishDate"
                    mods={ { isLabel: true } }
                >{ __('Posted:') }</span>
                <span
                    block="BlogPostCardWidget"
                    elem="PuslishDate"
                    mods={ { isValue: true } }
                >{ publishdate }</span>
            </div>
        )
    }

    renderCategories() {
        const {
            categories = []
        } = this.props;

        if (categories.length <= 0) {
            return null;
        }

        return (
            <div
                block="BlogPostCardWidget"
                elem="Categories"
            >
                <span
                    block="BlogPostCardWidget"
                    elem="Categories"
                    mods={ { isLabel: true } }
                >{ __('Categories:') }</span>
                { categories.map(this.renderCategory) }
            </div>
        )
    }

    renderCategory(category) {
        const {
            title,
            url
        } = category;

        return (
            <Link
                block="BlogPostCardWidget"
                elem="CategoryLink"
                to={ url }
                key={ title }
            >{ title }</Link>
        )
    }

    renderShortContent() {
        const {
            shortcontent = ''
        } = this.props;

        if (!shortcontent) {
            return null;
        }

        return (
            <div
                block="BlogPostCardWidget"
                elem="ShortContent"
            >
                <Html content={ shortcontent } />
            </div>
        )
    }



    renderContainer() {
        return (
            <div
                block="BlogPostCardWidget"
                elem="Container"
            >
                { this.renderTitle() }
                { this.renderFeaturedImage() }
                { this.renderShortContent() }
                { this.renderPublishDate() }
                { this.renderCategories() }
            </div>
        )
    }

    render() {
        const {
            posturl
        } = this.props;

        return (
            <Link
                block="BlogPostCardWidget"
                elem="LinkWrapper"
                to={ posturl }
            >
                { this.renderContainer() }
            </Link>
        )
    }
}

export default BlogPostCardWidget;