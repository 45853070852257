/* eslint-disable */
import { FieldSelectContainer as SourceFieldSelectContainer } from 'SourceComponent/FieldSelect/FieldSelect.container';

/** @namespace Component/FieldSelect/Container */
export class FieldSelectStoreFinderContainer extends SourceFieldSelectContainer {
    sortSelectOptions() {
        const { selectOptions } = this.props;

        const [firstOption, ...restOfOptions] = selectOptions;

        /**
         * Trim all null label values, sort alphabetically
         */
        const sortedOptions = [firstOption, ...restOfOptions.reduce(
            (acc, a) => (a.label ? [...acc, a] : acc), []
        ).sort((a, b) => {
            const textA = a.label.toUpperCase();
            const textB = b.label.toUpperCase();
            // eslint-disable-next-line no-nested-ternary
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        })];

        return sortedOptions;
    }
}

export default FieldSelectStoreFinderContainer;