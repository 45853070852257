import { PureComponent } from 'react';

export class writeFrameNumberComponent extends PureComponent {
    getCookie(name) {
        const nameEQ = name + '=';
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    render() {
        const frameNumber = this.getCookie('framenumber');
        return (
            <div block="writeFrameNumber">
                {frameNumber ? <h4 className='ProductRecall-TitleFrame'>{__('Frame Number: ')} {frameNumber}</h4> : <></>}
            </div>
        )
    }
}

export default writeFrameNumberComponent;