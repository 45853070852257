/* eslint-disable */
import { PureComponent } from 'react';

import './EstimatedDate.style';

import {
    STATUS_LOADING,
    STATUS_UNKNOWN,
    STATUS_IN_STOCK,
    STATUS_DATE
} from './EstimatedDate.config';

/** @namespace Component/EstimatedDate/Component */
export class EstimatedDate extends PureComponent {
    renderRequiredDate() {
        const { requiredDate: { status, text } } = this.props;
        const statusText = status === STATUS_IN_STOCK || status === STATUS_UNKNOWN || status === STATUS_LOADING ? __('Bike stock status: ') : __('Bike expected back in stock: ')

        return (
            <div
                block="EstimatedDate"
                elem="RequiredDate"
            >
                <span
                    block="EstimatedDate"
                    elem="RequiredDateLabel"
                >
                    { statusText }
                </span>
                <span
                    block="EstimatedDate"
                    elem="RequiredDateValue"
                >
                { text }
                </span>
            </div>
        )
    }

    renderOptionalDate() {
        const { optionalDate: { status, text } } = this.props;
        const statusText = status === STATUS_IN_STOCK || status === STATUS_UNKNOWN || status === STATUS_LOADING ? __('Accessories stock status: ') : __('Accessories expected back in stock: ')

        return (
            <div
                block="EstimatedDate"
                elem="OptionalDate"
            >
                <span
                    block="EstimatedDate"
                    elem="OptionalDateLabel"
                >
                    { statusText }
                </span>
                <span
                    block="EstimatedDate"
                    elem="OptionalDateValue"
                >
                    { text }
                </span>
            </div>
        )
    }

    renderDate() {
        const {
            optionalDate: {
                status: optionalStatus,
                text: optionalText
            },
            requiredDate: {
                status: requiredStatus,
                text: requiredText
            }
        } = this.props;

        if (optionalStatus === requiredStatus && optionalStatus !== STATUS_DATE) {
            return this.renderRequiredDate();
        }

        if (optionalStatus === STATUS_DATE && requiredStatus === STATUS_DATE && optionalText === requiredText) {
            return this.renderRequiredDate();
        }

        return (
            <>
                { this.renderRequiredDate() }
                { this.renderOptionalDate() }
            </>
        )
    }

    render() {
        return (
            <>
                { this.renderDate() }
            </>
        )
    }
}

export default EstimatedDate;