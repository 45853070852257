/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
/* eslint-disable */

import { connect } from 'react-redux';

import BlogCategories from './BlogCategories.component';

/** @namespace ScandiPWA/Blog/Component/BlogCategories/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
  categories: state.CategoriesReducer.categories.items,
  isLoaded: state.CategoriesReducer.isLoaded,
});

/** @namespace ScandiPWA/Blog/Component/BlogCategories/Container/mapDispatchToProps */
// eslint-disable-next-line no-unused-vars
export const mapDispatchToProps = (dispatch) => ({});

/** @namespace ScandiPWA/Blog/Component/BlogCategories/Container */
export class BlogCategoriesContainer extends PureComponent {
  containerProps() {
    const { path, categoriesUsed } = this.props;

    return {
      path,
      categoriesUsed
    };
  }

  render() {
    return (
      <BlogCategories
        { ...this.props }
        { ...this.containerProps() }
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlogCategories);
