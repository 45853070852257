/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import Link from 'SourceComponent/Link';
import TextPlaceholder from 'SourceComponent/TextPlaceholder';

import { MAX_CATEGORIES } from './BlogCategories.config';

import './BlogCategories.style';

/** @namespace ScandiPWA/Blog/Component/BlogCategories/Component */
export class BlogCategories extends PureComponent {
    static propTypes = {
        categories: PropTypes.array,
        isLoaded: PropTypes.bool
    };

    static defaultProps = {
        categories: {},
        isLoaded: false
    };

    renderCategory = this.renderCategory.bind(this);

    renderCategory(category, key) {
        const { path, categoriesUsed = [] } = this.props;
        const { title, identifier } = category;

        if (!categoriesUsed.includes(identifier)) {
            return null;
        }

        return (
            <div block="BlogCategories" elem="Category" key={ key }>
                <Link to={ `${path}?category=${identifier}` } title={ __('View %s', title) }>
                    <h3 block="BlogCategories" elem="CategoryTitle">
                        <TextPlaceholder
                          mix={ { block: 'BlogCategories', elem: 'CategoryTitlePlaceholder' } }
                          content={ title }
                          length="long"
                        />
                    </h3>
                </Link>
            </div>
        );
    }

    renderContent() {
        const { categories, isLoaded } = this.props;
        const category = {};

        if (!isLoaded) {
            return Array(MAX_CATEGORIES).fill().map((_, i) => this.renderCategory(category, i));
        }

        if (isLoaded && categories.length === 0) {
            return __('No categories');
        }

        return categories.map(this.renderCategory);
    }

    render() {
        return (
            <div block="BlogCategories" elem="Wrapper">
                <h3 block="BlogCategories" elem="Title">
                    Categories
                </h3>
                { this.renderContent() }
            </div>
        );
    }
}

export default BlogCategories;
