/* eslint-disable */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 */

import { createReducer, getStore } from 'Store/index';

export class AppComponentPlugin {
    /**
     * Updates the reducer list
     * The store is created before plugins are called therefore this function is required to get the reducers to
     * connect to the redux store.
     * @param args
     * @param callback
     * @returns {*}
     */
    updateReducerList = (args, callback) => {
        getStore().replaceReducer(createReducer());
        return callback(...args);
    };
}

const { updateReducerList } = new AppComponentPlugin();

export default {
    'Component/App/Component': {
        'member-function': {
            renderRedux: updateReducerList
        }
    }
};
