import { PureComponent } from 'react';
import CmsBlock from 'Component/CmsBlock'
export class ProductRecallEmailFormSuccessComponent extends PureComponent {
    render() {
        return (
            <div block="ProductRecallEmailFormSuccess">
                <CmsBlock identifier="product-recall-success" />
            </div>
        );
    }
}

export default ProductRecallEmailFormSuccessComponent;