/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import ContentWrapper from 'SourceComponent/ContentWrapper';
import Html from 'SourceComponent/Html';
import Image from 'SourceComponent/Image';
import TextPlaceholder from 'SourceComponent/TextPlaceholder';
import { HistoryType } from 'SourceType/Common';
import media from 'SourceUtil/Media';

import BlogCategories from '../../component/BlogCategories';
import BlogRecentPosts from '../../component/BlogRecentPosts';
import BlogRelatedPosts from '../../component/BlogRelatedPosts';
import BlogRelatedProducts from '../../component/BlogRelatedProducts';
import BlogSearchBar from '../../component/BlogSearchBar';
import { convertDateFormat } from '../../util/Date';
import { POSTS_DETAILS } from './PostsDetails.config';

import './PostsDetails.style';


/**
 * @class PostsDetails
 * @namespace ScandiPWA/Blog/Route/PostsDetails/Component
 */
export class PostsDetails extends PureComponent {
    static propTypes = {
        requestPosts: PropTypes.func.isRequired,
        updateBreadcrumbs: PropTypes.func.isRequired,
        updateMeta: PropTypes.func.isRequired,
        history: HistoryType.isRequired,
        setHeaderState: PropTypes.func.isRequired,
        post: PropTypes.object.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                handle: PropTypes.string.isRequired
            }).isRequired
        }).isRequired
    };

    __construct(props) {
        super.__construct(props);

        this.options = {
            postOptions: {
                getDescription: true,
                getMedia: true,
                getRelated: true
            }
        };
    }

    componentDidMount() {
        this.requestPosts();
        this.getPost();
        this.setHeaderState();
        this.storePathname();
    }

    componentDidUpdate() {
        this.updateBreadcrumbs();
        this.updatePage();
        this.updateMeta();
    }

    setHeaderState() {
        const { setHeaderState, history, name } = this.props;
        setHeaderState({
            name: POSTS_DETAILS,
            title: name,
            onBackClick: () => history.goBack()
        });
    }

    updateMeta() {
        const {
            post: {
                title, meta_title, meta_description, meta_keyword
            },
            updateMeta
        } = this.props;

        updateMeta({
            title,
            meta_title,
            meta_description,
            meta_keyword,
            canonical_url: this.getCanonicalUrl() || ''
        });
    }

    /**
     * Get url handle from router
     * @return {void | jsx}
     */
    getUrlParam() {
        const { match: { params: { handle } } } = this.props;
        return handle;
    }

    getPost() {
        const { post } = this.props;
        return post;
    }

    /**
     * Dispatch breadcrumbs update
     * @return {void}
     */
    updateBreadcrumbs() {
        const { updateBreadcrumbs, post, nameTranslated, path } = this.props;
        const { title } = post;

        if (!title) {
            return;
        }

        const breadcrumbs = [
            {
                name: title
            },
            {
                url: `/${path}`,
                name: nameTranslated
            },
            {
                url: '/',
                name: __('Home')
            }
        ];

        updateBreadcrumbs(breadcrumbs);
    }

    /**
     * Stores the old handle in state so that
     * it can compare it when a handle is changed
     */
    storePathname() {
        this.setState({ oldHandle: this.getUrlParam() });
    }

    /**
     * Gets the canonical url for the specific post
     */
    getCanonicalUrl() {
        const { post: { identifier }, path } = this.props;

        if (!identifier) {
            return null;
        }

        return `${window.location.origin}/${path}/${identifier}`;
    }

    /**
     * Updates the page if handle is changed
     */
    updatePage() {
        const { oldHandle } = this.state;

        if (oldHandle !== this.getUrlParam()) {
            this.requestPosts();
            this.getPost();
            this.setHeaderState();
            this.storePathname();
        }
    }

    requestPosts() {
        const { requestPosts } = this.props;
        const { postOptions } = this.options;

        requestPosts({
            ...postOptions,
            id: this.getUrlParam()
        });
    }

    renderTitle() {
        const { post: { title } } = this.props;

        return (
            <h3 mix={ { block: 'PostsDetails', elem: 'Title' } }>
                <TextPlaceholder length="medium" content={ title } />
            </h3>
        );
    }

    /**
     * Renders the featured image
     * @returns {*}
     */
    renderImage() {
        const { post: { media_gallery, post_id } } = this.props;

        const imageUrl = media(media_gallery);

        if (!media_gallery && post_id) {
            return null;
        }

        return (
            <Image
              block="PostsListing"
              elem="FeaturedImage"
              src={ imageUrl }
              ratio="16x9"
              isPlaceholder={ !media_gallery }
            />
        );
    }

    renderPublishDate() {
        const { post: { publish_time } } = this.props;

        if (!publish_time) {
            return null;
        }

        return (
            <div mix={ { block: 'PostsDetails', elem: 'Date' } }>
                <TextPlaceholder
                  mix={ { block: 'BlogRecentPosts', elem: 'DatePlaceholder' } }
                  content={ convertDateFormat(publish_time) }
                />
            </div>
        );
    }

    renderAuthorName() {
        const { post: { author } } = this.props;

        if (!author) {
            return null;
        }

        return (
            <div mix={ { block: 'PostsDetails', elem: 'Author' } }>
                { author.name }
            </div>
        );
    }

    renderContent() {
        const { post: { content } } = this.props;

        if (!content) {
            return (
                <TextPlaceholder
                  mix={ { block: 'PostsDetails', elem: 'ContentPlaceholder' } }
                  length="custom"
                />
            );
        }

        return (
            <div mix={ { block: 'PostsDetails', elem: 'Content' } }>
                <Html content={ content } />
            </div>

        );
    }

    render() {
        return (
            <main block="PostsDetails">
                <ContentWrapper
                  wrapperMix={ { block: 'PostsDetails', elem: 'Wrapper' } }
                  label="Post"
                >
                    <div block="PostsListing" elem="ColumnWrapper">
                        <div block="PostsDetails" elem="Sidebar">
                            <BlogSearchBar />
                            <BlogRecentPosts />
                            <BlogCategories />
                        </div>
                        <div block="PostsDetails" elem="Description">
                            { this.renderTitle() }
                            { this.renderImage() }
                            { this.renderPublishDate() }
                            { this.renderAuthorName() }
                            { this.renderContent() }
                            <BlogRelatedProducts />
                            <BlogRelatedPosts />
                        </div>
                    </div>
                </ContentWrapper>
            </main>
        );
    }
}

export default PostsDetails;
