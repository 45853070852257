import { PureComponent } from 'react';
import './ProductRecallEmailForm.style';
import Field from 'Component/Field';
import Form from 'Component/Form';
import FormPortal from 'Component/FormPortal';
import { TEXT_TYPE, CHECKBOX_TYPE, SELECT_TYPE } from 'Component/Field/Field.config';
import ProductRecallEmailFormSuccess from '../ProductRecallEmailFormSuccess';

export class ProductRecallEmailFormComponent extends PureComponent {
    renderHoneyPotTrap() {
        return (
            <>
                <label className='ohnohoney'>Email</label>
                <Field className='ohnohoney' autoComplete="off" type="text" id="email" name="email" placeholder='your email here'/>
            </>
        )
    }

    renderFields() {
        return (
            <div block="ProductRecallEmailForm" elem="Fields">
                <Field
                    id="emailwarding"
                    name="emailwarding"
                    type={TEXT_TYPE}
                    label={__('Email')}
                    validation={['notEmpty', 'email']}
                    placeholder={__('Email Address')}
                />
                {this.renderHoneyPotTrap()}
            </div>
        );
    }

    renderActions() {
        const { onFormSucess, onFormError } = this.props;
        return (
            <Form
                id={'RECALL_NOTIFY'}
                onSubmitSuccess={(fields) => onFormSucess(fields)}
                onSubmitError={(fields) => onFormError(fields)}
            >
                <button
                    type="submit"
                    block="Button"
                    mix={{ block: "ProductRecallEmailForm", elem: "Button" }}
                >
                    {__('Keep up to date')}
                </button>
            </Form>
        );
    }

    render() {
        const { formSuccess } = this.props;
        return (
            <div block="ProductRecallEmailForm">
               {formSuccess != true ? 
               <>
               <FormPortal
                    id={'RECALL_NOTIFY'}
                    name="recall_notify"
                >
                    {this.renderFields()}
                </FormPortal>
                {this.renderActions()}
                </> 
                : <ProductRecallEmailFormSuccess />}
            </div>
        );
    }
}

export default ProductRecallEmailFormComponent;