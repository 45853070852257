/* eslint-disable */
import { PureComponent } from 'react';
import { FieldContainer as SourceFieldContainer } from 'SourceComponent/Field/Field.container';
import { CHECKBOX_TYPE, RADIO_TYPE } from 'Component/Field/Field.config';

/** @namespace Component/Field/Container */
export class FieldContainer extends SourceFieldContainer {
    componentDidUpdate(prevProps) {
        const { value: prevValue, checked: prevChecked } = prevProps;
        const { value: currentValue, checked: currChecked, type } = this.props;

        if (prevValue !== currentValue) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ value: currentValue });
        }
        if ((type === CHECKBOX_TYPE || type === RADIO_TYPE) && currChecked !== prevChecked) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ checked: currChecked });
        }
    }

    containerProps = () => {
        const {
            checked: propsChecked,
            message
        } = this.props;

        const {
            type,
            checked,
            value,
            validationStatus,
            validationMessage
        } = this.state;

        return {
            checked: (type === CHECKBOX_TYPE || type === RADIO_TYPE) ? propsChecked : checked,
            value,
            validationStatus,
            message: validationMessage || message
        };
    };

    // onClick(event, selectValue = false) {
    //     const { onClick } = this.props;
    //     const { target: { checked, value } } = event;
    //     console.log(event.target);

    //     if (selectValue) {
    //         event.target.select();
    //     }
    //     if (onClick) {
    //         onClick(event);
    //     }

    //     console.log(checked);
    //     // this.setState({ checked: !this.state.checked });
    // }

    onChange(event) {
        // console.log('CHANGE');
        // console.log(event)
        return super.onChange(event);
    }
}

export default FieldContainer;
