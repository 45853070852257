/* eslint-disable import/no-cycle */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Event, { EVENT_GTM_CHECKOUT } from '../../../util/Event';
import ProductHelper from '../utils/Product4';
import BaseEvent from './BaseEvent.event';

export const CHECKOUT_EVENT_DELAY = 500;
export const SPAM_PROTECTION_DELAY = 1000;

/**
 * On checkout
 */
class BeginCheckout4Event extends BaseEvent {
    /**
     * Event fire delay
     *
     * @type {number}
     */
    eventHandleDelay = CHECKOUT_EVENT_DELAY;

    /**
     * Bind
     */
    bindEvent() {
        Event.observer(EVENT_GTM_CHECKOUT, ({ totals, step }) => {
            this.handle(totals, step);
        });
    }

    /**
     * Handle
     */
    handler(totals, step) {
        if (this.spamProtection(SPAM_PROTECTION_DELAY)) {
            return;
        }

        const { items: cartItems = [] } = totals;

        const {
            quote_currency_code,
            grand_total,
        } = totals;

        const items = cartItems.map(({ qty, ...cartItem }) => ({
            ...ProductHelper.getItemData(cartItem),
            quantity: qty
        }));

        this.clearEcommerce();
        this.pushEventData({
            ecommerce: {
                currency: quote_currency_code,
                value: grand_total,
                items,
                content_ids: ProductHelper.getItemSkus(items),
            }
        });
    }
}

export default BeginCheckout4Event;
