/* eslint-disable */

const getResetPasswordMutation = (args, callback, instance) => {
    const resetPasswordMutation = callback.apply(instance, args);
    resetPasswordMutation.setAlias('resetPassword');
    resetPasswordMutation.name = 's_resetPassword';
    return resetPasswordMutation;
}

export default {
    'Query/MyAccount': {
        'member-function': {
            getResetPasswordMutation
        }
    }
}