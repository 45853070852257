/* eslint-disable */
import VideoThumbnail from 'Component/VideoThumbnail';
import Image from 'Component/Image';

const renderVideo = (args, callback, instance) => {
    const [media, index] = args;

    const {
        handleImageZoomPopupActiveChange
    } = instance.props;

    return (
        <VideoThumbnail
            key={ index }
            media={ media }
            handleImageZoomPopupActiveChange={ handleImageZoomPopupActiveChange }
        />
    )
}

const _onPlayClick = (args, callback, instance) => {
    const { handleImageZoomPopupActiveChange } = instance.props;
    handleImageZoomPopupActiveChange(false);
    return callback.apply(instance, args);
}

const render = (args, callback, instance) => {
    const {
        media: {
            base: { url },
            video_content: { video_title }
        },
        onPlayClick
    } = instance.props;

    return (
        <div block="VideoThumbnail">
            <button
              block="VideoThumbnail"
              elem="Button"
              onClick={ onPlayClick }
              title={ __('Play video %s', video_title) }
            >
                <Image
                  src={ url }
                  ratio="custom"
                  mix={ {
                      block: 'VideoThumbnail',
                      elem: 'Thumbnail',
                      mods: { isPlaceholder: !url }
                  } }
                  isPlaceholder={ !url }
                  alt={ video_title }
                />
                { instance.renderPlayIcon() }
            </button>
        </div>
    );
}

export default { 
    'Component/ProductGallery/Component': {
        'member-function': {
            renderVideo
        }
    },
    'Component/VideoThumbnail/Container/videoThumbnailContainer': {
        'member-function': {
            _onPlayClick
        }
    },
    'Component/VideoThumbnail/Component/videoThumbnail': {
        'member-function': {
            render
        }
    }
}