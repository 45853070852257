/* eslint-disable */

// imports the original component from vendor folder
import HoeksBlogRecentPosts from 'scandipwa-blog/src/component/BlogRecentPosts/BlogRecentPosts.component';

// import dependencies
import Link from 'Component/Link';
import TextPlaceholder from 'Component/TextPlaceholder';
import Fade from 'react-reveal/Fade';
import Image from 'Component/Image';
import Html from 'Component/Html';
import { convertDateFormat } from 'scandipwa-blog/src/util/Date';
import { getPostUrl } from 'scandipwa-blog/src/util/Posts';

// import new extended styles file
import './BlogRecentPosts.override.style.scss';

export class BlogRecentPosts extends HoeksBlogRecentPosts {
    renderCard = this.renderCard.bind(this);

    renderCard(post, key) {
        const { path } = this.props;
        const { title, first_image, meta_description } = post || {};
        const postUrl = getPostUrl(post, path);

        return (
            <Fade bottom small duration={500}>
                <div
                block="BlogRecentPosts"
                elem="PostCard"
                key={ key }
                >
                    <Link
                    to={ postUrl }
                    title={ __('View %s', title) }
                    mix={ { block: 'BlogRecentPosts', elem: 'ImageWrapper' } }
                    >
                        <Image
                        src={ first_image }
                        ratio="16x9"
                        isPlaceholder={ !first_image }
                        />
                    </Link>
                    <div
                    block="BlogRecentPosts"
                    elem="Wrapper"
                    >
                        <Link to={ postUrl } title={ __('View %s', title) }>
                            <h3 block="BlogRecentPosts" elem="PostTitle">
                                { title }
                            </h3>
                        </Link>
                        <Link to={ postUrl } title={ __('View %s', title) }>
                            <p block="BlogRecentPosts" elem="PostDescription">
                                <Html content={ meta_description || '' } />
                            </p>
                        </Link>
                        <div className="plus-button">
                </div>
                <div className="plus-sign">+</div>
                    </div>
                </div>
            </Fade>
        );
    }

    render() {
        return (
            <div block="BlogRecentPosts" elem="Wrapper">
                { this.renderContents() }
            </div>
        );
    }
}

export default BlogRecentPosts;
