/* eslint-disable */
import {
    FieldSelectContainer as SourceFieldSelectContainer
} from 'SourceComponent/FieldSelect/FieldSelect.container';

import './FieldSelect.override.style';

/** @namespace HoeksPwa/Component/FieldSelect/Container/FieldSelectContainer */
export class FieldSelectContainer extends SourceFieldSelectContainer {
    // TODO implement logic
    sortSelectOptions() {
        const { selectOptions, fromBikeForm = false } = this.props;

        /**
         * Trim all null label values, sort alphabetically
         */

        if (fromBikeForm) return selectOptions;

        const sortedOptions = selectOptions.reduce(
            (acc, a) => (a.label ? [...acc, a] : acc), []
        ).sort((a, b) => {
            const textA = a.label.toUpperCase();
            const textB = b.label.toUpperCase();
            // eslint-disable-next-line no-nested-ternary
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });

        return sortedOptions;
    }
}

export default FieldSelectContainer;
