import { Field } from 'Util/Query';

export class DealerDocumentationQuery {
    addDealerDocumentation(data) {
        return new Field('addToDealerDocumentation')
        .addArgument('dealer_name', 'String!', data.dealer_name)
        .addArgument('contact_person', 'String!', data.contact_person)
        .addArgument('amount_repaired_or_replaced', 'Int!', data.amount_repaired_or_replaced)
        .addArgument('frame_numbers', 'String!', data.frame_numbers)
        .addArgument('submittal_date', 'String!', data.submittal_date)
        .addField('status')
        .addField('message')
    }
}

export default new DealerDocumentationQuery();