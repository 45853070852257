/* eslint-disable */

const defaultProps = (member, instance) => {
    return {
        ...member,
        isBreadcrumbsActive: false
    }
}

const componentDidMount = (args, callback, instance) => {
    const val = callback.apply(instance, args);

    instance.updateBreadcrumbs();

    return val;
}

export const BreadcrumbsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Breadcrumbs/Breadcrumbs.dispatcher'
);

import { toggleBreadcrumbs } from 'Store/Breadcrumbs/Breadcrumbs.action';

const mapDispatchToProps = (args, callback, instance) => {
    const [dispatch] = args;
    const val = callback.apply(instance, args);

    return {
        ...val,
        toggleBreadcrumbs: (isActive) => {
            dispatch(toggleBreadcrumbs(isActive));
        }
    }
}

export default {
    'Route/CmsPage/Container': {
        'static-member': {
            defaultProps
        },
        'member-function': {
            componentDidMount
        }
    },
    'Route/CmsPage/Container/mapDispatchToProps': {
        'function': mapDispatchToProps
    }
};