// import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './DealerDocumentationPlaceholder.style';

/** @namespace Hoeks/DealerDocumentation/Component/DealerDocumentationPlaceholder/Component/DealerDocumentationPlaceholderComponent */
export class DealerDocumentationPlaceholderComponent extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    render() {
        return (
            <div block="DealerDocumentationPlaceholder">
                { /* TODO: Implement render method */ }
            </div>
        );
    }
}

export default DealerDocumentationPlaceholderComponent;
