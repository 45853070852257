/* eslint-disable */
import EstimatedDateReusable from 'Component/EstimatedDateReusable';

const renderStock = (args, callback, instance) => {
    const {
        product: {
            type_id,
            sku
        } = {},
        productOrVariant: {
            sku: variantSku,
            type_id: variantTypeId
        },
        quantity
    } = instance.props;

    if (sku && type_id && type_id === 'simple') {
        return <EstimatedDateReusable sku={ sku } qty={ quantity } />
    }

    if (sku && variantTypeId && variantTypeId === 'configurable') {
        return '-';
    }

    if (sku && type_id && type_id === 'configurable') {
        return <EstimatedDateReusable sku={ variantSku } qty={ quantity } />;
    }

    return callback.apply(instance, args);
}

export default {
    'Component/ProductActions/Component': {
        'member-function': {
            renderStock
        }
    }
}