// TODO update this import when renamed
import { UPDATE_STATUS } from './BikeForm.action';

/** @namespace Hoeks/UpcomingBike-Form/Store/BikeForm/Reducer/getInitialState */
export const getInitialState = () => ({
    // TODO set initial state
    status: null
});

/** @namespace Hoeks/UpcomingBike-Form/Store/BikeForm/Reducer/BikeFormReducer */
export const BikeFormReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case UPDATE_STATUS:
        const { status } = action;

        return {
            ...state,
            status: status
            // TODO implement payload handling
        };

    default:
        return state;
    }
};

export default BikeFormReducer;
