/* eslint-disable */
import DealerDocumentation from "../component/DealerDocumentation";
import DealerDocumentationPlaceholder from "../component/DealerDocumentationPlaceholder";
export const DealerDocumentation_TYPE = 'HoeksDealerDocumentation';

const addProductRecallEmailFormWidget = (member, instance) => {
    // console.log('Debug Hello');
    return {
        ...member,
        [DealerDocumentation_TYPE]: {
            component: DealerDocumentation,
            fallback: () => <DealerDocumentationPlaceholder />
        }
    }
}

export default {
    'Component/WidgetFactory/Component': {
        'member-property': {
            'renderMap': {
                implementation: addProductRecallEmailFormWidget,
                position: 0
            }
        }
    },
}