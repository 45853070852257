/* eslint-disable */

import { ConfigQuery as SourceConfigQuery } from 'SourceQuery/Config.query';

/** @namespace Query/Config */
export class ConfigQuery extends SourceConfigQuery {
    _getStoreListFields() {
        return [
            ...super._getStoreListFields(),
            'id'
        ];
    }

    _getStoreConfigFields() {
        return [
            ...super._getStoreConfigFields(),
            'stock_warehouses_frontend_show_eta_within',
            'stock_warehouses_frontend_show_eta_within_text',
            'payment_name'
        ];
    }
}

export default new ConfigQuery();