/* eslint-disable */

const getStreetFields = (args, callback, instance) => {
    const street = callback.apply(instance, args);
    street.validation.push('addressLength');
    return street;
}

export default {
    'Component/MyAccountAddressForm/Component': {
        'member-function': {
            getStreetFields
        }
    }
}