import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Html from 'Component/Html';
import Image from 'Component/Image';
import Link from 'Component/Link';

const LinkWrapper = (props) => {
    const {
        link,
        children
    } = props;

    if (link) {
        return (
            <Link to={ link }>
                { children }
            </Link>
        )
    } else {
        return (
            <>
                { children }
            </>
        )
    }
}

const renderSlide = (args, callback, instance) => {
    const [slide, i] = args;

    const {
        slide_text,
        isPlaceholder,
        title: block,
        slide_link: slideLink
    } = slide;

    return (
        <figure
          block="SliderWidget"
          elem="Figure"
          key={ i }
        >
            <LinkWrapper link={ slideLink }>
                <Image
                mix={ { block: 'SliderWidget', elem: 'FigureImage' } }
                ratio="custom"
                src={ instance.getSlideImage(slide) }
                isPlaceholder={ isPlaceholder }
                />
                <figcaption
                block="SliderWidget"
                elem="Figcaption"
                mix={ { block } }
                >
                    <Html content={ slide_text || '' } />
                </figcaption>
            </LinkWrapper>
        </figure>
    );
};

const render = (args, callback, instance) => {
    const {
        slider: {
            slides,
            slide_speed: slideSpeed,
            animation_speed: animationSpeed,
            slides_to_display: slidesToDisplay,
            slides_to_display_mobile: slidesToDisplayMobile,
            slides_to_display_tablet: slidesToDisplayTablet,
            slides_to_scroll: slidesToScroll,
            slides_to_scroll_mobile: slidesToScrollMobile,
            slides_to_scroll_tablet: slidesToScrollTablet,
            show_navigation: showNavigation,
            show_menu: autoplay
        }
    } = instance.props;

    const {
        innerWidth: width
    } = window;
    const deviceSelector = (mobileValue, tabletValue, desktopValue) => width <= 767 ? mobileValue : (width >= 768 && width <= 1024 ? tabletValue : desktopValue);
    const finalSlidesToDisplay = deviceSelector(slidesToDisplayMobile, slidesToDisplayTablet, slidesToDisplay);
    const finalSlidesToScroll = deviceSelector(slidesToScrollMobile, slidesToScrollTablet, slidesToScroll);

    const settings = {
        dots: true,
        infinite: true,
        autoplaySpeed: slideSpeed,
        speed: animationSpeed,
        slidesToShow: finalSlidesToDisplay,
        slidesToScroll: finalSlidesToScroll,
        autoplay: autoplay,
        arrows: showNavigation
    }

    // console.log(settings);

    return (
        <Slider { ...settings } >
            { slides.filter(({ is_active }) => is_active).map(instance.renderSlide) }
        </Slider>
    )
};

import history from 'Util/History';

const freezeScroll = (args, callback, instance) => {
    history.listen((_) => {
        instance.unfreezeScroll();
    })

    return callback.apply(instance, args);
}

export default {
    'Component/SliderWidget/Component': {
        'member-function': {
            render,
            renderSlide
        }
    },
    'Component/Overlay/Component': {
        'member-function': {
            freezeScroll
        }
    }
}