/* eslint-disable */
import ContentWrapper from 'Component/ContentWrapper';
import BundleSections from '../component/BundleSections';
import BikeBuilderFooter from '../component/BikeBuilderFooter';
import Field from 'Component/Field';
import {
    BUNDLE
} from 'Util/Product';
import { toggleBreadcrumbs } from 'Store/Breadcrumbs/Breadcrumbs.action';
import Html from 'Component/Html';
import Image from 'Component/Image';
import Loader from 'Component/Loader';

import './BikeBuilder.style.scss';

const render = (args, callback, instance) => {
    const  {
        product: {
            type_id: type,
            sections
        } = {},
        areDetailsLoaded
    } = instance.props;

    // console.log(instance.props);

    const bikeBuilderFooter = areDetailsLoaded && (type ?? '') === 'bundle' && sections ? <BikeBuilderFooter { ...instance.props } /> : <></>;
    const bundleSections = areDetailsLoaded && (type ?? '') === 'bundle' && sections ? <BundleSections { ...instance.props } /> : <></>;
    const style = areDetailsLoaded && (type ?? '') === 'bundle' && sections ? <style>{ 'html.scrollDisabled{overflow:hidden;}' }</style> : <></>;
    // console.log(type);
    // console.log(sections);

    // if (!areDetailsLoaded) {
    //     return (
    //         <main
    //             block="ProductPage"
    //             aria-label="Product page"
    //             itemScope
    //             itemType="http://schema.org/Product"
    //             mods={ { isBikeBuilder: type === BUNDLE && sections !== null && sections !== '' } }
    //         >
    //             { style }
    //             { bundleSections }
    //             <ContentWrapper
    //                 mix={ { block: 'ProductPage', elem: 'Section' } }
    //                 wrapperMix={ { block: 'ProductPage', elem: 'Wrapper' } }
    //                 label={ __('Main product details') }
    //             >
    //                 {/* <div block="ProductPageLoading" style={{width: '100%', height: '100vh'}}> */}
    //                     <Loader isLoading={true} />
    //                 {/* </div> */}
    //             </ContentWrapper>
    //             { bikeBuilderFooter }
    //             { instance.renderAdditionalSections() }
    //         </main>
    //     )
    // }

    return (
        <main
          block="ProductPage"
          aria-label="Product page"
          itemScope
          itemType="http://schema.org/Product"
          mods={ { isBikeBuilder: type === BUNDLE && sections !== null && sections !== '' } }
        >
            { style }
            { bundleSections }
            <ContentWrapper
              mix={ { block: 'ProductPage', elem: 'Section' } }
              wrapperMix={ { block: 'ProductPage', elem: 'Wrapper' } }
              label={ __('Main product details') }
            >
                { instance.renderProductPageContent() }
            </ContentWrapper>
            { bikeBuilderFooter }
            { instance.renderAdditionalSections() }
        </main>
    );
};

const renderQuantityInput = (args, callback, instance) => {
    const {
        quantity,
        minQuantity,
        setQuantity,
        product: { type_id, sections }
    } = instance.props;

    if (type_id === BUNDLE && sections) {
        return (
            <Field
              id="item_qty"
              name="item_qty"
              type="number"
              value={ quantity }
              max={ 1 }
              min={ minQuantity }
              mix={ { block: 'ProductActions', elem: 'Qty', mods: { isHidden: true } } }
              onChange={ setQuantity }
            />
        );
    }

    return callback(...args);
};

const renderAddToCart = (args, callback, instance) => {
    const {
        product: {
            type_id,
            sections
        }
    } = instance.props;

    if (type_id === BUNDLE && sections) {
        return null;
    }

    return callback(...args)
};

const renderPriceWithGlobalSchema = (args, callback, instance) => {
    const {
        product: {
            type_id,
            sections
        }
    } = instance.props;

    if (type_id === BUNDLE && sections) {
        return null;
    }

    return callback(...args)
};

const getGalleryPictures = (args, callback, instance) => {
    const {
        product: {
            sections
        } = {}
    } = instance.props;

    // console.log('getGalleryPictures before builder check');
    // console.log(sections);

    if (!sections) return callback(...args);

    // console.log('getGalleryPictures after builder check');

    return [];
};

const renderAttributeByType = (args, callback, instance) => {
    const renderWeightAttribute = () => {
        const { attribute: { attribute_value } } = instance.props;
        return instance.renderStringValue(`${parseFloat(attribute_value)} kg`);
    };

    const { attribute: { attribute_type } } = instance.props;
    switch (attribute_type) {
        case 'weight':
            return renderWeightAttribute();
        default:
            return callback(...args);
    }
}

const headerContainerRender = (args, callback, instance) => {
    const {
        isHeaderShown
    } = instance.props;

    if (!isHeaderShown) {
        return null;
    }

    return callback.apply(instance, args);
}

// Removing product tabs on builder products
const renderProductTabs = (args, callback, instance) => {
    const {
        product: {
            type_id,
            sections
        } = {}
    } = instance.props;

    if (type_id === BUNDLE && sections !== null && sections !== '') {
        return null;
    }

    return callback.apply(instance, args);
}

const hideOnBikeBuilderCategory = (args, callback, instance) => {
    const {
        category: {
            id
        } = {}
    } = instance.props;

    if (id === 126) {
        return null;
    }

    return callback.apply(instance, args);
}


const categoryPageRender = (args, callback, instance) => {
    const val = callback.apply(instance, args);

    const {
        category: {
            id
        } = {}
    } = instance.props;

    // if (!id) {
    //     return (
    //         <div 
    //             block="BikeBuilder"
    //             elem="Spinner"
    //         >
    //             <span 
    //                 block="BikeBuilder"
    //                 elem="SpinnerLabel"
    //             >
    //                 { __('Loading...') }
    //             </span>
    //         </div>
    //     );
    // }

    if (id !== 126) {
        return val
    }

    const hideProducts = !instance.displayProducts();

    return (
        <main block="CategoryPage" mods={ { isBuilder: true } }>
            <ContentWrapper
              wrapperMix={ {
                  block: 'CategoryPage',
                  elem: 'Wrapper',
                  mods: { hideProducts }
              } }
              label="Category page"
            >
                { instance.renderContent() }
            </ContentWrapper>
        </main>
    );
}

const mapDispatchToPropsCategoryPageContainer = (args, callback, instance) => {
    const [dispatch] = args;
    const val = callback.apply(instance, args);

    return {
        ...val,
        toggleBreadcrumbs: (isActive) => {
            dispatch(toggleBreadcrumbs(isActive));
        }
    }
}

const updateBreadcrumbsCategoryPageContainer = (args, callback, instance) => {
    const {
        category: {
            id
        } = {},
        toggleBreadcrumbs
    } = instance.props;
    
    if (id === 126 && typeof toggleBreadcrumbs === 'function') {
        toggleBreadcrumbs(false);
        instance.setState({ breadcrumbsWereUpdated: true });
    } else {
        return callback.apply(instance, args);
    }
};

const mapStateToPropsHeaderContainer = (args, callback, instance) => {
    const [state] = args;
    const val = callback.apply(instance, args);

    return {
        ...val,
        isHeaderShown: state.HeaderReducer.isHeaderShown
    }
}

const renderConfigurableOptionCategoryConfigurableAttributesComponent = (args, callback, instance) => {
    const [option] = args;
    const { attribute_code } = option;

    if (attribute_code === 'price') {
        return null;
    }

    return callback.apply(instance, args);
}

const containerPropsFieldInputContainer = (args, callback, instance) => {
    const val = callback.apply(instance, args);

    // return val;

    const { recommended, image, ...validProps } = val;

    return {
        ...validProps
    };
}

const mapStateToPropsProductCardContainer = (args, callback, instance) => {
    const [state] = args;
    const val = callback.apply(instance, args);

    return {
        ...val,
        category: state.CategoryReducer.category
    }
}

const renderCardContentProductCardComponent = (args, callback, instance) => {
    const val = callback.apply(instance, args);

    const {
        category: {
            id
        } = {},
        product: {
            short_description: {
                html
            } = {},
            sections
        } = {}
    } = instance.props;

    if (id !== undefined && id !== null && id === 126 && html && sections && sections !== '') {
        return (
            <>
                {instance.renderCardLinkWrapper((
                    <>
                        <div block="ProductCard" elem="FigureReview">
                            <figure block="ProductCard" elem="Figure">
                                { instance.renderPicture() }
                            </figure>
                            { instance.renderReviews() }
                        </div>
                        <div block="ProductCard" elem="Content">
                            { instance.renderAdditionalProductDetails() }
                            { instance.renderMainDetails() }
                            { instance.renderProductPrice() }
                            { instance.renderVisualConfigurableOptions() }
                        </div>
                    </>
                ))}
                <div block="ProductCard" elem="ShortDescription"><Html content={ html } /></div>
            </>
        )
    }

    return val;
}

const renderPictureProductCardComponent = (args, callback, instance) => {
    const {
        category: {
            id
        } = {},
        product: {
            sections
        } = {}
    } = instance.props;

    if (id !== undefined && id !== null && sections && id === 126 && sections !== '') {
        const [mix] = args;

        const { product: { id, name, image: { url = '' } = {} }, thumbnail } = instance.props;

        instance.sharedComponent = (
            <Image
              imageRef={ instance.imageRef }
              src={ url }
              alt={ name }
              ratio="custom"
              mix={ { block: 'ProductCard', elem: 'Picture', mix } }
              isPlaceholder={ !id }
            />
        );

        return (
            <>
                { instance.sharedComponent }
                <img
                  style={ { display: 'none' } }
                  alt={ name }
                  src={ url }
                />
            </>
        );
    }

    return callback.apply(instance, args);
}

export default {
    'Route/ProductPage/Component': {
        'member-function': {
            render,
            renderProductTabs
        }
    },
    'Component/ProductActions/Component': {
        'member-function': {
            renderQuantityInput,
            renderAddToCart,
            renderPriceWithGlobalSchema
        }
    },
    'Component/ProductGallery/Container': {
        'member-function': {
            // getGalleryPictures
        }
    },
    'Component/ProductAttributeValue/Component': {
        'member-function': {
            renderAttributeByType
        }
    },
    'Route/CategoryPage/Component': {
        'member-function': {
            renderItemsCount: hideOnBikeBuilderCategory,
            renderFilterOverlay: hideOnBikeBuilderCategory,
            renderCategorySort: hideOnBikeBuilderCategory,
            // renderCategoryDetails: hideOnBikeBuilderCategory,
            renderMiscellaneous: hideOnBikeBuilderCategory,
            // render: categoryPageRender
        }
    },
    'Route/CategoryPage/Container/mapDispatchToProps': {
        'function': mapDispatchToPropsCategoryPageContainer,
    },
    'Route/CategoryPage/Container': {
        'member-function': {
            // componentDidUpdate: componentDidUpdateCategoryPageContainer
            updateBreadcrumbs: updateBreadcrumbsCategoryPageContainer
        }
    },
    'Component/Header/Container/mapStateToProps': {
        'function': mapStateToPropsHeaderContainer
    },
    'Component/Header/Container': {
        'member-function': {
            'render': headerContainerRender
        }
    },
    'Component/CategoryConfigurableAttributes/Component': {
        'member-function': {
            'renderConfigurableOption': renderConfigurableOptionCategoryConfigurableAttributesComponent
        }
    },
    'Component/FieldInput/Container': {
        'member-function': {
            containerProps: containerPropsFieldInputContainer
        }
    },
    'Component/ProductCard/Container/mapStateToProps': {
        'function': mapStateToPropsProductCardContainer
    },
    'Component/ProductCard/Component': {
        'member-function': {
            renderCardContent: renderCardContentProductCardComponent,
            renderPicture: renderPictureProductCardComponent
        }
    }
};