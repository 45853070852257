/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
/* eslint-disable */

import { connect } from 'react-redux';

import LinkedProductsDispatcher from 'SourceStore/LinkedProducts/LinkedProducts.dispatcher';
import { updateMeta } from 'SourceStore/Meta/Meta.action';
import { changeNavigationState } from 'SourceStore/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'SourceStore/Navigation/Navigation.reducer';

import { PostsDetailsDispatcher } from '../../store/PostsDetails';
import PostsDetails from './PostsDetails.component';

export const BreadcrumbsDispatcher = import(
  /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
  'Store/Breadcrumbs/Breadcrumbs.dispatcher'
);

/** @namespace ScandiPWA/Blog/Route/PostsDetails/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
  post: state.PostsDetailsReducer.post,
});

/** @namespace ScandiPWA/Blog/Route/PostsDetails/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
  updateBreadcrumbs: (breadcrumbs) =>
    BreadcrumbsDispatcher.then(({ default: dispatcher }) =>
      dispatcher.update(breadcrumbs, dispatch)
    ),
  requestPosts: (options) => {
    PostsDetailsDispatcher.handleData(dispatch, options);
    LinkedProductsDispatcher.clearLinkedProducts(dispatch);
  },
  updateMeta: (meta) => dispatch(updateMeta(meta)),
  setHeaderState: (stateName) =>
    dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, stateName)),
});

/** @namespace ScandiPWA/Blog/Route/PostsDetails/Container */
export class PostsDetailsContainer extends PureComponent {
  getName() {
    const { name } = this.props;
    return name || 'Blog';
  }

  getNameTranslated() {
    const { nameTranslated } = this.props;
    return nameTranslated || __('Blog');
  }

  getPath() {
    const { path } = this.props;
    return path || 'blog';
  }

  containerProps() {
    return {
      name: this.getName(),
      nameTranslated: this.getNameTranslated(),
      path: this.getPath()
    }
  }

  render() {
    return (
      <PostsDetails
        { ...this.props }
        { ...this.containerProps() }
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PostsDetailsContainer);
