/* eslint-disable */
import BlogPostListWidget from '../component/BlogPostListWidget';
import BlogPostCardWidget from  '../component/BlogPostCardWidget';

const BlogPostListWidgetType = 'BlogPostListWidget';
const BlogPostCardWidgetType = 'BlogPostCardWidget';
const addBlogPostWidgets = (member, instance) => ({
    ...member,
    [BlogPostListWidgetType]: {
        component: BlogPostListWidget
    },
    [BlogPostCardWidgetType]: {
        component: ({ categories, ...props }) => <BlogPostCardWidget categories={ JSON.parse(categories) } { ...props } />
    }
})

export default {
    'Component/WidgetFactory/Component': {
        'member-property': {
            'renderMap': addBlogPostWidgets
        }
    }
}