/* eslint-disable */
import { FieldContainer as SourceFieldContainer }
    from 'SourceComponent/Field/Field.container';
import FieldStoreFinder from './FieldStoreFinder.component';

/**
 * Input fields component
 * @class Field
 * @namespace Component/Field/Component
 */
export class FieldStoreFinderContainer extends SourceFieldContainer {
    render() {
        return (
            <FieldStoreFinder
              { ...this.props }
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default FieldStoreFinderContainer;